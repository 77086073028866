import { Box, Grid, useMediaQuery, Link as MuiLink } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { format } from "date-fns";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { ActiveUserDetailsDTO, ApiUserType, Permission } from "src/generated/api_types";
import { requestAddressChange, requestLicenseNumberChange, requestPasswordResetOnBehalf } from "src/pages/auth/Queries";
import { requestDeactivation as requestDeactivationType } from "src/pages/carinaAdmin/dashboard/agencyInvite/Queries";
import { UserDeactivationDialog } from "src/pages/carinaAdmin/dashboard/agencyInvite/UserDeactivationDialog";
import { getAvailability } from "src/pages/medicaidAccount/sharedComponents/ProviderProfileTagHelpers";
import { usersAdministrationController } from "src/pages/supportAdminAccount/ControllerClients";
import ChangeUserEmailModal from "src/pages/supportAdminAccount/dashboard/components/ChangeUserEmailModal";
import ChangeUserRoleModal from "src/pages/supportAdminAccount/dashboard/components/ChangeUserRoleModal";
import MessageDialog from "src/pages/supportAdminAccount/dashboard/components/MessageDialog";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import BottomActionBar from "src/reusable_view_elements/BottomActionBar";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import DashSection from "src/reusable_view_elements/DashSection";
import { FormAddressField, FormTextField, TextField } from "src/reusable_view_elements/form_fields";
import Form from "src/reusable_view_elements/form_fields/Form";
import { InternalLink } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import PageContainerDashboard, { DashboardType } from "src/reusable_view_elements/PageContainerDashboard";
import { StartImpersonationButton } from "src/reusable_view_elements/StartImpersonationButton";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import theme from "src/themes/civilization/CivTheme";
import { applyPhoneFormat } from "src/utilities/DashboardUtilities";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

export function getDashboardTitle(userType: string | undefined, dashboardType: DashboardType): string {
  let userTitle = "";
  switch (userType) {
    case UserType.UserSupportAgent:
      userTitle = "Carina User Support Agent Tools";
      break;
    case UserType.UserSupportManager:
      userTitle = "Carina User Support Manager Tools";
      break;
    default:
      userTitle = "Carina User Support Tools";
  }
  let programTitle = "";
  switch (dashboardType) {
    case DashboardType.MEDICAID:
      programTitle = "Medicaid";
      break;
    case DashboardType.CHILDCARE:
      programTitle = "Childcare";
      break;
    default:
      programTitle = "";
  }
  return `${userTitle}: ${programTitle}`;
}

export function getRoleDisplayName(role: string | ApiUserType): string {
  switch (role) {
    case "MedicaidCaseManager":
      return "Case Manager 2";
    case "CaseWorker":
      return "Case Manager 1";
    default:
      return role;
  }
}

interface UserDetailsFormFields {
  license: string;
  address: string;
}

interface UserDetailsProps {
  dashboardType: DashboardType;
  requestDeactivation?: typeof requestDeactivationType;
}

const UserDetails = ({ dashboardType, requestDeactivation = requestDeactivationType }: UserDetailsProps) => {
  const mobileSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { id } = useParams<{ id: string }>();
  const { showSnackbar } = useNotification();
  const [isLoadingUser, setLoadingUser] = useState<boolean>(false);
  const [userData, setUserData] = useState<ActiveUserDetailsDTO>();
  const [reportedCases, _setReportedCases] = useState(); // TODO: story #181590958
  const { userSession, isUserType, hasPermission, isImpersonated } = useUserSession();
  const [showMessageDialog, setMessageDialog] = useState<boolean>(false);
  const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);
  const [openChangeRoleModal, setOpenChangeRoleModal] = useState(false);
  const [resetPasswordURL, setResetPasswordURL] = useState("");
  const history = useHistory();

  useEffect(() => {
    const loadUser = async () => {
      setLoadingUser(true);
      const res = await usersAdministrationController.getActiveUserDetails(id);
      setUserData(res.data);
    };
    loadUser()
      .catch(() => showSnackbar("Sorry, there was an issue retrieving this user's data.", "error"))
      .finally(() => setLoadingUser(false));
  }, []);

  const handleResetPasswordClick = () => {
    if (userData && (isUserType(UserType.UserSupportAgent) || userData.role === "ChildcareProvider")) {
      requestPasswordResetOnBehalf({ email: userData.email }).then((resp) => {
        return resp.data === ""
          ? showSnackbar("Error submitting forgot password request. Please try again later.", "error")
          : setResetPasswordURL(resp.data);
      });
    }
  };

  const copyResetPasswordURL = async (urlWithToken: string) => {
    await navigator.clipboard.writeText(urlWithToken);
    showSnackbar("Password Reset Link Copied!", "success");
  };

  const schema = object({
    license: string().required("Please enter a valid license"),
    address: string().required("Please provide a valid address"),
  });

  const initialValues: UserDetailsFormFields = {
    license: userData?.license || "",
    address: userData?.address || "",
  };

  const handleCloseChangeEmailModal = () => {
    setOpenChangeEmailModal(false);
  };

  const handleCloseChangeRoleModal = () => {
    setOpenChangeRoleModal(false);
  };

  const handleSubmit = (values: UserDetailsFormFields) => {
    if (
      values.license &&
      userData?.license &&
      values.license !== userData.license &&
      hasPermission(Permission.ADMIN_TOOLS_LICENSE_NUMBER_CHANGE)
    ) {
      requestLicenseNumberChange({ providerId: userData.id, newLicense: values.license })
        .then(() => showSnackbar("Request was successfully submitted", "success"))
        .catch((e) => {
          if (e.response && e.response.status === 409) {
            showSnackbar("Proposed new License number is ALREADY IN USE \nplease try with a different one", "error");
          } else {
            showSnackbar("Something went wrong while processing your request. Please try again later.", "error");
          }
        });
    }
    if (
      values.address &&
      userData?.address &&
      values.address !== userData.address &&
      hasPermission(Permission.ADMIN_TOOLS_ADDRESS_CHANGE)
    ) {
      requestAddressChange({
        targetUserId: userData.id,
        currentAddress: userData.address,
        newAddress: values.address,
      })
        .then(() => showSnackbar("Request was successfully submitted", "success"))
        .catch(() => {
          showSnackbar("Something went wrong while processing your request. Please try again later.", "error");
        });
    }
  };

  function updateEmailInUserDataObject(newEmail: string) {
    if (userData) setUserData({ ...userData, email: newEmail });
  }

  function updateRoleInUserDataObject(newRole: string) {
    if (userData) setUserData({ ...userData, role: newRole });
  }

  const getApiUserType = (role: string): ApiUserType => ApiUserType[role as keyof typeof ApiUserType];

  return (
    <>
      <Helmet>
        <title>Carina | User Details</title>
      </Helmet>

      {userData && (
        <MessageDialog
          name={userData.firstName}
          open={showMessageDialog}
          onClose={() => setMessageDialog(false)}
          setDialogVisibility={setMessageDialog}
          recipientId={userData.id}
        />
      )}

      <PageContainerDashboard title={getDashboardTitle(userSession?.role, dashboardType)} maxWidth="xl">
        <DashReturnLink
          to={
            dashboardType === DashboardType.CHILDCARE
              ? SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_active_user
              : SUPPORT_ADMIN_DASHBOARD_ROUTES.active_user
          }
        >
          Back
        </DashReturnLink>

        {isLoadingUser && <LoadingCircle />}

        {!isLoadingUser && !userData && (
          <Constraint columns={8} paddingTop={4}>
            <SectionTitle paragraph>User not found</SectionTitle>
          </Constraint>
        )}

        {!isLoadingUser && userData && (
          <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
            <Form name="active-user-details" localizationReady={true}>
              <Helmet>
                <title>Carina | User Details</title>
              </Helmet>

              <Constraint columns={8} paddingTop={4}>
                <Grid container style={{ marginBottom: "36px" }} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SectionTitle paragraph>
                      {userData?.firstName} {userData?.lastName}
                    </SectionTitle>
                    <Body>
                      Active {userData?.funderName} {userData ? getRoleDisplayName(userData.role) : ""}
                    </Body>
                  </Grid>
                  <Grid item xs={12} sm={6} style={mobileSize ? { textAlign: "right" } : {}}>
                    <StartImpersonationButton
                      userDisplayName={`${userData?.firstName} ${userData?.lastName[0]}`}
                      userId={id}
                      returnRoute={
                        dashboardType === DashboardType.CHILDCARE
                          ? generatePath(SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_user_details, { id: userData.id })
                          : generatePath(SUPPORT_ADMIN_DASHBOARD_ROUTES.user_details, { id: userData.id })
                      }
                    />
                  </Grid>
                </Grid>

                {hasPermission(Permission.ADMIN_TOOLS_USER_ROLE_CHANGE) && userData.role === "CaseWorker" && (
                  <DashSection bodyTitleLabel label="Role" topBorder>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <BodyEmphasis paragraph>{getRoleDisplayName(userData.role)}</BodyEmphasis>
                      </Grid>
                      {!openChangeRoleModal && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            type="button"
                            onClick={() => {
                              if (isImpersonated()) {
                                showSnackbar(
                                  "You cannot change this user's role if you are in impersonation mode.",
                                  "info",
                                );
                              } else {
                                setOpenChangeRoleModal(true);
                              }
                            }}
                          >
                            Change Role
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </DashSection>
                )}

                <DashSection bodyTitleLabel label="Account info" topBorder container contentGridProps={{ spacing: 4 }}>
                  {!hasPermission(Permission.ADMIN_TOOLS_USER_EMAIL_CHANGE) && (
                    <Grid item xs={12}>
                      <BodyEmphasis gutterBottom>Email Address</BodyEmphasis>
                      <Body>{userData.email}</Body>
                    </Grid>
                  )}
                  {userData.role === "ChildcareProvider" && (
                    <>
                      <Grid item xs={12}>
                        <FormTextField
                          name="license"
                          label="License number"
                          type="license"
                          required={isRequiredField("license", schema)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormAddressField
                          name="address"
                          label="Address"
                          required={isRequiredField("address", schema)}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <BodyEmphasis gutterBottom>Phone</BodyEmphasis>
                    <Body>{applyPhoneFormat(userData?.smsPhone || "")}</Body>
                  </Grid>
                  <Grid item xs={6}>
                    <BodyEmphasis gutterBottom>Join Date</BodyEmphasis>
                    <Body>{userData?.joinDate && format(userData?.joinDate, "PPpp")}</Body>
                  </Grid>
                  <Grid item xs={6}>
                    {userData.availability ? (
                      <>
                        <BodyEmphasis gutterBottom>Availability</BodyEmphasis>
                        <Body>{getAvailability(userData.availability)}</Body>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <BodyEmphasis gutterBottom>Last Login</BodyEmphasis>
                    <Body>{userData?.lastLoginDate && format(userData?.lastLoginDate, "PPpp")}</Body>
                  </Grid>
                  {userData.role !== "ChildcareProvider" && (
                    <Grid item xs={6}>
                      <BodyEmphasis gutterBottom>Unread Messages</BodyEmphasis>
                      <Body>{userData?.totalUnreadMessages}</Body>
                    </Grid>
                  )}
                </DashSection>

                {hasPermission(Permission.ADMIN_TOOLS_USER_EMAIL_CHANGE) && (
                  <DashSection bodyTitleLabel label="Email" topBorder>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Body>Current email:</Body>
                        <BodyEmphasis paragraph>{userData.email}</BodyEmphasis>
                      </Grid>
                      {!openChangeEmailModal && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            type="button"
                            onClick={() => {
                              if (isImpersonated()) {
                                showSnackbar(
                                  "You cannot change this user's email if you are in impersonation mode.",
                                  "info",
                                );
                              } else {
                                setOpenChangeEmailModal(true);
                              }
                            }}
                          >
                            Change Email
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </DashSection>
                )}

                {reportedCases && (
                  <DashSection bodyTitleLabel label="Reported cases" topBorder>
                    <Body>Nothing here yet</Body>
                  </DashSection>
                )}

                {userData.reason && userData.verifiersInfo && (
                  <DashSection bodyTitleLabel label="Invite notes" topBorder>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <BodyEmphasis paragraph>{format(userData.createdAt, "PP")}</BodyEmphasis>
                      </Grid>
                      <Grid item>
                        {/*TODO: Remove next check for issuerFirstName once this field exist in Medicaid & Childcare*/}
                        {userData.issuerFirstName && (
                          <Body paragraph>
                            Submitted By {userData.issuerFirstName} {userData.issuerLastName.substring(0, 1)} &#46;
                          </Body>
                        )}
                      </Grid>
                    </Grid>
                    <Body>{userData.reason}</Body>
                    <Grid item>
                      <Grid item style={{ padding: "16px 0px" }}>
                        <Body>
                          <span style={{ color: "#595A5A" }}>
                            Verified By {JSON.parse(userData.verifiersInfo).verifiersFirstName} &nbsp;
                            {JSON.parse(userData.verifiersInfo).verifiersLastName.substring(0, 1)} &#46; &nbsp; (
                            {JSON.parse(userData.verifiersInfo).verifiersEmail})
                          </span>
                        </Body>
                      </Grid>
                    </Grid>
                  </DashSection>
                )}

                <DashSection bodyTitleLabel label="Actions" topBorder container contentGridProps={{ spacing: 4 }}>
                  {userData.role !== "ChildcareProvider" && (
                    <Grid item xs={12}>
                      <MuiLink onClick={() => setMessageDialog(true)}>
                        <Body>
                          Message {userData?.firstName} {userData?.lastName}
                        </Body>
                      </MuiLink>
                    </Grid>
                  )}
                  {hasPermission(Permission.ADMIN_TOOLS_USER_PASSWORD_RESET) && (
                    <Grid item xs={userData.role === "ChildcareProvider" ? 6 : 12}>
                      <InternalLink
                        id={userData.id}
                        to={
                          userData && !isUserType(UserType.UserSupportAgent) && userData.role !== "ChildcareProvider"
                            ? generatePath(SUPPORT_ADMIN_DASHBOARD_ROUTES.reset_password, {
                                id: userData.id,
                              })
                            : "#"
                        }
                        onClick={handleResetPasswordClick}
                      >
                        Reset Password
                      </InternalLink>
                    </Grid>
                  )}
                  {userData.role === "ChildcareProvider" && (
                    <Grid item xs={6}>
                      <MuiLink onClick={() => showSnackbar("TODO: To be implemented", "info")} color="error">
                        <Body>Deactivate user</Body>
                      </MuiLink>
                    </Grid>
                  )}
                  {resetPasswordURL && (
                    <Grid item xs={12}>
                      <TextField
                        name="inviteLink"
                        label="Password Reset Link"
                        type="text"
                        value={resetPasswordURL}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                variant="text"
                                type="button"
                                onClick={() => copyResetPasswordURL(resetPasswordURL)}
                              >
                                Copy
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  {isUserType(UserType.UserSupportManager) && userData.role !== "ChildcareProvider" && (
                    <>
                      <Grid item xs={12}>
                        <UserDeactivationDialog
                          userFirstName={userData?.firstName}
                          onSubmit={async (accepted) => {
                            if (accepted) {
                              try {
                                await requestDeactivation({
                                  userId: id,
                                });
                                showSnackbar(`Successfully deactivated ${userData?.firstName}'s account`, "success");
                                history.push(SUPPORT_ADMIN_DASHBOARD_ROUTES.user_details);
                              } catch (e) {
                                showSnackbar(`Error requesting user deactivation: ${e}`, "error");
                              }
                            }
                          }}
                        >
                          {(setModalOpen) => (
                            <Body>
                              <MuiLink onClick={() => setModalOpen(true)}>Deactivate user</MuiLink>
                            </Body>
                          )}
                        </UserDeactivationDialog>
                      </Grid>
                      <Grid item xs={12}>
                        <MuiLink onClick={() => showSnackbar("TODO: To be implemented", "info")} color="error">
                          <Body>Block user</Body>
                        </MuiLink>
                      </Grid>
                    </>
                  )}
                </DashSection>
              </Constraint>

              <Box height={150} />

              {userData.role === "ChildcareProvider" && (
                <BottomActionBar
                  bottomActionButtons={[
                    {
                      variant: "text",
                      buttonText: "Cancel",
                      buttonProps: {
                        onClick: () =>
                          history.push(
                            userData.role === "ChildcareProvider"
                              ? SUPPORT_ADMIN_DASHBOARD_ROUTES.cc_active_user
                              : SUPPORT_ADMIN_DASHBOARD_ROUTES.active_user,
                          ),
                      },
                    },
                    {
                      variant: "contained",
                      buttonText: "Save",
                      buttonProps: {
                        type: "submit",
                      },
                    },
                  ]}
                />
              )}
            </Form>
          </Formik>
        )}
      </PageContainerDashboard>
      {userData && (
        <ChangeUserEmailModal
          currentEmail={userData.email}
          showModal={openChangeEmailModal}
          handleModalClose={handleCloseChangeEmailModal}
          updateDisplayedEmail={updateEmailInUserDataObject}
        />
      )}
      {userData && (
        <ChangeUserRoleModal
          userId={userData.id}
          currentRole={getApiUserType(userData.role)}
          showModal={openChangeRoleModal}
          handleModalClose={handleCloseChangeRoleModal}
          updateDisplayedRole={updateRoleInUserDataObject}
        />
      )}
    </>
  );
};

export default UserDetails;
