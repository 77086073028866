import { alpha, Box, MenuList } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { useUserSession } from "src/context/UserSessionContext";
import { RoleType, ThreadListDTO } from "src/generated/api_types";
import ThreadListCard from "src/pages/inbox/inboxComponents/ThreadListCard";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import Constraint from "src/reusable_view_elements/Constraint";
import CivColors from "src/themes/civilization/CivColors";

interface ThreadListGridProps {
  threads: ThreadListDTO[];
}

// Shared across inbox tab views: All, Unread, Archived, Starred, Job Response Threads
const ThreadListGrid = (props: ThreadListGridProps) => {
  const history = useHistory();
  const { isAssistiveRoleView } = useUserSession();
  const { setSelectedThreadId, setInboxSidePanelUserId } = useInboxContext();

  const getClientName = (thread: ThreadListDTO) => {
    if (isAssistiveRoleView()) return thread.jobPost?.consumerFirstName;
    return thread.otherUser.roleType === RoleType.SUPPORT ||
      thread.otherUser.roleType === RoleType.MEDICAID_CASE_MANAGER ||
      thread.otherUser.roleType === RoleType.PROXY_PROVIDER ||
      thread.otherUser.roleType === RoleType.MEDICAID_REFERRAL_COORDINATOR
      ? thread.jobPost?.consumerFirstName
      : undefined;
  };

  return (
    <Constraint
      columns={12}
      sx={{ borderTop: isAssistiveRoleView() ? `1px solid ${alpha(CivColors.mediumGray, 0.5)}` : "none" }}
    >
      <Box component={isAssistiveRoleView() ? MenuList : "div"} paddingY={isAssistiveRoleView() ? 0 : 4}>
        {props.threads.map((thread) => (
          <ThreadListCard
            key={thread.threadId}
            onClick={() => {
              isAssistiveRoleView() && setSelectedThreadId(thread.threadId);
              // next line resets id of user to be displayed in the inbox panel (if applicable)
              setInboxSidePanelUserId(undefined);
              !isAssistiveRoleView() &&
                history.push(generatePath(MEDICAID_INBOX_ROUTES.viewThread, { threadId: thread.threadId }), {
                  from: history.location.pathname, // Helps with back button logic
                });
            }}
            avatarId={thread.otherUser.roleType === RoleType.PROVIDER ? thread.otherUser.id : undefined}
            hasImg={thread.otherUser.roleType === RoleType.PROVIDER ? thread.otherUser.hasImg : undefined}
            date={thread.lastMessageDate}
            firstName={thread.otherUser.firstName}
            lastNameOrInitial={
              thread.otherUser.roleType !== RoleType.CONSUMER ? thread.otherUser.lastNameOrInitial : undefined
            }
            organization={thread.otherUser.organizationName}
            roleType={thread.otherUser.roleType}
            clientName={getClientName(thread)}
            jobNum={thread.jobPost?.jobPostNumber}
            jobId={thread.jobPost?.jobPostId}
            jobIsResolved={thread.jobPost?.jobIsResolved}
            message={thread.lastMessageText}
            unreadMessages={thread.unreadMessageCount}
            threadId={thread.threadId}
          />
        ))}
      </Box>
    </Constraint>
  );
};

export default ThreadListGrid;
