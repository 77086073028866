import { Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useHistory } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { RoleType, SortDirection, ThreadFilterEnum, ThreadListDTO } from "src/generated/api_types";
import { getGenericError } from "src/i18n/Utilities";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidAccountPortal } from "src/i18n/Namespaces";
import { ConversationChat2AlternateIcon } from "src/assets/icons/StreamlineIcons";
import ThreadMessage from "src/pages/inbox/inboxComponents/ThreadMessage";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import LoadingPortalCardSkeleton from "src/pages/medicaidAccount/portal/portalCards/LoadingPortalCardSkeleton";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import Badge from "src/reusable_view_elements/Badge";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import PortalCard from "src/reusable_view_elements/PortalCard";
import PortalCardDivider from "src/reusable_view_elements/PortalCardDivider";
import PortalCardTitle from "src/reusable_view_elements/PortalCardTitle";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";
import theme from "src/themes/civilization/CivTheme";
import { Medicaid_Provider_Dashboard_Routes } from "../../providerAccount/ProviderRouter";
import { Medicaid_Proxy_Provider_Routes } from "../../ProxyProvider/ProxyProviderRouter";

const MessagesPortalCard = () => {
  const { showSnackbar } = useNotification();
  const { isUserType, userSession } = useUserSession();
  const history = useHistory();
  const { program } = useSupportAdminContext();
  const { setTotalUnreadCount } = useInboxContext();
  const { t, ready: translationReady } = useTranslation([nsMedicaidAccountPortal, nsCommonToasts]);

  const [threadList, setThreadList] = useState<ThreadListDTO[]>([]);
  const [numUnreadMessages, setNumUnreadMessages] = useState<number>(0);
  const [loadingThreads, setLoadingThreads] = useState<boolean>(false);
  const [loadingNumUnread, setLoadingNumUnread] = useState<boolean>(false);

  useEffect(() => {
    if (!isUserType(UserType.UserSupportManager) || (isUserType(UserType.UserSupportManager) && program !== "none")) {
      setLoadingThreads(true);
      messagingClient
        .getThreads({
          threadFilter: ThreadFilterEnum.UNREAD,
          pageInfo: {
            pageNum: 0,
            pageSize: 3,
            filters: {},
            search: "",
            sort: {
              columnName: "messageThread.lastMessageDate",
              direction: SortDirection.DESC,
            },
            searchMap: {},
          },
          selectedFunderId: isUserType(UserType.UserSupportManager) ? program : "",
        })
        .then((res) => {
          setThreadList(res.data.content);
        })
        .catch(() => {
          showSnackbar(getGenericError(t), "error");
        })
        .finally(() => {
          setLoadingThreads(false);
        });

      setLoadingNumUnread(true);
      messagingClient
        .getUnreadThreadCount({
          selectedFunderId: isUserType(UserType.UserSupportManager) ? program : "",
        })
        .then((res) => {
          setNumUnreadMessages(res.data);
          setTotalUnreadCount(res.data); // global unread messages count (i.e. shown in navbar)
        })
        .catch(() => {
          showSnackbar(getGenericError(t), "error");
        })
        .finally(() => {
          setLoadingNumUnread(false);
        });
    }
  }, [program]);

  if (!translationReady) {
    return <LoadingPortalCardSkeleton />;
  }

  return (
    <PortalCard>
      <PortalCardTitle
        icon={ConversationChat2AlternateIcon}
        viewMorePath={numUnreadMessages > 0 ? MEDICAID_INBOX_ROUTES.unreadThreads : MEDICAID_INBOX_ROUTES.allThreads}
      >
        <BodyTitle>
          {t("my_messages.label", { ns: nsMedicaidAccountPortal })}
          {!loadingNumUnread && numUnreadMessages > 0 && <Badge number={numUnreadMessages} ml="12px" />}
        </BodyTitle>
      </PortalCardTitle>

      <PortalCardDivider />

      {(loadingThreads || loadingNumUnread) && (
        <LoadingCircle text={t("my_messages.loading_unread_messages", { ns: nsMedicaidAccountPortal })} />
      )}

      {!(loadingThreads || loadingNumUnread) && numUnreadMessages === 0 && <NoMessages />}

      {!(loadingThreads || loadingNumUnread) &&
        threadList.length > 0 &&
        threadList.map((thread, i) => (
          <ThreadMessage
            key={thread.threadId}
            firstName={thread.otherUser.firstName}
            lastNameOrInitial={
              thread.otherUser.roleType !== RoleType.CONSUMER && thread.otherUser.id !== userSession?.id
                ? thread.otherUser.lastNameOrInitial
                : undefined
            }
            messageOwner={false}
            roleType={thread.otherUser.roleType}
            organization={thread.otherUser.organizationName}
            date={thread.lastMessageDate}
            message={thread.lastMessageText}
            showExpandOption={false}
            showBottomBorder={threadList.length !== i + 1}
            px={0}
            mx={0}
            onClick={() => {
              history.push(generatePath(MEDICAID_INBOX_ROUTES.viewThread, { threadId: thread.threadId }));
            }}
            style={{ cursor: "pointer" }}
            avatarId={thread.otherUser.roleType === RoleType.PROVIDER ? thread.otherUser.id : undefined}
            hasImg={thread.otherUser.roleType === RoleType.PROVIDER ? thread.otherUser.hasImg : undefined}
          />
        ))}
    </PortalCard>
  );
};

const NoMessages = () => {
  const { isUserType } = useUserSession();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready } = useTranslation([nsMedicaidAccountPortal, nsCommonFormsBtns]);

  const canPostAJob: boolean =
    isUserType(UserType.Consumer) ||
    // isUserType(UserType.ProxyProvider) || // Simplify the CTA's for Proxy Providers and just show them Profile CTA's
    isUserType(UserType.MedicaidReferralCoordinator) ||
    isUserType(UserType.MedicaidCaseManager);

  const canUpdateProfile: boolean = isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider);
  const viewMyProfileLink: string = isUserType(UserType.Provider)
    ? Medicaid_Provider_Dashboard_Routes.viewMyProfile
    : Medicaid_Proxy_Provider_Routes.viewMyProfile;

  if (!ready) {
    return <LoadingCircle />;
  }

  return (
    <>
      <Body paragraph align={desktopSize ? "left" : "center"}>
        {t("my_messages.description.message_count", { ns: nsMedicaidAccountPortal, count: 0 })}
      </Body>
      <Body paragraph align={desktopSize ? "left" : "center"}>
        {canPostAJob && t("my_messages.description.post_a_job_search_for_provider", { ns: nsMedicaidAccountPortal })}
        {canUpdateProfile &&
          t("my_messages.description.update_profile_or_browse_jobs", { ns: nsMedicaidAccountPortal })}
      </Body>

      <Grid
        container
        spacing={2}
        direction={desktopSize ? "row" : "column-reverse"}
        alignItems={desktopSize ? "flex-start" : "center"}
      >
        {canPostAJob && (
          <>
            <Grid item>
              <LinkStyledAsButton variant="outlined" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseProviders}>
                {t("button.search_for_providers", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Grid>
            <Grid item>
              <LinkStyledAsButton
                variant="contained"
                component={Link}
                to={getMedicaidRouteByUserType("jobCreate", isUserType)}
              >
                {t("button.post_a_job", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Grid>
          </>
        )}
        {canUpdateProfile && (
          <>
            <Grid item>
              <LinkStyledAsButton variant="outlined" component={Link} to={viewMyProfileLink}>
                {t("button.update_profile", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Grid>
            <Grid item>
              <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseJobs}>
                {t("button.search_for_jobs", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default MessagesPortalCard;
