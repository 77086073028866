import { ArrowBack } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  PaginationItem as MUIPaginationItem,
  PaginationRenderItemParams,
  Tooltip as MuiTooltip,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { SortDirection, ThreadFilterEnum, ThreadListDTO } from "src/generated/api_types";
import { nsCommonAria, nsCommonFormsBtns, nsCommonToasts, nsMedicaidInbox } from "src/i18n/Namespaces";
import { getGenericError, getLocalizedRoleType } from "src/i18n/Utilities";
import { EmptyContainer } from "src/pages/inbox/inboxComponents/EmptyInboxComponents";
import ThreadListGrid from "src/pages/inbox/inboxComponents/ThreadListGrid";
import { InboxTabState, useInboxContext } from "src/pages/inbox/InboxContext";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import { Button, FormButtonRowStyle } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import { TextField } from "src/reusable_view_elements/form_fields";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MenuItem from "src/reusable_view_elements/MenuItem";
import Pagination from "src/reusable_view_elements/Pagination";
import { Body, BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

/***********************************************************************
 *********** SUBCOMPONENTS USED IN TreadListContainer ********************
 ***********************************************************************/
interface SearchBarProps {
  searchFieldLabel: string;
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  showSearchResetButton: boolean;
  setShowSearchResetButton: Dispatch<SetStateAction<boolean>>;
  runSearch: (__pageNumber0Based: number, __reset?: boolean) => void;
  ariaLabel?: string;
}

const RegularSearchBar: FC<SearchBarProps> = ({
  searchFieldLabel,
  searchQuery,
  setSearchQuery,
  setShowSearchResetButton,
  runSearch,
  showSearchResetButton,
}) => {
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t } = useTranslation([nsCommonFormsBtns, nsCommonAria, nsMedicaidInbox, nsCommonToasts]);
  return (
    <Constraint columns={8}>
      <Box px="16px" pt={4}>
        <form>
          <Grid container alignItems="center" spacing={2} justifyContent="center">
            <Grid item xs={12} sm>
              <TextField
                fullWidth
                label={searchFieldLabel}
                onChange={(event) => setSearchQuery(event.target.value)}
                value={searchQuery}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sx={{ alignSelf: "end", marginBottom: "4px" }}>
              <Button
                variant="outlined"
                type="submit"
                disabled={false}
                onClick={() => {
                  setShowSearchResetButton(true);
                  runSearch(0);
                }}
                style={desktopSize ? FormButtonRowStyle : {}}
                sx={{ height: "48px" }}
              >
                {t("button.search", { ns: nsCommonFormsBtns })}
              </Button>
            </Grid>
            {showSearchResetButton && (
              <Grid item sx={{ alignSelf: "end", marginBottom: "4px" }}>
                <Button variant="text" type="button" onClick={() => runSearch(0, true)}>
                  {t("button.reset", { ns: nsCommonFormsBtns })}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </Constraint>
  );
};

export const AssistiveRoleSearchBar: FC<SearchBarProps> = ({
  searchFieldLabel,
  searchQuery,
  setSearchQuery,
  setShowSearchResetButton,
  runSearch,
  showSearchResetButton,
  ariaLabel,
}) => {
  const handleSearch = () => {
    if (searchQuery && searchQuery.length > 0) {
      setShowSearchResetButton(true);
      runSearch(0);
    }
  };
  return (
    <Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <TextField
          fullWidth
          sx={{ mx: 2, mb: 3 }}
          onChange={(event) => setSearchQuery(event.target.value)}
          value={searchQuery}
          placeholder={searchFieldLabel}
          aria-label={ariaLabel}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon htmlColor={CivColors.coreDarkNavy} onClick={handleSearch} />
              </InputAdornment>
            ),
            endAdornment: showSearchResetButton && (
              <InputAdornment position="end">
                <IconButton aria-label="clear search" size="small">
                  <ClearIcon htmlColor={CivColors.coreDarkNavy} onClick={() => runSearch(0, true)} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Box>
  );
};

const SearchBar: FC<SearchBarProps> = (props) => {
  const { isAssistiveRoleView } = useUserSession();
  const { t } = useTranslation(nsMedicaidInbox);

  if (isAssistiveRoleView())
    return (
      <AssistiveRoleSearchBar
        {...props}
        searchFieldLabel={t("field.search_by_sender_or_job.label", {
          ns: nsMedicaidInbox,
          defaultValue: "Sender's name or job #",
        })}
        ariaLabel={t("field.search_by_sender_or_job.aria_label", {
          ns: nsMedicaidInbox,
          defaultValue: "Search by sender's name or job number",
        })}
      />
    );
  return <RegularSearchBar {...props} />;
};

/******************************************************************** */

interface ThreadListContainerProps {
  threadFilter?: ThreadFilterEnum;
  loadingText: string;
}

const ThreadListContainer = (props: PropsWithChildren<ThreadListContainerProps>) => {
  const { showSnackbar } = useNotification();
  const { isUserType, isAssistiveRoleView } = useUserSession();
  const { program } = useSupportAdminContext();
  const {
    tab,
    pageNumber,
    setPageNumber,
    searchedTerm,
    setSearchedTerm,
    searchQuery,
    setSearchQuery,
    showSearchResetButton,
    setShowSearchResetButton,
    resetSearchAndPagination,
    contextOtherUserId,
    loadingThreads,
    setLoadingThreads,
    totalThreads,
    setTotalThreads,
    setWereThreadsSearched,
    wereThreadsFiltered,
    setWereThreadsFiltered,
    archiveActionRequested,
    setArchiveActionRequested,
    unfavoriteActionRequested,
    setUnfavoriteActionRequested,
    selectedThreadId,
    setSelectedThreadId,
    setInboxSidePanelUserId,
    setByClientJobNumber,
  } = useInboxContext();
  const { userId: paramOtherUserId } = useParams<{ userId: string }>();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsMedicaidInbox, nsCommonToasts]);

  const [threadList, setThreadList] = useState<ThreadListDTO[]>([]);
  //Next state contains the list of threads of the next page
  const [nextThreadsQueue, setNextThreadsQueue] = useState<ThreadListDTO[]>([]);
  const [filteredByUnread, setFilteredByUnread] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<null | HTMLElement>(null);

  const otherUserId = paramOtherUserId !== undefined ? paramOtherUserId : contextOtherUserId;
  const PAGE_SIZE = 5;
  const searchFieldLabel = (): string => {
    if (isAssistiveRoleView()) return ""; //This label no longer exists in the Assistive Role view

    return paramOtherUserId
      ? t("field.inbox_search.label_job_number", { ns: nsCommonFormsBtns })
      : t("field.inbox_search.label_name_or_job", { ns: nsCommonFormsBtns });
  };

  // User does not have conversations if thread list is empty, searched term is empty, and list is not filtered by unread
  const userHasNoConversations: boolean = threadList.length === 0 && searchedTerm === "" && !filteredByUnread;

  // User Support Managers need a program selected; if user is any other role, proceed with search
  const canRunSearch: boolean =
    (isUserType(UserType.UserSupportManager) && program !== "none") || !isUserType(UserType.UserSupportManager);

  const FilterByMenu = () => {
    return (
      <Menu
        anchorEl={filterMenuAnchor}
        keepMounted // mounted in the DOM for accessibility
        open={showFilterMenu}
        onClose={() => setShowFilterMenu(false)}
      >
        <Body sx={{ padding: "12px 16px" }}>{t("filter_by.label", { ns: nsMedicaidInbox })}</Body>
        <MenuItem
          Icon={MarkEmailUnreadOutlinedIcon}
          SelectedIcon={MarkEmailUnreadOutlinedIcon}
          style={{ minWidth: "300px", paddingLeft: "24px" }}
          selected={filteredByUnread}
          aria-label={t("unread_generic.label", { ns: nsMedicaidInbox })}
          onClick={() => {
            setNextThreadsQueue([]); //reset next page queue. When runSearch runs, it'll refill using the right filter
            setFilteredByUnread(!filteredByUnread);
            setWereThreadsFiltered(!wereThreadsFiltered); // This state is needed to inform render logic up the component tree
            setSelectedThreadId(undefined);
            setInboxSidePanelUserId(undefined);
            setShowFilterMenu(false);
          }}
        >
          {t("unread_generic.label", { ns: nsMedicaidInbox })}
        </MenuItem>
      </Menu>
    );
  };

  useEffect(() => {
    runSearch(0);
  }, [filteredByUnread]);

  useEffect(() => {
    if (canRunSearch) {
      runSearch(pageNumber - 1);
    }
  }, [program]);

  //UseEffect to update the thread list, in the front end, when a thread is archived/unarchived/unfavorited
  useEffect(() => {
    if (archiveActionRequested || unfavoriteActionRequested) {
      //Skip updating the thread list in the next scenarios
      if (
        (tab === InboxTabState.starred_threads && archiveActionRequested) ||
        (tab === InboxTabState.client_threads && archiveActionRequested) ||
        (tab !== InboxTabState.starred_threads && unfavoriteActionRequested)
      ) {
        setUnfavoriteActionRequested(false); // Reset flag
        setArchiveActionRequested(false); // Reset flag
        return; //Skip the rest of the code inside this useEffect hook
      }

      //Hide right panel
      setInboxSidePanelUserId(undefined);

      //Determine the next thread to select after the current thread is archived/unarchived/unfavorited
      const selectedThreadIdIndex = threadList.findIndex((thread) => thread.threadId === selectedThreadId);
      const isLastPage = pageNumber === totalPages;
      const isLastItemInPage = selectedThreadIdIndex === threadList.length - 1;
      let nextSelectedThreadId = undefined;
      if (isLastPage && isLastItemInPage) {
        if (selectedThreadIdIndex > 0) {
          nextSelectedThreadId = threadList[selectedThreadIdIndex - 1].threadId;
        } // else if (archiveActionRequested||unfavoriteActionRequested) select last thread inside the 'runSearch' function
      } else {
        if (selectedThreadIdIndex < 4) {
          nextSelectedThreadId = threadList[selectedThreadIdIndex + 1].threadId;
        } else if (nextThreadsQueue.length > 0) {
          nextSelectedThreadId = nextThreadsQueue[0].threadId;
        }
      }

      //Remove the thread from the list
      const updatedThreadList = threadList.filter((thread) => thread.threadId !== selectedThreadId);

      //Add the next thread from the queue (next page) to the bottom of the list
      const nextQueuedThread = nextThreadsQueue.shift();
      if (nextQueuedThread) updatedThreadList.push(nextQueuedThread);

      if (pageNumber >= 2 && isLastPage && updatedThreadList.length === 0) {
        // If we are on the last page, it's page 2+, and there are no threads left, go back to previous page
        runSearch(pageNumber - 2); // This also takes care of updating the list and the selected thread, if applicable
      } else {
        //Update the total pages count (used by the pagination component)
        if (totalThreads % PAGE_SIZE === 1) setTotalPages(totalPages - 1);
        setTotalThreads(totalThreads - 1);

        //Update the thread list and selected thread
        setThreadList(updatedThreadList);
        if (!(isLastPage && isLastItemInPage && selectedThreadIdIndex === 0)) setSelectedThreadId(nextSelectedThreadId);
      }

      setUnfavoriteActionRequested(false); // Reset flag
      setArchiveActionRequested(false); //Reset flag
    }
    getNextThreadsQueue();
  }, [archiveActionRequested, unfavoriteActionRequested]);

  useEffect(() => {
    getNextThreadsQueue();
  }, [loadingThreads, searchedTerm]);

  function runSearch(__pageNumber0Based: number, __reset?: boolean) {
    setLoadingThreads(true);
    if (__reset) {
      resetSearchAndPagination();
      setNextThreadsQueue([]);
    } else {
      setSearchedTerm(searchQuery);
      if (__pageNumber0Based === pageNumber - 2 && searchedTerm === searchQuery) {
        //If the user is going back to the previous page, reuse current page's threads
        setNextThreadsQueue(threadList);
      } else {
        //Otherwise empty the next threads queue
        setNextThreadsQueue([]);
      }
    }
    messagingClient
      .getThreads({
        threadFilter: filteredByUnread ? ThreadFilterEnum.UNREAD : props.threadFilter,
        withUserId: otherUserId,
        pageInfo: {
          pageNum: __pageNumber0Based,
          pageSize: PAGE_SIZE,
          filters: {},
          search: __reset ? "" : searchQuery,
          sort: {
            columnName:
              props.threadFilter === ThreadFilterEnum.ARCHIVED ? "updatedAt" : "messageThread.lastMessageDate",
            direction: SortDirection.DESC,
          },
          searchMap: {},
        },
        selectedFunderId: isUserType(UserType.UserSupportManager) ? program : "",
      })
      .then((res) => {
        setTotalThreads(res.data.totalElements);
        setThreadList(res.data.content);
        setTotalPages(res.data.totalPages);
        setPageNumber(res.data.number + 1);
        if ((archiveActionRequested || unfavoriteActionRequested) && res.data.content.length > 0) {
          setSelectedThreadId(res.data.content[res.data.content.length - 1].threadId);
        }
      })
      .catch(() => {
        showSnackbar(getGenericError(t), "error");
      })
      .finally(() => {
        setLoadingThreads(false);
        setWereThreadsSearched(true);
      });
  }

  function getNextThreadsQueue() {
    if (pageNumber >= totalPages) {
      //If this is the last page, there are no threads on a "next" page
      setNextThreadsQueue([]);
      return;
    }
    if (nextThreadsQueue.length > 0) return; //If the queue still has threads, there's no need to fetch more

    messagingClient
      .getThreads({
        threadFilter: filteredByUnread ? ThreadFilterEnum.UNREAD : props.threadFilter,
        withUserId: otherUserId,
        pageInfo: {
          pageNum: pageNumber,
          pageSize: PAGE_SIZE,
          filters: {},
          search: searchedTerm,
          sort: {
            columnName:
              props.threadFilter === ThreadFilterEnum.ARCHIVED ? "updatedAt" : "messageThread.lastMessageDate",
            direction: SortDirection.DESC,
          },
          searchMap: {},
        },
        selectedFunderId: isUserType(UserType.UserSupportManager) ? program : "",
      })
      .then((res) => {
        setNextThreadsQueue(res.data.content);
        setTotalPages(res.data.totalPages);
      });
  }

  const ThreadsTitle = () => {
    let bodyTitleText = "";
    if (otherUserId && threadList.length > 0) {
      bodyTitleText = t("threads_with_user.label", {
        ns: nsMedicaidInbox,
        name: (() => {
          const locizeRole = getLocalizedRoleType(threadList[0].otherUser.roleType);
          return `${threadList[0].otherUser.firstName} (${t(locizeRole.key, { ns: locizeRole.namespace })})`;
        })(),
      });
    } else {
      switch (tab) {
        case InboxTabState.all_threads:
          bodyTitleText = t("threads_title_all_messages.label", { ns: nsMedicaidInbox });
          break;
        case InboxTabState.starred_threads:
          bodyTitleText = t("threads_title_favorites.label", { ns: nsMedicaidInbox });
          break;
        case InboxTabState.archived_threads:
          bodyTitleText = t("threads_title_archived.label", { ns: nsMedicaidInbox });
          break;
        case InboxTabState.client_threads:
          return (
            <Box display="flex" gap="8px" justifyContent="left">
              <BodyEmphasis overflow="hidden" textOverflow="ellipsis" sx={{ fontFamily: "Noto Sans, sans-serif" }}>
                {threadList.length > 0 && threadList[0].jobPost ? threadList[0].jobPost.consumerFirstName : ""}
              </BodyEmphasis>
              <Body whiteSpace="nowrap" sx={{ fontFamily: "Noto Sans, sans-serif" }}>
                {t("job_number.label", {
                  ns: nsMedicaidInbox,
                  job_num: searchQuery,
                })}
              </Body>
            </Box>
          );
        default:
          bodyTitleText = "";
      }
    }
    return <BodyTitle>{bodyTitleText}</BodyTitle>;
  };

  if (loadingThreads || !ready) {
    return (
      <EmptyContainer>
        <LoadingCircle text={props.loadingText} />
      </EmptyContainer>
    );
  }

  if (!userHasNoConversations) {
    return (
      <>
        {(otherUserId || isAssistiveRoleView()) && (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            p="40px 8px 8px 24px"
            pb={tab === InboxTabState.client_threads ? "40px" : "8px"}
          >
            {tab === InboxTabState.client_threads && (
              <Box display="flex" alignItems="center" mb="20px">
                <IconButton
                  sx={{ ml: "-8px" }}
                  onClick={() => {
                    setByClientJobNumber(undefined);
                    setSearchQuery("");
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <Body>{t("button.return_to_client_list", { ns: nsCommonFormsBtns })}</Body>
              </Box>
            )}
            <Grid item xs={10}>
              <Constraint columns={8} textAlign={isAssistiveRoleView() ? "left" : "center"}>
                <ThreadsTitle />
              </Constraint>
            </Grid>

            {/* Unread Messages Filter for Assistive Roles in Inbox (ALL) tab only */}
            {isAssistiveRoleView() && props.threadFilter === ThreadFilterEnum.ALL && (
              <Grid item xs={2} textAlign="end">
                <MuiTooltip title={t("icon.filter_unread", { ns: nsCommonAria })} enterTouchDelay={0}>
                  <IconButton
                    aria-label={t("icon.filter_unread", { ns: nsCommonAria })}
                    size="large"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setFilterMenuAnchor(event.currentTarget);
                      setShowFilterMenu(true);
                    }}
                  >
                    {filteredByUnread ? (
                      <Badge color="secondary" variant="dot">
                        <FilterListIcon htmlColor={CivColors.coreDarkNavy} />
                      </Badge>
                    ) : (
                      <FilterListIcon htmlColor={CivColors.coreDarkNavy} />
                    )}
                  </IconButton>
                </MuiTooltip>
                <FilterByMenu />
              </Grid>
            )}
          </Grid>
        )}

        {/* Search Bar */}
        {tab !== InboxTabState.client_threads && (
          <SearchBar
            searchFieldLabel={searchFieldLabel()}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            showSearchResetButton={showSearchResetButton}
            setShowSearchResetButton={setShowSearchResetButton}
            runSearch={runSearch}
          />
        )}

        {isAssistiveRoleView() && (
          <>
            {/* Thread List */}
            <ThreadListGrid threads={threadList} />

            {/* Pagination: Next Page, Previous Page Buttons */}
            {totalPages > 1 && (
              <Box display="flex" justifyContent="center" paddingY={6}>
                <Pagination
                  page={pageNumber}
                  count={totalPages}
                  renderItem={(item: PaginationRenderItemParams) => {
                    return (
                      <MUIPaginationItem
                        {...item}
                        onClick={() => {
                          setPageNumber(item.page || 1); // item.page is 1-based
                          runSearch(item.page ? item.page - 1 : 0);
                        }}
                      />
                    );
                  }}
                />
              </Box>
            )}
          </>
        )}

        {threadList.length > 0 && !isAssistiveRoleView() && (
          <>
            {/* Thread List */}
            <ThreadListGrid threads={threadList} />

            {/* Pagination: Next Page, Previous Page Buttons */}
            <Box display="flex" justifyContent="center" paddingY={6}>
              <Pagination
                page={pageNumber}
                count={totalPages}
                renderItem={(item: PaginationRenderItemParams) => {
                  return (
                    <MUIPaginationItem
                      {...item}
                      onClick={() => {
                        setPageNumber(item.page || 1); // item.page is 1-based
                        runSearch(item.page ? item.page - 1 : 0);
                      }}
                    />
                  );
                }}
              />
            </Box>
          </>
        )}

        {threadList.length === 0 && !filteredByUnread && (
          <Box display="flex" justifyContent="center" paddingY={6} textAlign="center">
            <Body>{t("no_results_found_for_term.label", { ns: nsMedicaidInbox, term: searchedTerm })}</Body>
          </Box>
        )}

        {threadList.length === 0 && filteredByUnread && (
          <Box display="flex" justifyContent="center" px={2} py={6}>
            <BodyEmphasis>{t("you_have_no_unreads.label", { ns: nsMedicaidInbox })}</BodyEmphasis>
          </Box>
        )}
      </>
    );
  }

  return <>{props.children}</>;
};

export default ThreadListContainer;
