import {
  AxiosDirectionsControllerClient,
  AxiosJobPostSearchAndFilterControllerClient,
  AxiosMedicaidControllerClient,
  AxiosMessagingControllerClient,
  AxiosProviderProfileSearchAndFilterControllerClient,
  AxiosPublicMedicaidControllerClient,
  CurrentSettingsDTO,
  ProviderProfileTagEnum,
  TravelType,
} from "src/generated/api_types";

const directionsClient = new AxiosDirectionsControllerClient();
export const medicaidClient = new AxiosMedicaidControllerClient();
export const medicaidMessagingClient = new AxiosMessagingControllerClient();
export const publicMedicaidClient = new AxiosPublicMedicaidControllerClient();
// TODO: https://github.com/CarinaWeb/CarinaCore/issues/982
//  Update the job search api endpoint to include last name or last initial when the poster is not a consumer
export const jobSearchAndFilterClient = new AxiosJobPostSearchAndFilterControllerClient();
export const providerSearchAndFilterClient = new AxiosProviderProfileSearchAndFilterControllerClient();

export async function getDirectionsData(travelType: TravelType, startId: string, endId: string) {
  return directionsClient.getDirectionsData(travelType, startId, endId);
}

export async function sendInitialMessage(otherUserId: string, jobPostId: string, messageText: string, phone?: string) {
  return medicaidMessagingClient.sendInitialMessage({ otherUserId, jobPostId, phone, messageText });
}

export function getProviderProfile(id: string) {
  return medicaidClient.getProviderProfile(id);
}

export function getProviderProfileForAssistive(id: string) {
  return medicaidClient.getProviderProfileForAssistive(id);
}

export function getJobPostInfo(jobId: string) {
  return medicaidClient.getJobPostInfo(jobId);
}

export function getSurveryJobPostInfo(jobId: string) {
  return medicaidClient.getSurveyJobPostInfo(jobId);
}

export function bookmarkProfile(profileId: string) {
  return medicaidClient.bookmarkProfile({ profileId });
}

export function unBookmarkProfile(profileId: string) {
  return medicaidClient.unBookmarkProfile({ profileId });
}

export function getBookmarkedProfiles(programId: string) {
  return medicaidClient.getBookMarkedProfiles({ selectedFunderId: programId });
}

export function bookmarkJob(jobId: string) {
  return medicaidClient.bookmarkJobPost({ jobId });
}

export function unBookmarkJob(jobId: string) {
  return medicaidClient.unBookmarkJobPost({ jobId });
}

export function getBookmarkedJobs(programId: string) {
  return medicaidClient.getBookMarkedJobs({ selectedFunderId: programId });
}

export function getProvidersPreview(id: string) {
  return publicMedicaidClient.getProvidersNearMePreview({ id });
}

export function getUserSettings() {
  return medicaidClient.getUserSettings();
}

export function updateUserSettings(settings: CurrentSettingsDTO) {
  return medicaidClient.setUserSettings(settings);
}

export function getRegPathFromToken(token: string) {
  return publicMedicaidClient.getRegPathFromToken({ token });
}

export function getMyAddress() {
  return medicaidClient.getMyAddress();
}

export async function updateAvailability(id: string, status: ProviderProfileTagEnum) {
  return publicMedicaidClient.updateAvailabilityPost(id, status);
}
