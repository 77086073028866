import { alpha, Box, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { MessageResponseDTO, RoleType, ThreadDetailsDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidInbox } from "src/i18n/Namespaces";
import { getGenericError } from "src/i18n/Utilities";
import { DesktopMinWidth } from "src/pages/inbox/Inbox";
import AssistiveRoleReplyToThreadSection from "src/pages/inbox/inboxComponents/AssistiveRoleReplyToThreadSection";
import ThreadDetailsSection from "src/pages/inbox/inboxComponents/ThreadDetailsSection";
import ThreadMessage from "src/pages/inbox/inboxComponents/ThreadMessage";
import { InboxTabState, useInboxContext } from "src/pages/inbox/InboxContext";
import { firstNameAndInitial } from "src/pages/inbox/InboxHelpers";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import { ViewAPIResBannerControlEnum } from "src/reusable_view_elements/form_fields/ApiResponseBanner";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { scrollToId } from "src/utilities/ScrollToId";

const AssistiveRoleViewThread = () => {
  const { threadId } = useParams<{ threadId: string }>();
  const { showSnackbar } = useNotification();
  const { userSession, isUserType } = useUserSession();
  const { setReportResponseView } = useMedicaidContext();
  const desktopSize = useMediaQuery(DesktopMinWidth);
  const {
    setTotalUnreadCount,
    selectedThreadId,
    inboxSidePanelUserId,
    setJobPostIdForComparison,
    tab,
    wereThreadsFiltered,
  } = useInboxContext();
  const { program } = useSupportAdminContext();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsMedicaidInbox, nsCommonToasts]);

  const [loadingDetails, setLoadingDetails] = useState<boolean>();
  const [loadingMessages, setLoadingMessages] = useState<boolean>();
  const [messages, setMessages] = useState<MessageResponseDTO[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [details, setDetails] = useState<ThreadDetailsDTO>();
  const [thrId, setThrId] = useState<string>();
  const detailsAndMessagesGridRef = useRef<HTMLDivElement>(null);
  const detailsGridRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedThreadId) {
      setThrId(selectedThreadId);
    } else setThrId(threadId);
  }, [selectedThreadId]);

  useEffect(() => {
    if (!thrId) return;
    setLoadingDetails(true);
    messagingClient
      .getThreadDetailsForAssistive({ threadId: thrId })
      .then((res) => {
        setDetails(res.data);
        setJobPostIdForComparison(res.data.jobPostId);
      })
      .catch(() => {
        showSnackbar(getGenericError(t), "error");
      })
      .finally(() => {
        setLoadingDetails(false);
      });

    // Load messages
    setLoadingMessages(true);
    messagingClient
      .getMessageListForThreadForAssistive({ threadId: thrId })
      .then((res) => {
        setMessages(res.data.messageList);
        setUnreadCount(res.data.unreadMessageCount);

        // Auto-scroll to first unread message or last message
        if (res.data.unreadMessageCount > 0) {
          const firstUnread = res.data.messageList.length - res.data.unreadMessageCount;
          scrollToId(firstUnread.toString());
        } else {
          const lastMessage = res.data.messageList.length - 1;
          scrollToId(lastMessage.toString());
        }
      })
      .catch(() => {
        showSnackbar(getGenericError(t), "error");
      })
      .finally(() => {
        setLoadingMessages(false);
      });

    // Hide User Report banner
    setReportResponseView(ViewAPIResBannerControlEnum.HIDDEN);
  }, [thrId]);

  useEffect(() => {
    if (unreadCount > 0) {
      messagingClient
        .getUnreadThreadCount({
          selectedFunderId: isUserType(UserType.UserSupportManager) ? program : "",
        })
        .then((res) => {
          setTotalUnreadCount(res.data); // global unread messages count (i.e. shown in navbar)
        })
        .catch(() => {
          // It's okay to not show error to user in this case
        });
    }
  }, [unreadCount]);

  if (!selectedThreadId) {
    return (
      <Box height="100%" width="100%" alignContent="center" textAlign="center">
        {ready ? (
          wereThreadsFiltered ? (
            <>
              <SectionTitle sx={{ mb: "16px" }}>{t("no_unread_threads.label", { ns: nsMedicaidInbox })}</SectionTitle>
              <Body>{t("no_unread_threads.description", { ns: nsMedicaidInbox })}</Body>
            </>
          ) : (
            <>
              <SectionTitle sx={{ mb: "16px" }}>{t("no_thread_selected.label", { ns: nsMedicaidInbox })}</SectionTitle>
              <Body>{t("no_thread_selected.description", { ns: nsMedicaidInbox })}</Body>
            </>
          )
        ) : (
          <LoadingCircle />
        )}
      </Box>
    );
  }

  if (loadingMessages && loadingDetails) {
    return <LoadingCircle text={ready ? t("loading_conversation.label", { ns: nsMedicaidInbox }) : ""} />;
  }

  return (
    <Grid
      ref={detailsAndMessagesGridRef}
      container
      sx={{
        height: "100%",
        borderRight: `${inboxSidePanelUserId ? "1" : "0"}px solid ${alpha(CivColors.mediumGray, 0.5)}`,
      }}
      alignContent="space-between"
    >
      <Grid item container height="auto">
        <Grid
          ref={detailsGridRef}
          item
          xs={12}
          sx={{ borderBottom: `${messages.length > 0 ? "1" : "0"}px solid ${alpha(CivColors.mediumGray, 0.5)}` }}
          alignContent="start"
          justifyContent="stretch"
        >
          {!loadingDetails && details && (
            <ThreadDetailsSection
              setStarred={(newStatus: boolean) => {
                // Update details object so that it updates the DOM for user
                setDetails({
                  ...details,
                  starred: newStatus,
                });
              }}
              setArchived={(newStatus: boolean) => {
                // Update details object so that it updates the DOM for user
                setDetails({
                  ...details,
                  archived: newStatus,
                });
              }}
              {...details}
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          overflow="auto"
          container
          height="auto"
          maxHeight={`${
            tab !== InboxTabState.archived_threads && detailsAndMessagesGridRef.current && detailsGridRef.current
              ? detailsAndMessagesGridRef.current.clientHeight -
                detailsGridRef.current.clientHeight -
                (details?.archived ? 0 : 72) //If thread is archived, the space for the reply section is not needed
              : 720
          }px`}
          display="block"
        >
          {!loadingMessages &&
            messages
              .sort((msg1, msg2) => msg1.createdAt - msg2.createdAt)
              .map((message, i) => (
                <Grid item xs={12} key={i}>
                  <ThreadMessage
                    key={i}
                    id={i.toString()}
                    messageOwner={message.sender.id === userSession?.id}
                    avatarId={message.sender.roleType === RoleType.PROVIDER ? message.sender.id : undefined}
                    hasImg={message.sender.roleType === RoleType.PROVIDER ? message.sender.hasImg : undefined}
                    date={message.createdAt}
                    firstName={message.sender.firstName}
                    lastNameOrInitial={
                      message.sender.roleType !== RoleType.CONSUMER && message.sender.id !== userSession?.id
                        ? message.sender.lastNameOrInitial
                        : undefined
                    }
                    roleType={message.sender.roleType}
                    organization={desktopSize ? message.sender.organizationName : undefined}
                    message={message.messageText}
                    expand={
                      messages.length === i + 1 || messages.length === i + 2 || messages.length - unreadCount < i + 1
                    }
                    showBottomBorder={i !== messages.length - 1 && i !== messages.length - 1 - unreadCount}
                  />
                  {/* Orange border above unread messages */}
                  {unreadCount > 0 && i === messages.length - 1 - unreadCount && (
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item xs={10} ml="24px">
                        <Box borderBottom={`1px solid ${CivColors.coreOrange}`} />
                      </Grid>
                      <Grid item mr="24px">
                        <Body sx={{ color: CivColors.coreOrange }}>{t("new.label", { ns: nsMedicaidInbox })}</Body>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ))}
        </Grid>
      </Grid>
      {details && !details.archived && !loadingMessages && (
        <Grid
          item
          xs={12}
          height="auto"
          minHeight="72px"
          sx={{
            alignContent: "center",
            borderTop: `${
              !loadingMessages && messages.length > 0 ? "1" : "0"
            }px solid ${alpha(CivColors.mediumGray, 0.5)}`,
          }}
        >
          <AssistiveRoleReplyToThreadSection
            threadId={selectedThreadId || thrId || threadId} // all of these represent the message thread id
            archived={details.archived}
            messages={messages}
            setMessages={setMessages}
            otherUserDisplayName={firstNameAndInitial(
              details.otherUser.firstName,
              details.otherUser.roleType !== RoleType.CONSUMER && details.otherUser.id !== userSession?.id
                ? details.otherUser.lastNameOrInitial
                : undefined,
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AssistiveRoleViewThread;
