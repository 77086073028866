import { Box, BoxProps, Grid, useMediaQuery, Link as MuiLink } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { RoleType } from "src/generated/api_types";
import { nsCommonAlt, nsCommonFormsBtns, nsMedicaidInbox } from "src/i18n/Namespaces";
import { DesktopMinWidth } from "src/pages/inbox/Inbox";
import { NameOrgRoleTitle } from "src/pages/inbox/InboxHelpers";
import Avatar from "src/reusable_view_elements/Avatar";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { imageExists } from "src/utilities/GeneralUtilities";

interface ThreadMessageProps extends BoxProps {
  avatarId?: string;
  firstName: string;
  lastNameOrInitial?: string;
  organization?: string;
  roleType: RoleType;
  messageOwner: boolean;
  date: number;
  message: string;
  expand?: boolean;
  showExpandOption?: boolean;
  showBottomBorder?: boolean;
  hasImg?: boolean;
}

const ThreadMessage = ({
  avatarId,
  firstName,
  lastNameOrInitial,
  organization,
  roleType,
  messageOwner,
  date,
  message,
  expand = false,
  showExpandOption = true,
  showBottomBorder = true,
  hasImg,
  ...restBoxProps
}: ThreadMessageProps) => {
  const desktopSize = useMediaQuery(DesktopMinWidth);
  const { isInEnv } = useConfig();
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [expanded, setExpanded] = useState<boolean>(expand);
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsMedicaidInbox, nsCommonAlt]);

  const meText: string = ready ? t("me.label", { ns: nsMedicaidInbox }) : "";

  function toggleMessageExpansion() {
    setExpanded(!expanded);
  }

  useEffect(() => {
    if (avatarId && hasImg) {
      const profileImage = `https://s3.us-west-2.amazonaws.com/${
        isInEnv(EnvName.PROD) ? "production.carina.profile.photos" : "carina.profile.photos"
      }/${avatarId}.jpg`;
      imageExists(profileImage, (exists) => {
        if (exists) {
          setImageUrl(profileImage);
        }
      });
    }
  }, []);

  return (
    <Box
      borderBottom={showBottomBorder ? `1px solid ${CivColors.mediumGray}` : undefined}
      py="24px"
      mx={desktopSize ? "24px" : 0}
      px={desktopSize ? 0 : "16px"}
      {...restBoxProps}
    >
      <Grid container>
        <Grid item style={{ paddingRight: 12 }}>
          <Avatar
            src={imageUrl}
            alt={
              imageUrl
                ? t("standard.headshot_alt", {
                    ns: nsCommonAlt,
                    name: firstName + lastNameOrInitial,
                  })
                : ""
            }
            style={{
              width: "40px",
              height: "40px",
              fontSize: "0.8rem",
            }}
          >
            {firstName[0]}
            {lastNameOrInitial?.at(0)}
          </Avatar>
        </Grid>

        <Grid item xs>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: expanded ? 24 : 12 }}
          >
            {messageOwner ? (
              <BodyEmphasis>{meText}</BodyEmphasis>
            ) : (
              <NameOrgRoleTitle
                component={BodyEmphasis}
                firstName={firstName}
                lastInitial={lastNameOrInitial}
                roleType={roleType}
                org={organization}
              />
            )}

            <Body color="textSecondary">
              {format(date, !showExpandOption || expanded ? "MM/dd/yyyy hh:mm aaaa" : "MM/dd/yyyy")}
            </Body>
          </Grid>

          {/* TODO: Figure out how to make this work without weirdly affecting the grid:
                  https://github.com/CarinaWeb/CarinaCore/issues/997 */}
          {/*<Body style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%", minWidth: 0 }}>
            {message}
          </Body>*/}

          {!desktopSize && (
            <>
              {!showExpandOption && (
                <Body style={{ overflowWrap: "anywhere" }}>
                  {message.substr(0, 120)}
                  {message.length > 120 && "..."}
                </Body>
              )}
              {showExpandOption && expanded && (
                <Box>
                  <Body style={{ overflowWrap: "anywhere" }}>{message}</Body>
                  <Body style={{ float: "right" }}>
                    <MuiLink onClick={toggleMessageExpansion}>
                      {ready && t("button.read_less", { ns: nsCommonFormsBtns })}
                    </MuiLink>
                  </Body>
                </Box>
              )}
              {showExpandOption && !expanded && (
                <Body>
                  {message.substr(0, 30)}...{" "}
                  <MuiLink onClick={toggleMessageExpansion}>
                    {ready && t("button.read_more", { ns: nsCommonFormsBtns })}
                  </MuiLink>
                </Body>
              )}
            </>
          )}
          {desktopSize && (
            <>
              {!showExpandOption ? (
                <Body style={{ overflowWrap: "anywhere" }}>
                  {message.substr(0, 120)}
                  {message.length > 120 && "..."}
                </Body>
              ) : (
                <Body style={{ overflowWrap: "anywhere" }}>{message}</Body>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThreadMessage;
