import { Inventory2Outlined, MoreVert, OutlinedFlag } from "@mui/icons-material";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Box, Grid, IconButton, Menu, Tooltip as MuiTooltip } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CloseSidePanelIcon, OpenSidePanelIcon } from "src/assets/icons/StreamlineIcons";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import {
  JobPostTagEnum,
  ProviderProfileTagEnum,
  ReportSourceEnum,
  RoleType,
  ThreadDetailsDTO,
} from "src/generated/api_types";
import {
  nsCommonAria,
  nsCommonFormsBtns,
  nsCommonToasts,
  nsMedicaidInbox,
  nsMedicaidTagsEnums,
} from "src/i18n/Namespaces";
import { getLocalizedJobPostTag, getLocalizedProviderProfileTag, LocalizedTag } from "src/i18n/TagHelpers";
import { nameAndRole } from "src/pages/inbox/inboxComponents/AssistiveRoleThreadListCard";
import ConfirmationDialog from "src/pages/inbox/inboxComponents/ConfirmationDialog";
import { ThreadDetailsSectionProps } from "src/pages/inbox/inboxComponents/ThreadDetailsSection";
import { InboxTabState, useInboxContext } from "src/pages/inbox/InboxContext";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { UserReportModal } from "src/pages/medicaidAccount/sharedComponents/ReportUserModal";
import MenuItem from "src/reusable_view_elements/MenuItem";
import { Body, BodyEmphasis, PanelTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { getUserType } from "src/utilities/GeneralUtilities";

const AssistiveRoleThreadDetailsSection = ({ setStarred, setArchived, ...details }: ThreadDetailsSectionProps) => {
  const { showSnackbar } = useNotification();
  const { t, ready } = useTranslation([nsMedicaidInbox, nsCommonFormsBtns, nsMedicaidTagsEnums, nsCommonAria]);
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const [showActionsMenu, setShowActionsMenu] = useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [showUnarchiveModal, setShowUnarchiveModal] = useState<boolean>(false);
  const [showUnfavoriteModal, setShowUnfavoriteModal] = useState<boolean>(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  //isFavoriteAction has three states (true: "favoriting", false: "unfavoriting", undefined: default)
  const [isFavoriteAction, setIsFavoriteAction] = useState<boolean>();

  const {
    inboxSidePanelUserId,
    setInboxSidePanelUserId,
    tab,
    setTab,
    resetSearchAndPagination,
    setArchiveActionRequested,
    setUnfavoriteActionRequested,
    selectedThreadId,
  } = useInboxContext();
  const { isAssistiveRoleView } = useUserSession();

  useEffect(() => {
    // reset id of user to be displayed in the inbox panel (if the other user is a provider)
    (details.otherUser.roleType === RoleType.PROVIDER || details.otherUser.roleType === RoleType.PROXY_PROVIDER) &&
      setInboxSidePanelUserId(details.otherUser.id);
  }, []);

  // Show last initial for every role except Consumers
  const showLastInitial: boolean =
    details.otherUser.roleType !== RoleType.CONSUMER && details.otherUser.lastNameOrInitial.length > 0;

  const DiscussingJob = () => (
    <Trans
      i18nKey="discussing_job.label"
      ns={nsMedicaidInbox}
      parent={(props: PropsWithChildren<any>) => (
        <BodyEmphasis sx={{ marginTop: "14px", "& span": { display: "contents" } }}>{props.children}</BodyEmphasis>
      )}
      components={{ jblnk: <span /> }}
      values={{ job_num: details.jobPostNumber }}
    />
  );

  const showPronouns = (threadDetails: ThreadDetailsDTO): boolean => {
    if (threadDetails.consumerData?.pronouns && threadDetails.consumerData.pronouns.length > 0) {
      return true;
    } else if (threadDetails.providerData?.pronouns && threadDetails.providerData.pronouns.length > 0) return true;
    return false;
  };

  const Summary = ({
    consumerPronounTags,
    providerPronounTags,
  }: {
    consumerPronounTags?: JobPostTagEnum[];
    providerPronounTags?: ProviderProfileTagEnum[];
  }) => {
    let locizePronouns: LocalizedTag[] | undefined = undefined;
    if (consumerPronounTags && consumerPronounTags.length > 0) {
      locizePronouns = consumerPronounTags.map((tag) => getLocalizedJobPostTag(tag));
    } else if (providerPronounTags && providerPronounTags.length > 0) {
      locizePronouns = providerPronounTags.map((tag) => getLocalizedProviderProfileTag(tag));
    }
    if (!locizePronouns) return <></>;
    const pronouns = locizePronouns.map((tag) => t(tag.key, { ns: tag.namespace })).join(", ");
    return <Body>{`(${[pronouns].filter((detail) => !!detail).join(` • `)})`}</Body>;
  };

  const ThreadActionsMenu = () => {
    return (
      <Menu
        id="thread actions menu"
        anchorEl={actionsMenuAnchor}
        keepMounted // mounted in the DOM for accessibility
        open={showActionsMenu}
        onClose={() => setShowActionsMenu(false)}
      >
        {!details.archived && (
          <MenuItem
            Icon={StarOutlineRoundedIcon}
            SelectedIcon={StarRoundedIcon}
            style={{ minWidth: "185px" }}
            selected={details.starred}
            aria-label="favorite thread"
            onClick={async () => {
              setShowActionsMenu(false);
              if (tab === InboxTabState.starred_threads && details.starred) {
                setShowUnfavoriteModal(true);
              } else {
                await toggleFavoriteThread();
              }
            }}
          >
            {details.starred
              ? t("button.remove_from_favorites", { ns: nsCommonFormsBtns })
              : t("button.favorite", { ns: nsCommonFormsBtns })}
          </MenuItem>
        )}
        <MenuItem
          Icon={Inventory2Outlined}
          style={{ minWidth: "185px" }}
          aria-label={details.archived ? "unarchive thread" : "archive thread"}
          onClick={() => {
            setShowActionsMenu(false);
            details.archived ? setShowUnarchiveModal(true) : setShowArchiveModal(true);
          }}
        >
          {t(details.archived ? "button.unarchive_conversation" : "button.archive", { ns: nsCommonFormsBtns })}
        </MenuItem>
        <MenuItem
          Icon={OutlinedFlag}
          style={{ minWidth: "185px" }}
          aria-label="Report Issue"
          onClick={() => {
            setOpenReportModal(true);
            setShowActionsMenu(false);
          }}
        >
          {t("button.report_user", { ns: nsCommonFormsBtns })}
        </MenuItem>
      </Menu>
    );
  };

  async function archiveThread() {
    if (!selectedThreadId) return;
    try {
      await messagingClient.archiveThread({ threadId: selectedThreadId });
      setArchiveActionRequested(true);
      setShowArchiveModal(false);
      setArchived && setArchived(true);
      if (!isAssistiveRoleView()) {
        resetSearchAndPagination();
        setTab(InboxTabState.archived_threads);
      }
      showSnackbar(t("inbox_thread.success.archived", { ns: nsCommonToasts }), "success");
    } catch (e: unknown) {
      showSnackbar(t("error.generic", { ns: nsCommonToasts }), "error");
    }
  }

  async function unarchiveThread() {
    if (!selectedThreadId) return;
    try {
      await messagingClient.unArchiveThread({ threadId: selectedThreadId });
      setArchiveActionRequested(true);
      setShowUnarchiveModal(false);
      setArchived && setArchived(false);
      if (!isAssistiveRoleView()) {
        resetSearchAndPagination();
        setTab(InboxTabState.all_threads);
      }
      showSnackbar(t("inbox_thread.success.unarchived", { ns: nsCommonToasts }), "success");
    } catch (e: unknown) {
      showSnackbar(t("error.generic", { ns: nsCommonToasts }), "error");
    }
  }

  async function toggleFavoriteThread() {
    if (!selectedThreadId) return;
    try {
      if (details.starred) {
        await messagingClient.unStarThread({ threadId: selectedThreadId });
        setStarred && setStarred(false);
        setShowUnfavoriteModal(false);
        setIsFavoriteAction(false);
        setUnfavoriteActionRequested(true);
      } else {
        await messagingClient.starThread({ threadId: selectedThreadId });
        setStarred && setStarred(true);
        setIsFavoriteAction(true);
      }
    } catch (e: unknown) {
      showSnackbar(t("error.generic", { ns: nsCommonToasts }), "error");
    }
  }

  //Triggering display of snackbar. Using useEffect hook to avoid collision with the 'Unfavorite' modal
  useEffect(() => {
    if (isFavoriteAction === true) {
      showSnackbar(t("inbox_thread.success.added_to_favorites", { ns: nsCommonToasts }), "success");
    } else if (isFavoriteAction === false) {
      showSnackbar(t("inbox_thread.success.removed_from_favorites", { ns: nsCommonToasts }), "success");
    }
    setIsFavoriteAction(undefined); //Reset flag
  }, [isFavoriteAction]);

  return (
    <Box bgcolor={CivColors.paleBlue} p="12px 8px 16px 24px" alignContent="center">
      <ConfirmationDialog
        title={t("are_you_sure_archive_conversation.label", { ns: nsMedicaidInbox })}
        body={t("are_you_sure_archive_conversation.description", { ns: nsMedicaidInbox })}
        isDialogOpen={showArchiveModal}
        onYesClick={archiveThread}
        onNoClick={() => setShowArchiveModal(false)}
        yesButtonText={t("button.yes_archive", { ns: nsCommonFormsBtns })}
        yesButtonVariant="outlined"
      />

      <ConfirmationDialog
        title={t("are_you_sure_unarchive_conversation.label", { ns: nsMedicaidInbox })}
        body={t("are_you_sure_unarchive_conversation.description", { ns: nsMedicaidInbox, context: "assistive" })}
        isDialogOpen={showUnarchiveModal}
        onYesClick={unarchiveThread}
        onNoClick={() => setShowUnarchiveModal(false)}
        yesButtonText={t("button.yes_unarchive", { ns: nsCommonFormsBtns })}
        yesButtonVariant="outlined"
      />

      <ConfirmationDialog
        title={t("are_you_sure_unfavorite_conversation.label", { ns: nsMedicaidInbox })}
        body={t("are_you_sure_unfavorite_conversation.description", { ns: nsMedicaidInbox, context: "assistive" })}
        isDialogOpen={showUnfavoriteModal}
        onYesClick={toggleFavoriteThread}
        onNoClick={() => setShowUnfavoriteModal(false)}
        yesButtonText={t("button.yes_remove", { ns: nsCommonFormsBtns })}
        yesButtonVariant="outlined"
      />
      <UserReportModal
        subjectId={details.otherUser.id}
        subjectUserType={getUserType(details.otherUser.roleType)}
        subjectFirstName={details.otherUser.firstName}
        subjectLastName={details.otherUser.lastNameOrInitial}
        reportSource={details.jobPostId ? ReportSourceEnum.JOB_POST_MESSAGE : ReportSourceEnum.DIRECT_MESSAGE}
        open={openReportModal}
        onClose={() => setOpenReportModal(false)}
        assistedConsumerFirstName={
          getUserType(details.otherUser.roleType) === UserType.MedicaidReferralCoordinator
            ? details.consumerData?.consumerFirstName
            : undefined
        }
        assistedJobId={
          getUserType(details.otherUser.roleType) === UserType.MedicaidReferralCoordinator
            ? details.jobPostId
            : undefined
        }
      />

      {ready && <ThreadActionsMenu />}
      <Grid container justifyContent="space-between" wrap="nowrap">
        {/* Column 1 */}
        <Grid item container>
          <Grid item alignContent="center">
            <Box>
              <Grid container alignItems="center">
                <Grid item>
                  <PanelTitle
                    sx={{ marginRight: "10px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {nameAndRole(
                      t,
                      details.otherUser.roleType,
                      details.otherUser.firstName,
                      showLastInitial ? details.otherUser.lastNameOrInitial : undefined,
                      isAssistiveRoleView,
                    )}
                  </PanelTitle>
                </Grid>
                {showPronouns(details) && (
                  <Grid item>
                    <Summary
                      providerPronounTags={
                        details.otherUser.roleType === RoleType.PROVIDER ||
                        details.otherUser.roleType === RoleType.PROXY_PROVIDER
                          ? details.providerData?.pronouns
                          : undefined
                      }
                    />
                  </Grid>
                )}
              </Grid>
              {/* Show "Job #" link if we posted the job */}
              {details.jobPostId && !details.jobIsPostedByOtherUser && !details.jobIsResolved && <DiscussingJob />}
            </Box>
          </Grid>
        </Grid>
        <Grid item display="flex" alignItems="center">
          {tab !== InboxTabState.archived_threads &&
            (details.otherUser.roleType === RoleType.PROVIDER ||
              details.otherUser.roleType === RoleType.PROXY_PROVIDER) &&
            (inboxSidePanelUserId ? (
              <MuiTooltip title={t("icon.close_profile_panel", { ns: nsCommonAria })} enterTouchDelay={0}>
                <IconButton aria-label="close profile panel" size="large" sx={{ height: "48px", width: "48px" }}>
                  <CloseSidePanelIcon
                    htmlColor={CivColors.coreDarkNavy}
                    onClick={() =>
                      setInboxSidePanelUserId(inboxSidePanelUserId !== undefined ? undefined : details.otherUser.id)
                    }
                  />
                </IconButton>
              </MuiTooltip>
            ) : (
              <MuiTooltip title={t("icon.open_profile_panel", { ns: nsCommonAria })} enterTouchDelay={0}>
                <IconButton aria-label="open profile panel" size="large" sx={{ height: "48px", width: "48px" }}>
                  <OpenSidePanelIcon
                    htmlColor={CivColors.coreDarkNavy}
                    onClick={() =>
                      setInboxSidePanelUserId(inboxSidePanelUserId !== undefined ? undefined : details.otherUser.id)
                    }
                  />
                </IconButton>
              </MuiTooltip>
            ))}
          <MuiTooltip title={t("icon.more_actions", { ns: nsCommonAria })} enterTouchDelay={0}>
            <IconButton
              aria-label="more actions"
              size="large"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setActionsMenuAnchor(event.currentTarget);
                setShowActionsMenu(!showActionsMenu);
              }}
            >
              <MoreVert htmlColor={CivColors.coreDarkNavy} />
            </IconButton>
          </MuiTooltip>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AssistiveRoleThreadDetailsSection;
