import { Box, Grid, Link as MuiLink, useMediaQuery } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { nsCommonAlt, nsCommonAria, nsMedicaidInbox } from "src/i18n/Namespaces";
import { DesktopMinWidth } from "src/pages/inbox/Inbox";
import { ThreadListCardProps } from "src/pages/inbox/inboxComponents/ThreadListCard";
import { NameOrgRoleTitle } from "src/pages/inbox/InboxHelpers";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import Avatar from "src/reusable_view_elements/Avatar";
import Badge from "src/reusable_view_elements/Badge";
import { InternalLink } from "src/reusable_view_elements/Link";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { imageExists } from "src/utilities/GeneralUtilities";

const RegularThreadListCard = ({
  firstName,
  lastNameOrInitial = undefined,
  organization = undefined,
  roleType,
  date,
  jobNum = undefined,
  jobId = undefined,
  jobIsResolved = false,
  clientName = undefined,
  avatarId,
  message,
  unreadMessages = 0,
  onClick = undefined,
  hasImg,
  ...rootBoxProps
}: ThreadListCardProps) => {
  const { isInEnv } = useConfig();
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const desktopSize = useMediaQuery(DesktopMinWidth);
  const { t } = useTranslation([nsMedicaidInbox, nsCommonAria, nsCommonAlt]);

  useEffect(() => {
    if (avatarId && hasImg) {
      const profileImage = `https://s3.us-west-2.amazonaws.com/${
        isInEnv(EnvName.PROD) ? "production.carina.profile.photos" : "carina.profile.photos"
      }/${avatarId}.jpg`;
      imageExists(profileImage, (exists) => {
        if (exists) {
          setImageUrl(profileImage);
        }
      });
    }
  }, []);

  const Title = unreadMessages ? BodyEmphasis : Body;

  return (
    <Box
      borderBottom={`1px solid ${CivColors.mediumGray}`}
      bgcolor={CivColors.white}
      sx={{ padding: "16px", display: "flex" }}
      {...rootBoxProps}
    >
      <Grid container xs={7} sm={10}>
        <MuiLink
          component="button"
          onClick={onClick}
          style={{ width: "100%", textAlign: "left", textDecoration: "none" }}
          aria-label={t("inbox.view_conversation_with_name", { ns: nsCommonAria, name: firstName })}
        >
          <Grid container>
            {/* Column 1 */}
            <Grid item style={{ paddingRight: 14, cursor: "pointer" }}>
              <Avatar
                src={imageUrl}
                alt={
                  imageUrl
                    ? t("standard.headshot_alt", {
                        ns: nsCommonAlt,
                        name: firstName + lastNameOrInitial,
                      })
                    : ""
                }
                style={{
                  width: "60px",
                  height: "60px",
                  fontSize: "1.2rem",
                }}
              >
                {firstName[0]}
                {lastNameOrInitial}
              </Avatar>
            </Grid>

            {/* Column 2 */}
            <Grid item xs style={{ cursor: "pointer" }}>
              <NameOrgRoleTitle
                // component="span" is needed to nest <Badge> within <Title>
                // https://stackoverflow.com/questions/41928567/div-cannot-appear-as-a-descendant-of-p
                component={(props) => (
                  <Title component="span">
                    {props.children}
                    {!!unreadMessages && <Badge number={unreadMessages} />}
                  </Title>
                )}
                firstName={firstName}
                lastInitial={lastNameOrInitial}
                roleType={roleType}
                org={organization}
              />

              {/* TODO: Figure out how to make this work without weirdly affecting the grid:
                  https://github.com/CarinaWeb/CarinaCore/issues/997 */}
              {/*<Body style={{ textOverflow: "ellipsis", overflow: "clip", whiteSpace: "nowrap" }}>{message}</Body>*/}
              <Body style={{ marginTop: 16, fontWeight: unreadMessages ? "bold" : "normal", overflowWrap: "anywhere" }}>
                {!desktopSize && message.length > 30 && `${message.substr(0, 30)}...`}
                {!desktopSize && message.length <= 30 && message}
                {desktopSize && message}
              </Body>
            </Grid>
          </Grid>
        </MuiLink>
      </Grid>
      {/* Column 3 */}
      <Grid container xs={5} sm={2} style={{ textAlign: "right" }}>
        <Grid item xs={12}>
          <Body paragraph color="textSecondary">
            {format(date, "MM/dd/yyyy")}
          </Body>
        </Grid>
        {jobNum && jobId && (
          <Grid item xs={12}>
            {jobIsResolved ? (
              <Body gutterBottom>{t("job_number.label", { ns: nsMedicaidInbox, job_num: jobNum })}</Body>
            ) : (
              <InternalLink to={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: jobId })}>
                <Body gutterBottom>{t("job_number.label", { ns: nsMedicaidInbox, job_num: jobNum })}</Body>
              </InternalLink>
            )}
          </Grid>
        )}
        {clientName && (
          <Grid item xs={12}>
            <Body style={{ overflowWrap: "anywhere" }}>({clientName})</Body>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RegularThreadListCard;
