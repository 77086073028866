import {
  Box,
  Grid,
  PaginationItem as MUIPaginationItem,
  PaginationRenderItemParams,
  useMediaQuery,
} from "@mui/material";
import { usePostHog } from "posthog-js/react";
import qs from "qs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { PixelEventPush } from "src/analytics/fpixel";
import { ChildcareProviderProfileSummaryDTO, Page } from "src/generated/api_types";
import { nsChildcareSearchResults } from "src/i18n/Namespaces";
import { useChildCareContext } from "src/pages/childcare/ChildCareContext";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import { ChildcareSearchControllerClient } from "src/pages/childcare/ControllerClients";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import { AddressField } from "src/reusable_view_elements/form_fields/address_autocomplete/AddressField";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import NavBar from "src/reusable_view_elements/navbars/NavBar";
import { Button, FormButtonRowStyle } from "src/reusable_view_elements/Button";
import Pagination from "src/reusable_view_elements/Pagination";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { getDistanceInMiles } from "src/utilities/GeneralUtilities";
import { scrollToId } from "src/utilities/ScrollToId";
import ChildCareSearchCard from "./components/ChildCareSearchCard";

const SearchList = () => {
  const { t, ready } = useTranslation(nsChildcareSearchResults);
  const history = useHistory();
  const [providerList, setProviderList] = useState<Page<ChildcareProviderProfileSummaryDTO>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [ranSearch, setRanSearch] = useState<boolean>(false); // This is to help conditionally show "No Providers" copy
  const {
    searchInput,
    setSearchInput,
    setGeoDataId,
    pageNumber,
    setPageNumber,
    lastViewedProfile,
    setLastViewedProfile,
  } = useChildCareContext();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const posthog = usePostHog();

  // Handle URL Query Params
  const location = useLocation();
  const urlQueryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const urlSearchParam = urlQueryParams.address ? urlQueryParams.address.toString() : undefined;

  useEffect(() => {
    if (searchInput) {
      runSearch(searchInput, pageNumber - 1);
    } else if (urlSearchParam && urlSearchParam.length > 0) {
      setSearchInput(urlSearchParam); // Populate the search field with the url param to show what's being searched
      runSearch(urlSearchParam, pageNumber - 1);
    }
  }, []);

  function runSearch(__input: string, __pageNumber0Based: number) {
    if (__input.length > 0) {
      setLoading(true);
      setRanSearch(true);
      ChildcareSearchControllerClient.getChildcareProvidersWithinDistance({
        address: __input,
        distanceMinutes: 60,
        pageNumber: __pageNumber0Based,
        pageSize: 9,
      })
        .then((res) => {
          PixelEventPush.SEARCH();
          posthog?.capture("cc_search");

          setProviderList(res.data.results);
          setGeoDataId(res.data.searchedGeoInfo.geoDataId);
          setSearchInput(res.data.searchedGeoInfo.searchedAddress);
        })
        .catch((err) => {
          setError(`Error: ${err.message}`);
        })
        .finally(() => {
          setLoading(false);
          if (lastViewedProfile) {
            scrollToId(lastViewedProfile);
          } else {
            window.scroll({ top: 0 });
          }
          setLastViewedProfile(undefined);
        });
    }
  }

  function getHeader(providers: number): string {
    if (providers === 0) {
      return t("unhappy path header", { ns: nsChildcareSearchResults });
    }
    if (providers === 1) {
      return `${providers} ${t("happy path header singular", { ns: nsChildcareSearchResults })}`;
    }
    return `${providers} ${t("happy path header plural", { ns: nsChildcareSearchResults })}`;
  }

  return (
    <>
      <Helmet>
        <title>Carina | Find Child Care</title>
      </Helmet>

      <NavBar showLanguage />

      <main id="main-content">
        {/* Header and Search Bar */}
        {ready && (
          <Section bgcolor={CivColors.lightGray} paddingBottom={0}>
            <Constraint columns={10}>
              <SectionTitle align="center" paragraph>
                {getHeader(providerList?.totalElements || 0)}
              </SectionTitle>

              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm>
                  <AddressField
                    name="search-address"
                    label="Search Address"
                    value={searchInput || ""}
                    onChange={(selectedAddress) => selectedAddress && setSearchInput(selectedAddress)}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (searchInput) {
                        setPageNumber(1);
                        runSearch(searchInput, 0);
                      }
                    }}
                    style={desktopSize ? FormButtonRowStyle : {}}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
              {error && <Body style={{ paddingTop: 10 }}>{error}</Body>}
            </Constraint>
          </Section>
        )}

        {/* Loading Circle */}
        {loading && (
          <Section bgcolor={CivColors.lightGray} minHeight={300} centerContent>
            <LoadingCircle />
          </Section>
        )}

        {/* No Providers Nearby (after running search) */}
        {ready && !loading && ranSearch && (!providerList || providerList?.empty) && (
          <Section bgcolor={CivColors.lightGray} minHeight={400}>
            <Constraint columns={8}>
              <Body>{t("unhappy path body", { ns: nsChildcareSearchResults })}</Body>
            </Constraint>
          </Section>
        )}

        {/* Blank Space (before running search) */}
        {!loading && !ranSearch && (!providerList || providerList?.empty) && (
          <Section bgcolor={CivColors.lightGray} minHeight={300} />
        )}

        {/* Provider List */}
        {!loading && providerList && !providerList.empty && (
          <Section bgcolor={CivColors.lightGray}>
            <Constraint columns={12}>
              <Grid container spacing={4} alignItems="stretch">
                {providerList.content.map((ccp) => (
                  <Grid item key={ccp.providerId} xs={12} sm={6} md={4} style={{ display: "flex" }}>
                    <ChildCareSearchCard
                      id={ccp.providerId}
                      style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}
                      name={ccp.businessName || `${ccp.firstName} ${ccp.lastInitial[0]}.`}
                      hasOpenings={ccp.searchStatus === "SEEKING"}
                      location={
                        (ccp.address.city && ccp.address.state && `${ccp.address.city}, ${ccp.address.state}`) ||
                        ccp.address.generalizedLocation
                      }
                      locationLink={`${CHILD_CARE_ROUTES.searchList}/${ccp.providerId}`}
                      milesAway={getDistanceInMiles(ccp.distance)}
                      schedule={ccp.tagGroups.SCHEDULE || []}
                      languages={ccp.tagGroups.LANGUAGE || []}
                      expertises={ccp.tagGroups.SPECIALIZED_SKILL || []}
                      onButtonClick={(_link) => {
                        setLastViewedProfile(ccp.providerId);
                        if (_link) {
                          history.push(_link);
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box display="flex" justifyContent="center" paddingY={6}>
                <Pagination
                  page={pageNumber}
                  count={providerList.totalPages}
                  renderItem={(item: PaginationRenderItemParams) => {
                    return (
                      <MUIPaginationItem
                        {...item}
                        onClick={() => {
                          setPageNumber(item.page || 1); // item.page is 1-based
                          if (searchInput) {
                            runSearch(searchInput, item.page ? item.page - 1 : 0);
                          }
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Constraint>
          </Section>
        )}
      </main>

      <Footer />
    </>
  );
};

export default SearchList;
