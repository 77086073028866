/* tslint:disable */
/* eslint-disable */

export interface HttpClient<O> {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: O; }): RestResponse<R>;
}

export class AuthControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/auth/login
     * Java method: org.carina.core.api.framework.security.auth.AuthController.login
     */
    login(formData: LoginRequestDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/auth/login`, data: formData, options: options });
    }

    /**
     * HTTP GET /api/auth/logout
     * Java method: org.carina.core.api.framework.security.auth.AuthController.logout
     */
    logout(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/auth/logout`, options: options });
    }
}

export class AuthCredentialsMigrationControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/migration/batchSyncUserCreds
     * Java method: org.carina.core.api.external_services.anti_corruption.AuthCredentialsMigrationController.syncUserCreds
     */
    syncUserCreds(dtoList: SyncUserCredsDTO[], options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/migration/batchSyncUserCreds`, data: dtoList, options: options });
    }
}

export class AvailabilityStatusCronControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /cron/ccProfileAvailability/systemUpdate
     * Java method: org.carina.core.api.domain.offerings.medicaid.provider.AvailabilityStatusCronController.systemUpdateAvailabilitiesOfCCProfiles
     */
    systemUpdateAvailabilitiesOfCCProfiles(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/ccProfileAvailability/systemUpdate`, options: options });
    }

    /**
     * HTTP POST /cron/profileAvailability/systemUpdate
     * Java method: org.carina.core.api.domain.offerings.medicaid.provider.AvailabilityStatusCronController.systemUpdateAvailabilitiesOfProfiles
     */
    systemUpdateAvailabilitiesOfProfiles(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/profileAvailability/systemUpdate`, options: options });
    }
}

export class CarinaAdminFunderManagementControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/admin/funder/apiConfig/{id}/changeStatus
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.changeApiStatus
     */
    changeApiStatus(id: string, dto: CarinaAdminFunderManagementController_ChangeStatusDTO, options?: O): RestResponse<boolean> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/funder/apiConfig/${id}/changeStatus`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/admin/funder/apiConfig/{id}/test
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.testApi
     */
    testApi(id: string, options?: O): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/funder/apiConfig/${id}/test`, options: options });
    }

    /**
     * HTTP POST /api/admin/funder/apiConfig/{id}/updateCreds
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.setApiCreds
     */
    setApiCreds(id: string, dto: CarinaAdminFunderManagementController_ApiCredsDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/funder/apiConfig/${id}/updateCreds`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/admin/funder/list
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.getFunders
     */
    getFunders(options?: O): RestResponse<CarinaAdminFunderManagementController_FunderListingDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/funder/list`, options: options });
    }

    /**
     * HTTP GET /api/admin/funder/role/regPath/{id}/detail
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.getRegPath
     */
    getRegPath(id: string, options?: O): RestResponse<CarinaAdminFunderManagementController_RegPathDetailDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/funder/role/regPath/${id}/detail`, options: options });
    }

    /**
     * HTTP GET /api/admin/funder/role/regStep/{id}/detail
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.getRegStep
     */
    getRegStep(id: string, options?: O): RestResponse<CarinaAdminFunderManagementController_RegistrationStepDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/funder/role/regStep/${id}/detail`, options: options });
    }

    /**
     * HTTP POST /api/admin/funder/role/regStep/{id}/update
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.updateRegStep
     */
    updateRegStep(id: string, dto: CarinaAdminFunderManagementController_RegStepUpdateDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/funder/role/regStep/${id}/update`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/admin/funder/role/verificationRegStep/{id}/update
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.updateVerificationRegStep
     */
    updateVerificationRegStep(id: string, dto: CarinaAdminFunderManagementController_VerificationEndpointUpdateDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/funder/role/verificationRegStep/${id}/update`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/admin/funder/role/{id}/detail
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.getRoleConfig
     */
    getRoleConfig(id: string, options?: O): RestResponse<CarinaAdminFunderManagementController_RoleDetailDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/funder/role/${id}/detail`, options: options });
    }

    /**
     * HTTP GET /api/admin/funder/{id}/detail
     * Java method: org.carina.core.api.domain.management.funder.CarinaAdminFunderManagementController.getFunder
     */
    getFunder(id: string, options?: O): RestResponse<CarinaAdminFunderManagementController_FunderDetailDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/funder/${id}/detail`, options: options });
    }
}

export class CarinaAdminUserManagementControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/admin/requestPasswordReset/{userId}
     * Java method: org.carina.core.api.domain.management.CarinaAdminUserManagementController.requestPasswordReset
     */
    requestPasswordReset(userId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/requestPasswordReset/${userId}`, options: options });
    }
}

export class CarinaAdminUtilityControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/admin/block_person
     * Java method: org.carina.core.api.domain.management.CarinaAdminUtilityController.blockperson
     */
    blockperson(dto: BlockPersonDTO, options?: O): RestResponse<Nullable<string>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/block_person`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/admin/decrypt_verification/{id}
     * Java method: org.carina.core.api.domain.management.CarinaAdminUtilityController.decryptVerification
     */
    decryptVerification(id: string, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/decrypt_verification/${id}`, options: options });
    }

    /**
     * HTTP POST /api/admin/geocode
     * Java method: org.carina.core.api.domain.management.CarinaAdminUtilityController.geocode
     */
    geocode(address: string, options?: O): RestResponse<Nullable<string>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/geocode`, data: address, options: options });
    }

    /**
     * HTTP POST /api/admin/shorten_link
     * Java method: org.carina.core.api.domain.management.CarinaAdminUtilityController.shortenLink
     */
    shortenLink(queryParams: { url: string; }, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/shorten_link`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/admin/verification_inputs
     * Java method: org.carina.core.api.domain.management.CarinaAdminUtilityController.verificationInputs
     */
    verificationInputs(queryParams: { regRecordUUID: string; }, options?: O): RestResponse<Nullable<string>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/verification_inputs`, queryParams: queryParams, options: options });
    }
}

export class ChildcareControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/public/childcare/profile/availability/{id}/{availability}
     * Java method: org.carina.core.api.domain.offerings.childcare.ChildcareController.updateAvailabilityPost
     */
    updateAvailabilityPost(id: string, availability: ProviderProfileTagEnum, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/public/childcare/profile/availability/${id}/${availability}`, options: options });
    }

    /**
     * HTTP POST /api/public/childcare/provider/profile/{providerId}
     * Java method: org.carina.core.api.domain.offerings.childcare.ChildcareController.getChildcareProviderProfileWithDistance
     */
    getChildcareProviderProfileWithDistance(providerId: string, dto: ChildCareProfileRequestDTO, options?: O): RestResponse<ChildcareProviderProfileResponseDetailWithDistanceDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/public/childcare/provider/profile/${providerId}`, data: dto, options: options });
    }
}

export class ChildcareInvitesControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/invites/childcare/create
     * Java method: org.carina.core.api.domain.management.invite.ChildcareInvitesController.createChildcareInvite
     */
    createChildcareInvite(dto: ChildcareInvitesController_ChildcareInviteDTO, options?: O): RestResponse<ServiceResponse<string>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/invites/childcare/create`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/invites/childcare/invitedDetails
     * Java method: org.carina.core.api.domain.management.invite.ChildcareInvitesController.getInvitedChildcareProviderDetails
     */
    getInvitedChildcareProviderDetails(options?: O): RestResponse<InvitedChildcareProviderDetailsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/childcare/invitedDetails`, options: options });
    }
}

export class ChildcareMessagingControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/childcare/messaging/send_message
     * Java method: org.carina.core.api.domain.messages.ChildcareMessagingController.sendMessageToChildcareProvider
     */
    sendMessageToChildcareProvider(dto: MessageToChildcareProviderDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/childcare/messaging/send_message`, data: dto, options: options });
    }
}

export class ChildcareProviderProfileControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/childcare/provider/profile/create
     * Java method: org.carina.core.api.domain.offerings.childcare.ChildcareProviderProfileController.createChildcareProviderProfile
     */
    createChildcareProviderProfile(profileValues: ChildcareProviderProfileRequestDetailDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/childcare/provider/profile/create`, data: profileValues, options: options });
    }

    /**
     * HTTP GET /api/childcare/provider/profile/portal
     * Java method: org.carina.core.api.domain.offerings.childcare.ChildcareProviderProfileController.getChildcareProviderPortal
     */
    getChildcareProviderPortal(options?: O): RestResponse<ChildcareProviderPortalDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/childcare/provider/profile/portal`, options: options });
    }

    /**
     * HTTP POST /api/childcare/provider/profile/update/{id}
     * Java method: org.carina.core.api.domain.offerings.childcare.ChildcareProviderProfileController.updateChildcareProviderProfile
     */
    updateChildcareProviderProfile(id: string, dto: ChildcareProviderProfileRequestDetailDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/childcare/provider/profile/update/${id}`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/childcare/provider/profile/{id}
     * Java method: org.carina.core.api.domain.offerings.childcare.ChildcareProviderProfileController.getChildcareProviderProfile
     */
    getChildcareProviderProfile(id: string, options?: O): RestResponse<ChildcareProviderProfileResponseDetailDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/childcare/provider/profile/${id}`, options: options });
    }
}

export class ChildcareProviderProfileSearchControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/childcare/provider/profile/search
     * Java method: org.carina.core.api.domain.search.childcare.provider_profile.ChildcareProviderProfileSearchController.getChildcareProvidersWithinDistance
     */
    getChildcareProvidersWithinDistance(dto: ChildcareProvidersWithinDistanceInputDTO, options?: O): RestResponse<ChildcareProvidersWithinDistanceResultDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/childcare/provider/profile/search`, data: dto, options: options });
    }
}

export class DirectionsControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/geo/travel/{type}/{startAddressId}/{endAddressId}
     * Java method: org.carina.core.api.external_services.geo.DirectionsController.getDirectionsData
     */
    getDirectionsData(type: TravelType, startAddressId: string, endAddressId: string, options?: O): RestResponse<TravelDataDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/geo/travel/${type}/${startAddressId}/${endAddressId}`, options: options });
    }
}

export class EnvironmentConfigControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/config/retrieve
     * Java method: org.carina.core.api.external_services.env_config.EnvironmentConfigController.config
     */
    config(options?: O): RestResponse<EnvironmentConfig> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/config/retrieve`, options: options });
    }
}

export class FindInviteControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/invites/find
     * Java method: org.carina.core.api.domain.management.invite.FindInviteController.getToken
     */
    getToken(queryParams: { token: string; }, options?: O): RestResponse<PrivatePayInvite_DTO_Response_Find> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/find`, queryParams: queryParams, options: options });
    }
}

export class FunderAdminToolsControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/funder/requestPasswordReset/{userId}
     * Java method: org.carina.core.api.domain.per_customer.funder.FunderAdminToolsController.requestPasswordReset
     */
    requestPasswordReset(userId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/funder/requestPasswordReset/${userId}`, options: options });
    }
}

export class FunderSelectionControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/admin/funder/selectOptions
     * Java method: org.carina.core.api.domain.management.FunderSelectionController.fetchSelectOptions
     */
    fetchSelectOptions(filterOptions: FunderSelectionController_FilterOptions, options?: O): RestResponse<Funder_DTO_Response_Listing[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/funder/selectOptions`, data: filterOptions, options: options });
    }
}

export class GroupInviteControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/invites/group/generate
     * Java method: org.carina.core.api.domain.per_customer.funder.registration.group_invite.GroupInviteController.generateInvite
     */
    generateInvite(dto: GenerateInviteRequestDTO, options?: O): RestResponse<GroupInviteResponseDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/invites/group/generate`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/invites/group/last-sent-by-me
     * Java method: org.carina.core.api.domain.per_customer.funder.registration.group_invite.GroupInviteController.getLastGroupInviteSentByMe
     */
    getLastGroupInviteSentByMe(options?: O): RestResponse<GroupInviteResponseDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/group/last-sent-by-me`, options: options });
    }

    /**
     * HTTP POST /api/invites/group/re-generate
     * Java method: org.carina.core.api.domain.per_customer.funder.registration.group_invite.GroupInviteController.reGenerateGroupInvite
     */
    reGenerateGroupInvite(options?: O): RestResponse<GroupInviteResponseDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/invites/group/re-generate`, options: options });
    }

    /**
     * HTTP GET /api/invites/group/{funderId}/detail/activeCaseManagers
     * Java method: org.carina.core.api.domain.per_customer.funder.registration.group_invite.GroupInviteController.getActiveCaseManagers
     */
    getActiveCaseManagers(funderId: string, options?: O): RestResponse<ActiveCaseManagersResponseDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/group/${funderId}/detail/activeCaseManagers`, options: options });
    }

    /**
     * HTTP GET /api/invites/group/{funderId}/detail/groupInviteRegPaths
     * Java method: org.carina.core.api.domain.per_customer.funder.registration.group_invite.GroupInviteController.getGroupInviteRegPaths
     */
    getGroupInviteRegPaths(funderId: string, options?: O): RestResponse<RoleConfigsWGroupInviteDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/group/${funderId}/detail/groupInviteRegPaths`, options: options });
    }
}

export class HealthcheckControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/healthcheck/db
     * Java method: org.carina.core.api.domain.management.HealthcheckController.doDBHealthCheck
     */
    doDBHealthCheck(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/healthcheck/db`, options: options });
    }
}

export class InviteManagementControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/invites/details/{id}
     * Java method: org.carina.core.api.domain.management.invite.InviteManagementController.getDetails
     */
    getDetails(id: string, options?: O): RestResponse<PrivatePayInvite_DTO_Response_Details> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/details/${id}`, options: options });
    }

    /**
     * HTTP GET /api/invites/find/all
     * Java method: org.carina.core.api.domain.management.invite.InviteManagementController.getAgencyAdminInvites
     */
    getAgencyAdminInvites(options?: O): RestResponse<AgencyAdminInviteDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/find/all`, options: options });
    }

    /**
     * HTTP GET /api/invites/generate
     * Java method: org.carina.core.api.domain.management.invite.InviteManagementController.create
     */
    create(queryParams: { firstName: string; lastName: string; email: string; agencyId: string; }, options?: O): RestResponse<GenericInviteControllerResponse> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/generate`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/invites/reinvite/{inviteId}
     * Java method: org.carina.core.api.domain.management.invite.InviteManagementController.reinvite
     */
    reinvite(inviteId: string, options?: O): RestResponse<GenericInviteControllerResponse> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/invites/reinvite/${inviteId}`, options: options });
    }

    /**
     * HTTP POST /api/invites/update/{inviteId}
     * Java method: org.carina.core.api.domain.management.invite.InviteManagementController.update
     */
    update(inviteId: string, updateDTO: PrivatePayInvite_DTO_Request_Update, options?: O): RestResponse<GenericInviteControllerResponse> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/invites/update/${inviteId}`, data: updateDTO, options: options });
    }
}

export class JobPostCronControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /cron/jobpost/systemDelete
     * Java method: org.carina.core.api.domain.offerings.medicaid.consumer.JobPostCronController.systemDeleteAgedJobPosts
     */
    systemDeleteAgedJobPosts(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/jobpost/systemDelete`, options: options });
    }
}

export class JobPostSearchAndFilterControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/job_post/filter/clear
     * Java method: org.carina.core.api.domain.search.job_post.JobPostSearchAndFilterController.clearFilter
     */
    clearFilter(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/job_post/filter/clear`, options: options });
    }

    /**
     * HTTP GET /api/job_post/filter/content
     * Java method: org.carina.core.api.domain.search.job_post.JobPostSearchAndFilterController.getFilterContent
     */
    getFilterContent(options?: O): RestResponse<JobPostSearchDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/job_post/filter/content`, options: options });
    }

    /**
     * HTTP GET /api/job_post/filter/notification/email
     * Java method: org.carina.core.api.domain.search.job_post.JobPostSearchAndFilterController.getEmailFilterNotification
     */
    getEmailFilterNotification(options?: O): RestResponse<Nullable<EmailFilterNotificationDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/job_post/filter/notification/email`, options: options });
    }

    /**
     * HTTP POST /api/job_post/filter/notification/email/update
     * Java method: org.carina.core.api.domain.search.job_post.JobPostSearchAndFilterController.updateEmailFilterNotification
     */
    updateEmailFilterNotification(dto: UpdateEmailFilterNotificationDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/job_post/filter/notification/email/update`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/job_post/filter/persist
     * Java method: org.carina.core.api.domain.search.job_post.JobPostSearchAndFilterController.persistFilter
     */
    persistFilter(dto: JobPostSearchDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/job_post/filter/persist`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/job_post/provider/preferences
     * Java method: org.carina.core.api.domain.search.job_post.JobPostSearchAndFilterController.getProviderPreferences
     */
    getProviderPreferences(options?: O): RestResponse<ProviderFilterPreferences> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/job_post/provider/preferences`, options: options });
    }

    /**
     * HTTP POST /api/job_post/search
     * Java method: org.carina.core.api.domain.search.job_post.JobPostSearchAndFilterController.search
     */
    search(dto: JobPostSearchDTO, queryParams: { selectedFunderId: string; }, options?: O): RestResponse<Page<JobPostListDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/job_post/search`, queryParams: queryParams, data: dto, options: options });
    }
}

export class LocizeControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/locize/resources/update
     * Java method: org.carina.core.api.external_services.localization.LocizeController.updateResources
     */
    updateResources(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/locize/resources/update`, options: options });
    }

    /**
     * HTTP POST /api/locize/webhook/notify/update
     * Java method: org.carina.core.api.external_services.localization.LocizeController.webhookNotifyOnChange
     */
    webhookNotifyOnChange(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/locize/webhook/notify/update`, options: options });
    }
}

export class MedicaidConsumerControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/medicaid/consumer/jobs/delete/{id}
     * Java method: org.carina.core.api.domain.offerings.medicaid.consumer.MedicaidConsumerController.deleteJobPost
     */
    deleteJobPost(id: string, deleteJobPostDTO: DeleteJobPostDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/consumer/jobs/delete/${id}`, data: deleteJobPostDTO, options: options });
    }

    /**
     * HTTP GET /api/medicaid/consumer/jobs/deleted/page
     * Java method: org.carina.core.api.domain.offerings.medicaid.consumer.MedicaidConsumerController.getDeletedJobPostsPage
     */
    getDeletedJobPostsPage(queryParams: { pageNumber: number; }, options?: O): RestResponse<Page<JobPostListDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/consumer/jobs/deleted/page`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/medicaid/consumer/jobs/list
     * Java method: org.carina.core.api.domain.offerings.medicaid.consumer.MedicaidConsumerController.getJobPostList
     */
    getJobPostList(options?: O): RestResponse<JobPostListDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/consumer/jobs/list`, options: options });
    }

    /**
     * HTTP GET /api/medicaid/consumer/jobs/page
     * Java method: org.carina.core.api.domain.offerings.medicaid.consumer.MedicaidConsumerController.getMyJobPostPage
     */
    getMyJobPostPage(queryParams: { pageNumber: number; }, options?: O): RestResponse<Page<JobPostListDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/consumer/jobs/page`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/medicaid/consumer/jobs/post
     * Java method: org.carina.core.api.domain.offerings.medicaid.consumer.MedicaidConsumerController.postJob
     */
    postJob(jobPostDTO: JobPostAndRepostDTO, options?: O): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/consumer/jobs/post`, data: jobPostDTO, options: options });
    }

    /**
     * HTTP POST /api/medicaid/consumer/jobs/renew/{id}
     * Java method: org.carina.core.api.domain.offerings.medicaid.consumer.MedicaidConsumerController.renewJobPost
     */
    renewJobPost(id: string, options?: O): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/consumer/jobs/renew/${id}`, options: options });
    }

    /**
     * HTTP POST /api/medicaid/consumer/jobs/update/{id}
     * Java method: org.carina.core.api.domain.offerings.medicaid.consumer.MedicaidConsumerController.updateJobPost
     */
    updateJobPost(id: string, jobPostDTO: JobPostAndRepostDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/consumer/jobs/update/${id}`, data: jobPostDTO, options: options });
    }

    /**
     * HTTP GET /api/medicaid/consumer/providers/list/all-messaged
     * Java method: org.carina.core.api.domain.offerings.medicaid.consumer.MedicaidConsumerController.getAllMessagedProviders
     */
    getAllMessagedProviders(options?: O): RestResponse<SurveyProviderDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/consumer/providers/list/all-messaged`, options: options });
    }
}

export class MedicaidControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/medicaid/shared/job/bookmark
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.bookmarkJobPost
     */
    bookmarkJobPost(queryParams: { jobId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/shared/job/bookmark`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/medicaid/shared/job/unbookmark
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.unBookmarkJobPost
     */
    unBookmarkJobPost(queryParams: { jobId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/shared/job/unbookmark`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/job/{id}
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getJobPostInfo
     */
    getJobPostInfo(id: string, options?: O): RestResponse<JobPostListDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/job/${id}`, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/jobs/list/bookmarked
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getBookMarkedJobs
     */
    getBookMarkedJobs(queryParams: { selectedFunderId: string; }, options?: O): RestResponse<JobPostListDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/jobs/list/bookmarked`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/myaddress
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getMyAddress
     */
    getMyAddress(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/myaddress`, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/profile/settings
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getUserSettings
     */
    getUserSettings(options?: O): RestResponse<CurrentSettingsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/profile/settings`, options: options });
    }

    /**
     * HTTP POST /api/medicaid/shared/profile/settings
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.setUserSettings
     */
    setUserSettings(settings: CurrentSettingsDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/shared/profile/settings`, data: settings, options: options });
    }

    /**
     * HTTP POST /api/medicaid/shared/profile/settings/email
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.updateEmail
     */
    updateEmail(dto: UpdateEmailDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/shared/profile/settings/email`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/medicaid/shared/profile/settings/language_preference
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.updateLanguagePreference
     */
    updateLanguagePreference(dto: UpdateLangPrefDTO, options?: O): RestResponse<LocizeLanguage> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/shared/profile/settings/language_preference`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/provider/profile/assistive/{id}
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getProviderProfileForAssistive
     */
    getProviderProfileForAssistive(id: string, options?: O): RestResponse<MedicaidProviderProfile_DTO_Response_ProfileDetails> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/provider/profile/assistive/${id}`, options: options });
    }

    /**
     * HTTP POST /api/medicaid/shared/provider/profile/bookmark
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.bookmarkProfile
     */
    bookmarkProfile(queryParams: { profileId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/shared/provider/profile/bookmark`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/medicaid/shared/provider/profile/unbookmark
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.unBookmarkProfile
     */
    unBookmarkProfile(queryParams: { profileId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/shared/provider/profile/unbookmark`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/provider/profile/{id}
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getProviderProfile
     */
    getProviderProfile(id: string, options?: O): RestResponse<MedicaidProviderProfile_DTO_Response_ProfileDetails> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/provider/profile/${id}`, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/providers/list/bookmarked
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getBookMarkedProfiles
     */
    getBookMarkedProfiles(queryParams: { selectedFunderId: string; }, options?: O): RestResponse<ProviderListDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/providers/list/bookmarked`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/medicaid/shared/report/submit
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.submitUserReport
     */
    submitUserReport(payload: UserReportPayload, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/shared/report/submit`, data: payload, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/report/userinfo
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getReportableUserInfo
     */
    getReportableUserInfo(queryParams: { userId: string; }, options?: O): RestResponse<ReportableUserInfo> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/report/userinfo`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/medicaid/shared/reportConsumer/submit
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.submitConsumerReport
     */
    submitConsumerReport(payload: ConsumerReportPayload, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/shared/reportConsumer/submit`, data: payload, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/show_settings_banner
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getUserNewMessageSettings
     */
    getUserNewMessageSettings(options?: O): RestResponse<NewMessageSettingsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/show_settings_banner`, options: options });
    }

    /**
     * HTTP GET /api/medicaid/shared/surveyJobInfo/{id}
     * Java method: org.carina.core.api.domain.offerings.medicaid.MedicaidController.getSurveyJobPostInfo
     */
    getSurveyJobPostInfo(id: string, options?: O): RestResponse<SurveyJobInfoDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/shared/surveyJobInfo/${id}`, options: options });
    }
}

export class MedicaidCoordinatorControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/medicaid/coordinator/coordinators/list
     * Java method: org.carina.core.api.domain.offerings.medicaid.field_coordinator.MedicaidCoordinatorController.getCoordinatorsList
     */
    getCoordinatorsList(options?: O): RestResponse<CoordinatorsListDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/coordinator/coordinators/list`, options: options });
    }

    /**
     * HTTP GET /api/medicaid/coordinator/jobPosts/list
     * Java method: org.carina.core.api.domain.offerings.medicaid.field_coordinator.MedicaidCoordinatorController.getOrgJobPostsList
     */
    getOrgJobPostsList(options?: O): RestResponse<OrganizationJobPostDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/medicaid/coordinator/jobPosts/list`, options: options });
    }
}

export class MedicaidInvitesControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/invites/medicaid/create
     * Java method: org.carina.core.api.domain.management.invite.MedicaidInvitesController.createMedicaidInvite
     */
    createMedicaidInvite(dto: MedicaidInvitesController_MedicaidInviteDTO, options?: O): RestResponse<ServiceResponse<string>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/invites/medicaid/create`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/invites/medicaid/organizations
     * Java method: org.carina.core.api.domain.management.invite.MedicaidInvitesController.getFunderOrganizations
     */
    getFunderOrganizations(queryParams: { funderId: string; }, options?: O): RestResponse<MedicaidInvitesController_OrganizationOptionDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/medicaid/organizations`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/invites/medicaid/reg_paths
     * Java method: org.carina.core.api.domain.management.invite.MedicaidInvitesController.getMedicaidInviteRegPaths
     */
    getMedicaidInviteRegPaths(queryParams: { funderId: string; }, options?: O): RestResponse<MedicaidInvitesController_MedicaidInviteRegPathOptionDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/invites/medicaid/reg_paths`, queryParams: queryParams, options: options });
    }
}

export class MedicaidProviderControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/medicaid/provider/profile/photo/update
     * Java method: org.carina.core.api.domain.offerings.medicaid.provider.MedicaidProviderController.updateProfilePhoto
     */
    updateProfilePhoto(photoDTO: PhotoDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/provider/profile/photo/update`, data: photoDTO, options: options });
    }

    /**
     * HTTP POST /api/medicaid/provider/profile/{id}
     * Java method: org.carina.core.api.domain.offerings.medicaid.provider.MedicaidProviderController.updateProviderProfile
     */
    updateProviderProfile(id: string, dto: MedicaidProviderProfile_DTO_Request_ProfileDetails, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/provider/profile/${id}`, data: dto, options: options });
    }
}

export class MessagingControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/messaging/job_responses
     * Java method: org.carina.core.api.domain.messages.MessagingController.getJobResponses
     */
    getJobResponses(pageInfo: PageInfo, options?: O): RestResponse<Page<JobResponsesDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/messaging/job_responses`, data: pageInfo, options: options });
    }

    /**
     * HTTP GET /api/messaging/job_responses/{jobPostId}
     * Java method: org.carina.core.api.domain.messages.MessagingController.getResponsesForJob
     */
    getResponsesForJob(jobPostId: string, options?: O): RestResponse<JobResponseThreadListDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/messaging/job_responses/${jobPostId}`, options: options });
    }

    /**
     * HTTP POST /api/messaging/message/initialSend
     * Java method: org.carina.core.api.domain.messages.MessagingController.sendInitialMessage
     */
    sendInitialMessage(initialMessagePayload: InitialMessagePayload, options?: O): RestResponse<InitialMessageResponseDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/messaging/message/initialSend`, data: initialMessagePayload, options: options });
    }

    /**
     * HTTP POST /api/messaging/message/send
     * Java method: org.carina.core.api.domain.messages.MessagingController.sendMessage
     */
    sendMessage(messagePayload: MessagePayload, options?: O): RestResponse<MessageResponseDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/messaging/message/send`, data: messagePayload, options: options });
    }

    /**
     * HTTP POST /api/messaging/thread/archive
     * Java method: org.carina.core.api.domain.messages.MessagingController.archiveThread
     */
    archiveThread(queryParams: { threadId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/messaging/thread/archive`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/messaging/thread/details
     * Java method: org.carina.core.api.domain.messages.MessagingController.getThreadDetails
     */
    getThreadDetails(queryParams: { threadId: string; }, options?: O): RestResponse<ThreadDetailsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/messaging/thread/details`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/messaging/thread/details/assistive
     * Java method: org.carina.core.api.domain.messages.MessagingController.getThreadDetailsForAssistive
     */
    getThreadDetailsForAssistive(queryParams: { threadId: string; }, options?: O): RestResponse<ThreadDetailsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/messaging/thread/details/assistive`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/messaging/thread/list
     * Java method: org.carina.core.api.domain.messages.MessagingController.getThreads
     */
    getThreads(req: GetThreadsRequestDTO, options?: O): RestResponse<Page<ThreadListDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/messaging/thread/list`, data: req, options: options });
    }

    /**
     * HTTP GET /api/messaging/thread/messages/list
     * Java method: org.carina.core.api.domain.messages.MessagingController.getMessageListForThread
     */
    getMessageListForThread(queryParams: { threadId: string; }, options?: O): RestResponse<MessageListDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/messaging/thread/messages/list`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/messaging/thread/messages/list/assistive
     * Java method: org.carina.core.api.domain.messages.MessagingController.getMessageListForThreadForAssistive
     */
    getMessageListForThreadForAssistive(queryParams: { threadId: string; }, options?: O): RestResponse<MessageListDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/messaging/thread/messages/list/assistive`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/messaging/thread/star
     * Java method: org.carina.core.api.domain.messages.MessagingController.starThread
     */
    starThread(queryParams: { threadId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/messaging/thread/star`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/messaging/thread/survey
     * Java method: org.carina.core.api.domain.messages.MessagingController.postMatchingSurveyForThread
     */
    postMatchingSurveyForThread(surveyPayload: SurveyPayload, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/messaging/thread/survey`, data: surveyPayload, options: options });
    }

    /**
     * HTTP POST /api/messaging/thread/unarchive
     * Java method: org.carina.core.api.domain.messages.MessagingController.unArchiveThread
     */
    unArchiveThread(queryParams: { threadId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/messaging/thread/unarchive`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/messaging/thread/unread/count
     * Java method: org.carina.core.api.domain.messages.MessagingController.getUnreadThreadCount
     */
    getUnreadThreadCount(queryParams: { selectedFunderId: string; }, options?: O): RestResponse<number> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/messaging/thread/unread/count`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/messaging/thread/unstar
     * Java method: org.carina.core.api.domain.messages.MessagingController.unStarThread
     */
    unStarThread(queryParams: { threadId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/messaging/thread/unstar`, queryParams: queryParams, options: options });
    }
}

export class NotificationCronControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /cron/notifications/daily
     * Java method: org.carina.core.api.domain.notification.NotificationCronController.processDailyNotifications
     */
    processDailyNotifications(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/notifications/daily`, options: options });
    }

    /**
     * HTTP POST /cron/notifications/daily_weekday
     * Java method: org.carina.core.api.domain.notification.NotificationCronController.processDailyWeekdayNotifications
     */
    processDailyWeekdayNotifications(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/notifications/daily_weekday`, options: options });
    }

    /**
     * HTTP POST /cron/notifications/delayed
     * Java method: org.carina.core.api.domain.notification.NotificationCronController.processDelayedNotifications
     */
    processDelayedNotifications(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/notifications/delayed`, options: options });
    }

    /**
     * HTTP POST /cron/notifications/marketing_blast
     * Java method: org.carina.core.api.domain.notification.NotificationCronController.processMarketingNotificationBlast
     */
    processMarketingNotificationBlast(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/notifications/marketing_blast`, options: options });
    }

    /**
     * HTTP POST /cron/notifications/message_reminder
     * Java method: org.carina.core.api.domain.notification.NotificationCronController.processMessageReminderNotifications
     */
    processMessageReminderNotifications(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/notifications/message_reminder`, options: options });
    }

    /**
     * HTTP POST /cron/notifications/monthly
     * Java method: org.carina.core.api.domain.notification.NotificationCronController.processMonthlyNotifications
     */
    processMonthlyNotifications(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/notifications/monthly`, options: options });
    }

    /**
     * HTTP POST /cron/notifications/weekly
     * Java method: org.carina.core.api.domain.notification.NotificationCronController.processWeeklyNotifications
     */
    processWeeklyNotifications(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cron/notifications/weekly`, options: options });
    }
}

export class OnboardingControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/onboarding/complete
     * Java method: org.carina.core.api.domain.offerings.onboarding.OnboardingController.completeProcess
     */
    completeProcess(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/onboarding/complete`, options: options });
    }

    /**
     * HTTP GET /api/onboarding/prepopulated_data/address
     * Java method: org.carina.core.api.domain.offerings.onboarding.OnboardingController.getPrepopulatedAddress
     */
    getPrepopulatedAddress(options?: O): RestResponse<Nullable<string>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/onboarding/prepopulated_data/address`, options: options });
    }

    /**
     * HTTP POST /api/onboarding/progress
     * Java method: org.carina.core.api.domain.offerings.onboarding.OnboardingController.completeOnboardingStep
     */
    completeOnboardingStep(stepDTO: OnboardingStepDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/onboarding/progress`, data: stepDTO, options: options });
    }

    /**
     * HTTP POST /api/onboarding/progressProfile
     * Java method: org.carina.core.api.domain.offerings.onboarding.OnboardingController.completeProfileOnboardingStep
     */
    completeProfileOnboardingStep(profileStepDTO: OnboardingProfileStepDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/onboarding/progressProfile`, data: profileStepDTO, options: options });
    }

    /**
     * HTTP GET /api/onboarding/state
     * Java method: org.carina.core.api.domain.offerings.onboarding.OnboardingController.getOnboardingState
     */
    getOnboardingState(options?: O): RestResponse<OnboardingDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/onboarding/state`, options: options });
    }
}

export class PPHCAgencyDashboardConsumersControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/pphc/dashboard/agency/consumers/archive/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardConsumersController.postClientArchive
     */
    postClientArchive(id: string, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/dashboard/agency/consumers/archive/${id}`, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/agency/consumers/details/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardConsumersController.getConsumerApplicationDetails
     */
    getConsumerApplicationDetails(id: string, options?: O): RestResponse<ConsumerApplication_DTO_Response_Details> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/agency/consumers/details/${id}`, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/agency/consumers/list
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardConsumersController.listApplications
     */
    listApplications(queryParams: { agencyId: string; }, options?: O): RestResponse<ConsumerApplication_DTO_Response_Listing[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/agency/consumers/list`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/agency/consumers/listAgencyAdmins
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardConsumersController.listAgencyAdmins
     */
    listAgencyAdmins(options?: O): RestResponse<AgencyAdmin_DTO_Response_Session[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/agency/consumers/listAgencyAdmins`, options: options });
    }

    /**
     * HTTP POST /api/pphc/dashboard/agency/consumers/listPaging
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardConsumersController.pagingListApplications
     */
    pagingListApplications(pageInfo: PageInfo, options?: O): RestResponse<Page<ConsumerApplication_DTO_Response_Listing>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/dashboard/agency/consumers/listPaging`, data: pageInfo, options: options });
    }

    /**
     * HTTP POST /api/pphc/dashboard/agency/consumers/update/assigned_admin/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardConsumersController.postAssignedAdminUpdate
     */
    postAssignedAdminUpdate(id: string, newAdminDTO: PPHCAgencyDashboardConsumersController_NewAssignedAdminDTO, options?: O): RestResponse<ConsumerApplication_DTO_Response_Listing> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/dashboard/agency/consumers/update/assigned_admin/${id}`, data: newAdminDTO, options: options });
    }

    /**
     * HTTP POST /api/pphc/dashboard/agency/consumers/update/status/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardConsumersController.postConsumerApplicationStatusUpdate
     */
    postConsumerApplicationStatusUpdate(id: string, newStatusDTO: NewStatusDTO, options?: O): RestResponse<ConsumerApplication_DTO_Response_Listing> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/dashboard/agency/consumers/update/status/${id}`, data: newStatusDTO, options: options });
    }

    /**
     * HTTP POST /api/pphc/dashboard/agency/consumers/update/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardConsumersController.postConsumerApplicationUpdate
     */
    postConsumerApplicationUpdate(id: string, formData: ConsumerApplication_DTO_Request_Update, options?: O): RestResponse<ConsumerApplication_DTO_Response_Listing> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/dashboard/agency/consumers/update/${id}`, data: formData, options: options });
    }
}

export class PPHCAgencyDashboardProvidersControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/pphc/dashboard/agency/providers/all
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardProvidersController.listAllAgencyProviders
     */
    listAllAgencyProviders(options?: O): RestResponse<AgencyProviderListing_DTO_Response_ListView[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/agency/providers/all`, options: options });
    }

    /**
     * HTTP POST /api/pphc/dashboard/agency/providers/createOrUpdate
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardProvidersController.postProviderListingCreateOrUpdate
     */
    postProviderListingCreateOrUpdate(reqBody: PPHCAgencyDashboardProvidersController_ListingFormData, options?: O): RestResponse<PPHCAgencyDashboardProvidersController_CreateOrUpdateResponseDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/dashboard/agency/providers/createOrUpdate`, data: reqBody, options: options });
    }

    /**
     * HTTP POST /api/pphc/dashboard/agency/providers/delete
     * Java method: org.carina.core.api.domain.offerings.pphc.agency.PPHCAgencyDashboardProvidersController.deleteProviderListing
     */
    deleteProviderListing(queryParams: { existingProviderListingId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/dashboard/agency/providers/delete`, queryParams: queryParams, options: options });
    }
}

export class PPHCAgencyRegistrationControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/agency_admins/register
     * Java method: org.carina.core.api.agency_admin.PPHCAgencyRegistrationController.register
     */
    register(formData: AgencyAdminRegistrationDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/agency_admins/register`, data: formData, options: options });
    }
}

export class PPHCCarinaAdminDashboardAgenciesControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/pphc/dashboard/carina_admin/agencies/all
     * Java method: org.carina.core.api.domain.offerings.pphc.carina_admin.PPHCCarinaAdminDashboardAgenciesController.listAllAgencies
     */
    listAllAgencies(options?: O): RestResponse<Agency_DTO_Response_Listing[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/carina_admin/agencies/all`, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/carina_admin/agencies/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.carina_admin.PPHCCarinaAdminDashboardAgenciesController.getAgencyAdmin
     */
    getAgencyAdmin(id: string, options?: O): RestResponse<PPHCCarinaAdminDashboardAgenciesController_AgencyAdminDetails> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/carina_admin/agencies/${id}`, options: options });
    }
}

export class PPHCCarinaAdminDashboardConsumersControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/pphc/dashboard/carina_admin/consumers/all
     * Java method: org.carina.core.api.domain.offerings.pphc.carina_admin.PPHCCarinaAdminDashboardConsumersController.listAllApplications
     */
    listAllApplications(options?: O): RestResponse<ConsumerApplication_DTO_Response_Listing[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/carina_admin/consumers/all`, options: options });
    }

    /**
     * HTTP POST /api/pphc/dashboard/carina_admin/consumers/archive/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.carina_admin.PPHCCarinaAdminDashboardConsumersController.postClientArchive
     */
    postClientArchive(id: string, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/dashboard/carina_admin/consumers/archive/${id}`, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/carina_admin/consumers/details/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.carina_admin.PPHCCarinaAdminDashboardConsumersController.getConsumerApplicationDetails
     */
    getConsumerApplicationDetails(id: string, options?: O): RestResponse<ConsumerApplication_DTO_Response_Details> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/carina_admin/consumers/details/${id}`, options: options });
    }
}

export class PPHCCarinaAdminDashboardFundersControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/pphc/dashboard/carina_admin/funders/admins
     * Java method: org.carina.core.api.domain.offerings.pphc.carina_admin.PPHCCarinaAdminDashboardFundersController.listFunderAdmins
     */
    listFunderAdmins(options?: O): RestResponse<PPHCCarinaAdminDashboardFundersController_FunderAdminListingDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/carina_admin/funders/admins`, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/carina_admin/funders/all
     * Java method: org.carina.core.api.domain.offerings.pphc.carina_admin.PPHCCarinaAdminDashboardFundersController.listAllFunders
     */
    listAllFunders(options?: O): RestResponse<Funder_DTO_Response_Listing[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/carina_admin/funders/all`, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/carina_admin/funders/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.carina_admin.PPHCCarinaAdminDashboardFundersController.getFunderAdmin
     */
    getFunderAdmin(id: string, options?: O): RestResponse<PPHCCarinaAdminDashboardFundersController_FunderAdminDetails> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/carina_admin/funders/${id}`, options: options });
    }
}

export class PPHCCarinaAdminDashboardProvidersControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/pphc/dashboard/carina_admin/providers/all
     * Java method: org.carina.core.api.domain.offerings.pphc.carina_admin.PPHCCarinaAdminDashboardProvidersController.listAllAgencyProviders
     */
    listAllAgencyProviders(options?: O): RestResponse<AgencyProviderListing_DTO_Response_ListView[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/carina_admin/providers/all`, options: options });
    }
}

export class PPHCConsumerXpControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/pphc/consumer_xp/filter
     * Java method: org.carina.core.api.domain.offerings.pphc.consumer_xp.PPHCConsumerXpController.filterListings
     */
    filterListings(queryParams: { address: string; }, options?: O): RestResponse<PPHCConsumerXpController_ProviderListing_WithDistanceDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/consumer_xp/filter`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/pphc/consumer_xp/selected_agency_submit
     * Java method: org.carina.core.api.domain.offerings.pphc.consumer_xp.PPHCConsumerXpController.registerOnSelectedAgency
     */
    registerOnSelectedAgency(formData: ConsumerApplicationRegistrationDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/consumer_xp/selected_agency_submit`, data: formData, options: options });
    }

    /**
     * HTTP POST /api/pphc/consumer_xp/track/agency_call
     * Java method: org.carina.core.api.domain.offerings.pphc.consumer_xp.PPHCConsumerXpController.trackAgencyCall
     */
    trackAgencyCall(reqBody: TrackAgencyCallDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/consumer_xp/track/agency_call`, data: reqBody, options: options });
    }

    /**
     * HTTP POST /api/pphc/consumer_xp/waitlist/submit
     * Java method: org.carina.core.api.domain.offerings.pphc.consumer_xp.PPHCConsumerXpController.waitlist
     */
    waitlist(formData: SubmitWaitlistEntryDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/consumer_xp/waitlist/submit`, data: formData, options: options });
    }
}

export class PPHCFunderDashboardAgencyAdminsControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/pphc/dashboard/funder/agency_admins/funder
     * Java method: org.carina.core.api.domain.offerings.pphc.funder.PPHCFunderDashboardAgencyAdminsController.getFunder
     */
    getFunder(options?: O): RestResponse<Funder_DTO_Response_Listing> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/funder/agency_admins/funder`, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/funder/agency_admins/invites/all
     * Java method: org.carina.core.api.domain.offerings.pphc.funder.PPHCFunderDashboardAgencyAdminsController.getAgencyAdminsByFunder
     */
    getAgencyAdminsByFunder(options?: O): RestResponse<AgencyAdminInviteDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/funder/agency_admins/invites/all`, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/funder/agency_admins/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.funder.PPHCFunderDashboardAgencyAdminsController.getAgencyAdmin
     */
    getAgencyAdmin(id: string, options?: O): RestResponse<PPHCFunderDashboardAgencyAdminsController_AgencyAdminDetails> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/funder/agency_admins/${id}`, options: options });
    }
}

export class PPHCFunderDashboardConsumersControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/pphc/dashboard/funder/consumers/all
     * Java method: org.carina.core.api.domain.offerings.pphc.funder.PPHCFunderDashboardConsumersController.listAllApplicationsByFunder
     */
    listAllApplicationsByFunder(queryParams: { funderId: string; }, options?: O): RestResponse<ConsumerApplication_DTO_Response_Listing[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/funder/consumers/all`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/pphc/dashboard/funder/consumers/archive/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.funder.PPHCFunderDashboardConsumersController.postClientArchive
     */
    postClientArchive(id: string, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/pphc/dashboard/funder/consumers/archive/${id}`, options: options });
    }

    /**
     * HTTP GET /api/pphc/dashboard/funder/consumers/details/{id}
     * Java method: org.carina.core.api.domain.offerings.pphc.funder.PPHCFunderDashboardConsumersController.getConsumerApplicationDetails
     */
    getConsumerApplicationDetails(id: string, options?: O): RestResponse<ConsumerApplication_DTO_Response_Details> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/funder/consumers/details/${id}`, options: options });
    }
}

export class PPHCFunderDashboardProvidersControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/pphc/dashboard/funder/providers/all
     * Java method: org.carina.core.api.domain.offerings.pphc.funder.PPHCFunderDashboardProvidersController.listAllAgencyProviders
     */
    listAllAgencyProviders(options?: O): RestResponse<AgencyProviderListing_DTO_Response_ListView[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/pphc/dashboard/funder/providers/all`, options: options });
    }
}

export class ProviderProfileSearchAndFilterControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/provider_profile/filter/clear
     * Java method: org.carina.core.api.domain.search.provider_profile.ProviderProfileSearchAndFilterController.clearFilter
     */
    clearFilter(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/provider_profile/filter/clear`, options: options });
    }

    /**
     * HTTP GET /api/provider_profile/filter/content
     * Java method: org.carina.core.api.domain.search.provider_profile.ProviderProfileSearchAndFilterController.getFilterContent
     */
    getFilterContent(options?: O): RestResponse<ProviderProfileSearchDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/provider_profile/filter/content`, options: options });
    }

    /**
     * HTTP GET /api/provider_profile/filter/notification/email
     * Java method: org.carina.core.api.domain.search.provider_profile.ProviderProfileSearchAndFilterController.getEmailFilterNotification
     */
    getEmailFilterNotification(options?: O): RestResponse<Nullable<EmailFilterNotificationDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/provider_profile/filter/notification/email`, options: options });
    }

    /**
     * HTTP POST /api/provider_profile/filter/notification/email/update
     * Java method: org.carina.core.api.domain.search.provider_profile.ProviderProfileSearchAndFilterController.updateEmailFilterNotification
     */
    updateEmailFilterNotification(dto: UpdateEmailFilterNotificationDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/provider_profile/filter/notification/email/update`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/provider_profile/filter/persist
     * Java method: org.carina.core.api.domain.search.provider_profile.ProviderProfileSearchAndFilterController.persistFilter
     */
    persistFilter(dto: ProviderProfileSearchDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/provider_profile/filter/persist`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/provider_profile/job_poster/preferences
     * Java method: org.carina.core.api.domain.search.provider_profile.ProviderProfileSearchAndFilterController.getJobPosterPreferences
     */
    getJobPosterPreferences(options?: O): RestResponse<JobPosterPreferences> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/provider_profile/job_poster/preferences`, options: options });
    }

    /**
     * HTTP POST /api/provider_profile/search
     * Java method: org.carina.core.api.domain.search.provider_profile.ProviderProfileSearchAndFilterController.search
     */
    search(dto: ProviderProfileSearchDTO, queryParams: { selectedFunderId: string; }, options?: O): RestResponse<Page<ProviderListDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/provider_profile/search`, queryParams: queryParams, data: dto, options: options });
    }
}

export class PublicMedicaidControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/public/medicaid/jobs/delete/fromNotif
     * Java method: org.carina.core.api.domain.offerings.medicaid.PublicMedicaidController.deleteJobPostFromNotification
     */
    deleteJobPostFromNotification(dto: DeleteJobUsingEmailDTO, options?: O): RestResponse<DeleteJobResponseMessage> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/public/medicaid/jobs/delete/fromNotif`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/public/medicaid/jobs/{id}
     * Java method: org.carina.core.api.domain.offerings.medicaid.PublicMedicaidController.getNewJobsNearProvider
     */
    getNewJobsNearProvider(id: string, options?: O): RestResponse<JobPostListDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/public/medicaid/jobs/${id}`, options: options });
    }

    /**
     * HTTP POST /api/public/medicaid/profile/availability/{id}/{availability}
     * Java method: org.carina.core.api.domain.offerings.medicaid.PublicMedicaidController.updateAvailabilityPost
     */
    updateAvailabilityPost(id: string, availability: ProviderProfileTagEnum, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/public/medicaid/profile/availability/${id}/${availability}`, options: options });
    }

    /**
     * HTTP GET /api/public/medicaid/providers/list/near
     * Java method: org.carina.core.api.domain.offerings.medicaid.PublicMedicaidController.getProvidersNearMePreview
     */
    getProvidersNearMePreview(queryParams: { id: string; }, options?: O): RestResponse<ProviderNotificationDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/public/medicaid/providers/list/near`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/public/medicaid/token/regPath
     * Java method: org.carina.core.api.domain.offerings.medicaid.PublicMedicaidController.getRegPathFromToken
     */
    getRegPathFromToken(queryParams: { token: string; }, options?: O): RestResponse<RegistrationController_RegPathDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/public/medicaid/token/regPath`, queryParams: queryParams, options: options });
    }
}

export class RegistrationControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/reg/passwordCreation
     * Java method: org.carina.core.api.domain.offerings.registration.RegistrationController.createPasswordAndCompleteRegistration
     */
    createPasswordAndCompleteRegistration(dto: RegistrationController_PasswordCreationDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/reg/passwordCreation`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/reg/verify
     * Java method: org.carina.core.api.domain.offerings.registration.RegistrationController.verifyCredentials
     */
    verifyCredentials(dto: RegistrationController_VerifyCredentialsDTO, options?: O): RestResponse<RegistrationController_VerificationResponseBodyDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/reg/verify`, data: dto, options: options });
    }
}

export class RegistrationTokenControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/registration/token/redeem
     * Java method: org.carina.core.api.domain.per_customer.funder.registration.registration_token.RegistrationTokenController.redeemRegistrationToken
     */
    redeemRegistrationToken(dto: TokenRedemptionDTO, options?: O): RestResponse<ServiceResponse<RedeemedTokenDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/registration/token/redeem`, data: dto, options: options });
    }
}

export class RepresentativeJobPosterControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/medicaid/rep_job_poster/verify
     * Java method: org.carina.core.api.domain.offerings.medicaid.shared.RepresentativeJobPosterController.preJobPostVerifyConsumerCreds
     */
    preJobPostVerifyConsumerCreds(dto: RegistrationController_VerifyCredentialsDTO, options?: O): RestResponse<RepresentativeJobPosterVerificationDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/medicaid/rep_job_poster/verify`, data: dto, options: options });
    }
}

export class RoleSelectionControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/reg/path/{regPathId}
     * Java method: org.carina.core.api.domain.per_customer.funder.registration.RoleSelectionController.getRegPath
     */
    getRegPath(regPathId: string, options?: O): RestResponse<RegistrationController_RegPathDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/reg/path/${regPathId}`, options: options });
    }

    /**
     * HTTP GET /api/reg/roleSelection/fundersWithRegPaths
     * Java method: org.carina.core.api.domain.per_customer.funder.registration.RoleSelectionController.getAllFundersWithRegPaths
     */
    getAllFundersWithRegPaths(queryParams: { offering: string; }, options?: O): RestResponse<RoleSelectionController_FunderWithRoleConfigDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/reg/roleSelection/fundersWithRegPaths`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/reg/roleSelection/select
     * Java method: org.carina.core.api.domain.per_customer.funder.registration.RoleSelectionController.selectRole
     */
    selectRole(formValues: RoleSelectionController_RoleSelectionDTO, options?: O): RestResponse<Nullable<RegistrationController_RegistrationStepDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/reg/roleSelection/select`, data: formValues, options: options });
    }
}

export class SMSCallbackControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/sms/reply
     * Java method: org.carina.core.api.external_services.sms.SMSCallbackController.smsReplyGet
     */
    smsReplyGet(options?: O): RestResponse<Nullable<string>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/sms/reply`, options: options });
    }

    /**
     * HTTP POST /api/sms/status
     * Java method: org.carina.core.api.external_services.sms.SMSCallbackController.smsStatusGet
     */
    smsStatusGet(queryParams?: { To?: string; From?: string; ErrorCode?: string; MessageStatus?: string; MessageSid?: string; commStatusId?: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/sms/status`, queryParams: queryParams, options: options });
    }
}

export class ShortLinkControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/sl/expand/{base62id}
     * Java method: org.carina.core.api.domain.utilities.ShortLinkController.expandShortlink
     */
    expandShortlink(base62id: string, options?: O): RestResponse<Nullable<string>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/sl/expand/${base62id}`, options: options });
    }
}

export class SingleInvitesControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/invites/single/redeem
     * Java method: org.carina.core.api.domain.management.invite.SingleInvitesController.redeemSingleInvite
     */
    redeemSingleInvite(dto: InviteRedemptionDTO, options?: O): RestResponse<ServiceResponse<RedeemedInviteDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/invites/single/redeem`, data: dto, options: options });
    }
}

export class SupportControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/support/message
     * Java method: org.carina.core.api.domain.management.support.SupportController.messageSupport
     */
    messageSupport(dto: SupportMessageDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/support/message`, data: dto, options: options });
    }
}

export class UserControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/users/addressChange/request
     * Java method: org.carina.core.api.domain.users.UserController.requestAddressChange
     */
    requestAddressChange(payload: AddressChangeRequestPayload, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users/addressChange/request`, data: payload, options: options });
    }

    /**
     * HTTP POST /api/users/changeEmail
     * Java method: org.carina.core.api.domain.users.UserController.changeEmail
     */
    changeEmail(payload: EmailChangePayload, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users/changeEmail`, data: payload, options: options });
    }

    /**
     * HTTP POST /api/users/deactivate
     * Java method: org.carina.core.api.domain.users.UserController.requestDeactivation
     */
    requestDeactivation(dto: UserController_RequestDeactivationDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users/deactivate`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/users/licenseChange/request
     * Java method: org.carina.core.api.domain.users.UserController.requestLicenseNumberChange
     */
    requestLicenseNumberChange(payload: LicenseChangeRequestPayload, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users/licenseChange/request`, data: payload, options: options });
    }

    /**
     * HTTP GET /api/users/me
     * Java method: org.carina.core.api.domain.users.UserController.whoami
     */
    whoami(options?: O): RestResponse<Nullable<UserController_SessionDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/me`, options: options });
    }

    /**
     * HTTP POST /api/users/passwordReset
     * Java method: org.carina.core.api.domain.users.UserController.passwordReset
     */
    passwordReset(dto: UserController_PasswordResetDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users/passwordReset`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/users/passwordReset/request
     * Java method: org.carina.core.api.domain.users.UserController.requestPasswordReset
     */
    requestPasswordReset(dto: UserController_PasswordResetRequestDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users/passwordReset/request`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/users/passwordReset/tokenData
     * Java method: org.carina.core.api.domain.users.UserController.getPasswordResetTokenData
     */
    getPasswordResetTokenData(queryParams: { token: string; }, options?: O): RestResponse<UserController_PasswordResetTokenDataDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/passwordReset/tokenData`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/users/passwordResetOnBehalf/request
     * Java method: org.carina.core.api.domain.users.UserController.requestPasswordResetOnBehalf
     */
    requestPasswordResetOnBehalf(dto: UserController_PasswordResetRequestDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users/passwordResetOnBehalf/request`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/users/update_user_alert
     * Java method: org.carina.core.api.domain.users.UserController.updateUserAlert
     */
    updateUserAlert(userAlertDTO: UserAlertDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users/update_user_alert`, data: userAlertDTO, options: options });
    }

    /**
     * HTTP GET /api/users/user_alert
     * Java method: org.carina.core.api.domain.users.UserController.getUserAlert
     */
    getUserAlert(queryParams: { alertType: UserAlertType; }, options?: O): RestResponse<Nullable<UserAlertDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/user_alert`, queryParams: queryParams, options: options });
    }
}

export class UsersAdministrationControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/admin/users/active/{userId}/details
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.getActiveUserDetails
     */
    getActiveUserDetails(userId: string, options?: O): RestResponse<ActiveUserDetailsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/users/active/${userId}/details`, options: options });
    }

    /**
     * HTTP POST /api/admin/users/emailChange/request
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.requestEmailChange
     */
    requestEmailChange(payload: EmailChangeRequestPayload, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/users/emailChange/request`, data: payload, options: options });
    }

    /**
     * HTTP GET /api/admin/users/report/details/{reportId}
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.getUserReportDetails
     */
    getUserReportDetails(reportId: string, options?: O): RestResponse<UserReportDetailsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/users/report/details/${reportId}`, options: options });
    }

    /**
     * HTTP POST /api/admin/users/report/update
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.updateUserReport
     */
    updateUserReport(queryParams: { reportId: string; updatedStatus: ReportStatusEnum; updatedCategory: BehaviorCategory; updatedBehavior: Behavior; action: Action; actionNotes: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/users/report/update`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/admin/users/roleChange/request
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.requestRoleChange
     */
    requestRoleChange(payload: RoleChangeRequestPayload, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/users/roleChange/request`, data: payload, options: options });
    }

    /**
     * HTTP POST /api/admin/users/search/invites/childcare/{funderId}
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.searchChildcareUserInvites
     */
    searchChildcareUserInvites(funderId: string, pageInfo: PageInfo, options?: O): RestResponse<Page<ChildcareInviteListDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/users/search/invites/childcare/${funderId}`, data: pageInfo, options: options });
    }

    /**
     * HTTP POST /api/admin/users/search/invites/medicaid/{funderId}
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.searchMedicaidUserInvites
     */
    searchMedicaidUserInvites(funderId: string, pageInfo: PageInfo, options?: O): RestResponse<Page<MedicaidInviteListDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/users/search/invites/medicaid/${funderId}`, data: pageInfo, options: options });
    }

    /**
     * HTTP POST /api/admin/users/search/{funderId}
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.search
     */
    search(funderId: string, pageInfo: PageInfo, options?: O): RestResponse<Page<ActiveUserListDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/users/search/${funderId}`, data: pageInfo, options: options });
    }

    /**
     * HTTP POST /api/admin/users/user_reports/search
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.searchUserReports
     */
    searchUserReports(pageInfo: PageInfo, queryParams: { funderId: string; }, options?: O): RestResponse<Page<UserReportListDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/users/user_reports/search`, queryParams: queryParams, data: pageInfo, options: options });
    }

    /**
     * HTTP GET /api/admin/users/{inviteToken}/childcare/details
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.getChildcareInviteDetails
     */
    getChildcareInviteDetails(inviteToken: string, options?: O): RestResponse<InviteDetailsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/users/${inviteToken}/childcare/details`, options: options });
    }

    /**
     * HTTP GET /api/admin/users/{inviteToken}/details
     * Java method: org.carina.core.api.domain.management.UsersAdministrationController.getMedicaidInviteDetails
     */
    getMedicaidInviteDetails(inviteToken: string, options?: O): RestResponse<InviteDetailsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/users/${inviteToken}/details`, options: options });
    }
}

export class UsersImpersonationControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/admin/users/impersonate/exit
     * Java method: org.carina.core.api.domain.management.UsersImpersonationController.getImpersonateExit
     */
    getImpersonateExit(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/admin/users/impersonate/exit`, options: options });
    }

    /**
     * HTTP POST /api/admin/users/impersonate/{userId}
     * Java method: org.carina.core.api.domain.management.UsersImpersonationController.postImpersonate
     */
    postImpersonate(userId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/admin/users/impersonate/${userId}`, options: options });
    }
}

export interface ActiveCaseManagersResponseDTO {
    firstName: string;
    hasValidInvite: boolean;
    id: string;
    lastName: string;
}

export interface ActiveUserDetailsDTO {
    address: string;
    availability?: Nullable<ProviderProfileTagEnum>;
    createdAt: number;
    email: string;
    firstName: string;
    funderName: string;
    id: string;
    issuerFirstName: string;
    issuerLastName: string;
    joinDate: number;
    lastLoginDate: number;
    lastName: string;
    license?: Nullable<string>;
    reason: string;
    role: string;
    smsPhone?: Nullable<string>;
    totalUnreadMessages: number;
    verifiersInfo: string;
}

export interface ActiveUserListDTO {
    city: string;
    contacts: number;
    email: string;
    firstName: string;
    id: string;
    lastLoginDate: number;
    lastName: string;
    license: string;
    openSlots: boolean;
    role: string;
    smsPhone?: Nullable<string>;
    state: string;
    status: string;
    totalUnreadMessages: number;
    zipCode: string;
}

export interface AddressChangeRequestPayload {
    currentAddress: string;
    newAddress: string;
    targetUserId: string;
}

export interface AgencyAdminInviteDTO {
    agencyAdminName: string;
    agencyInviteCreatedAt: number;
    agencyInviteStatus: AgencyInviteStatus;
    agencyName: string;
    funderName: string;
    id: string;
    registeredUserId?: Nullable<string>;
}

export interface AgencyAdminRegistrationDTO {
    password: string;
    token: string;
}

export interface AgencyAdmin_DTO {
}

export interface AgencyAdmin_DTO_Nested_Agency_IdAndName extends Agency_DTO_Id, Agency_DTO_Name {
}

export interface AgencyAdmin_DTO_Response_Session extends User_DTO_Response_Session {
    agency: AgencyAdmin_DTO_Nested_Agency_IdAndName;
}

export interface AgencyProviderListing_DTO {
}

export interface AgencyProviderListing_DTO_HasMaleProviders {
    hasMaleProviders: boolean;
}

export interface AgencyProviderListing_DTO_Id {
    id: string;
}

export interface AgencyProviderListing_DTO_Languages {
    languages: ConsumerApplicationLanguage[];
}

export interface AgencyProviderListing_DTO_Notes {
    notes: string;
}

export interface AgencyProviderListing_DTO_NumProviders {
    numProviders: number;
}

export interface AgencyProviderListing_DTO_Request_Create extends AgencyProviderListing_DTO_NumProviders, AgencyProviderListing_DTO_HasMaleProviders, AgencyProviderListing_DTO_Languages, AgencyProviderListing_DTO_Notes {
    zipCode: string;
}

export interface AgencyProviderListing_DTO_Response_ListView extends ProviderListing_DTO_Response_ListView, AgencyProviderListing_DTO_NumProviders, AgencyProviderListing_DTO_HasMaleProviders, AgencyProviderListing_DTO_Languages, AgencyProviderListing_DTO_Notes, AgencyProviderListing_DTO_UpdatedAt {
    agency: Agency_DTO_Response_Listing;
}

export interface AgencyProviderListing_DTO_UpdatedAt {
    updatedAt: number;
}

export interface Agency_DTO {
}

export interface Agency_DTO_AgencyRef {
    agencyRef: string;
}

export interface Agency_DTO_Description {
    description?: Nullable<string>;
}

export interface Agency_DTO_DiscountDescription {
    discountDescription?: Nullable<string>;
}

export interface Agency_DTO_DiscountedCost {
    discountedCost?: Nullable<number>;
}

export interface Agency_DTO_Expertises {
    expertises: AgencyExpertises[];
}

export interface Agency_DTO_ExtendedDescription {
    extendedDescription?: Nullable<string>;
}

export interface Agency_DTO_Funder {
    funder: Funder_DTO_Response_Listing;
}

export interface Agency_DTO_HourlyCost {
    hourlyCost?: Nullable<number>;
}

export interface Agency_DTO_Id {
    id: string;
}

export interface Agency_DTO_Name {
    name: string;
}

export interface Agency_DTO_PhoneNumber {
    phoneNumber?: Nullable<string>;
}

export interface Agency_DTO_Response_Details extends Agency_DTO_Id, Agency_DTO_Name, Agency_DTO_Funder {
}

export interface Agency_DTO_Response_Listing extends Agency_DTO_Id, Agency_DTO_Name, Agency_DTO_Funder {
}

export interface Agency_DTO_Url {
    websiteUrl?: Nullable<string>;
}

export interface AnnotatedElement {
    annotations: Annotation[];
    declaredAnnotations: Annotation[];
}

export interface AnnotatedType extends AnnotatedElement {
    annotatedOwnerType: AnnotatedType;
    type: Type;
}

export interface Annotation {
}

export interface AutoCloseable {
}

export interface BGVerificationDTO {
    ahcas?: Nullable<boolean>;
    assignedtrainingcategory?: Nullable<string>;
    bccu_expiry?: Nullable<number>;
    cdwaid?: Nullable<string>;
    city?: Nullable<string>;
    compliance_status?: Nullable<string>;
    contract_expiry?: Nullable<number>;
    county?: Nullable<string>;
    credential_expiry?: Nullable<number>;
    credentialnumber?: Nullable<string>;
    credentialstatus?: Nullable<string>;
    credentialtype?: Nullable<string>;
    dob?: Nullable<number>;
    eligibility?: Nullable<boolean>;
    exempt?: Nullable<string>;
    first_name?: Nullable<string>;
    home_phone?: Nullable<string>;
    interested_and_eligible_date?: Nullable<number>;
    ipone?: Nullable<string>;
    last_name?: Nullable<string>;
    middle_initial?: Nullable<string>;
    mobile_phone?: Nullable<string>;
    persona_classification?: Nullable<string>;
    persona_type?: Nullable<string>;
    postal_code?: Nullable<string>;
    primary_email?: Nullable<string>;
    sf_contact_id?: Nullable<string>;
    ssn_last_4?: Nullable<string>;
    state?: Nullable<string>;
    street?: Nullable<string>;
    student_id?: Nullable<string>;
    training_category?: Nullable<string>;
}

export interface BaseEntity_DTO {
}

export interface BaseEntity_DTO_CreatedAt {
    createdAt: number;
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel: boolean;
}

export interface BasicMessagingUserDTO {
    dtype: string;
    firstName: string;
    funderId: string;
    hasImg?: Nullable<boolean>;
    id: string;
    lastName: string;
    orgName?: Nullable<string>;
}

export interface BasicThreadListDTO {
    archived: boolean;
    jobPost?: Nullable<BasicThreadListJobPostDTO>;
    lastMessageDate: number;
    lastMessageText: string;
    otherUser: BasicMessagingUserDTO;
    otherUserPhone?: Nullable<string>;
    starred: boolean;
    threadId: string;
    unreadMessageCount: number;
    updatedAt: number;
}

export interface BasicThreadListJobPostDTO {
    consumerFirstName?: Nullable<string>;
    jobPostId?: Nullable<string>;
    jobPostNumber?: Nullable<number>;
    jobPosterFirstName?: Nullable<string>;
    jobPosterId?: Nullable<string>;
    resolvedMethod?: Nullable<ResolvedMethodEnum>;
}

export interface BlockPersonDTO {
    blockedSource?: Nullable<BlockedPerson_BlockedSourceEnum>;
    dob?: Nullable<number>;
    email: string;
    firstName: string;
    last4?: Nullable<string>;
    lastName: string;
    notes?: Nullable<string>;
    reason?: Nullable<string>;
    uniqueId?: Nullable<string>;
}

export interface BuildFooterDTO {
    contactUsParams?: Nullable<string>;
    footerType: FooterType;
    language: EmailLanguage;
    settingsParams?: Nullable<string>;
}

export interface CarinaAdminFunderManagementController_ApiCredsDTO {
    password: string;
    username: string;
}

export interface CarinaAdminFunderManagementController_ChangeStatusDTO {
    newStatus: GeneralAPIConfig_Status;
}

export interface CarinaAdminFunderManagementController_FunderDetailDTO extends Funder_DTO_Id, Funder_DTO_Name, Funder_DTO_Offering, Funder_DTO_Segment {
    roleConfigs: CarinaAdminFunderManagementController_RoleConfigDTO[];
}

export interface CarinaAdminFunderManagementController_FunderListingDTO extends Funder_DTO_Id, Funder_DTO_Name, Funder_DTO_Offering, Funder_DTO_Segment {
}

export interface CarinaAdminFunderManagementController_GeneralApiConfigDTO extends GeneralAPIConfig_DTO_Id, GeneralAPIConfig_DTO_Url, GeneralAPIConfig_DTO_Username, GeneralAPIConfig_DTO_Password, GeneralAPIConfig_DTO_Path, GeneralAPIConfig_DTO_Method {
}

export interface CarinaAdminFunderManagementController_RegPathDetailDTO {
    dtype: string;
    id: string;
    registrationSteps: CarinaAdminFunderManagementController_RegistrationStepDTO[];
}

export interface CarinaAdminFunderManagementController_RegStepUpdateDTO {
    name: string;
}

export interface CarinaAdminFunderManagementController_RegistrationStepDTO extends RegistrationStep_DTO_Id, RegistrationStep_DTO_Step, RegistrationStep_DTO_DisplayName {
}

export interface CarinaAdminFunderManagementController_RoleConfigDTO extends RoleConfig_DTO_Id, RoleConfig_DTO_RoleDisplayName, RoleConfig_DTO_RoleTypeInfo {
}

export interface CarinaAdminFunderManagementController_RoleDetailDTO extends RoleConfig_DTO_Id, RoleConfig_DTO_RoleDisplayName {
    registrationPaths: CarinaAdminFunderManagementController_RegPathDetailDTO[];
}

export interface CarinaAdminFunderManagementController_VerificationConfigDTO extends VerificationConfig_DTO_VerificationStrategy, IAPIEndpoint_DTO_Path, IAPIEndpoint_DTO_Method {
    apiConfig: CarinaAdminFunderManagementController_GeneralApiConfigDTO;
    classRef: string;
}

export interface CarinaAdminFunderManagementController_VerificationEndpointUpdateDTO {
    classRef: string;
    method: HttpMethod;
    path: string;
}

export interface CarinaAdminFunderManagementController_VerificationRegistrationStepDTO extends CarinaAdminFunderManagementController_RegistrationStepDTO {
    verificationConfig: CarinaAdminFunderManagementController_VerificationConfigDTO;
}

export interface CarinaAdmin_DTO {
}

export interface CarinaAdmin_DTO_Response_Session extends User_DTO_Response_Session {
}

export interface ChildCareProfileRequestDTO {
    distanceReferenceAddress?: Nullable<string>;
}

export interface ChildcareAddressDTO {
    city: string;
    generalizedLocation: string;
    state: string;
}

export interface ChildcareInviteListDTO {
    email: string;
    expiresAt: number;
    firstName: string;
    lastName: string;
    license: string;
    status: SingleInvite_Status;
    token: string;
}

export interface ChildcareInvitesController_ChildcareInviteDTO {
    email: string;
    firstName: string;
    funderId: string;
    lastName: string;
    notes: string;
    providerLicenseNumber: string;
    reason?: Nullable<string>;
    verificationInputsJson: string;
    verifierEmail: string;
    verifierFirstName: string;
    verifierLastName: string;
}

export interface ChildcareInvitesController_ChildcareInviteRedemptionDTO {
    token: string;
}

export interface ChildcareInvitesController_ChildcareInviteUserInfoDTO {
    email: string;
    firstName: string;
    lastName: string;
    registrationPath: RegistrationController_RegPathDTO;
}

export interface ChildcareInvitesController_ChildcareRedeemedInviteDTO {
    childcareInvite: ChildcareInvitesController_ChildcareInviteUserInfoDTO;
    regStep: RegistrationController_RegistrationStepDTO;
}

export interface ChildcareProviderPortalDTO {
    firstName: string;
}

export interface ChildcareProviderProfileRequestDetailDTO {
    about: string;
    businessAddress: string;
    businessName: string;
    businessPhone?: Nullable<string>;
    firstName: string;
    lastName: string;
    licenseNumber: string;
    personalPhone?: Nullable<string>;
    searchStatus: ProviderProfileTagEnum;
    smsConsent: boolean;
    tags: ProviderProfileTagEnum[];
}

export interface ChildcareProviderProfileResponseDetailDTO {
    about: string;
    address: string;
    businessName: string;
    businessPhone?: Nullable<string>;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    licenseNumber: string;
    personalPhone?: Nullable<string>;
    searchStatus: ProviderProfileTagEnum;
    smsConsent?: Nullable<boolean>;
    tagGroups: { [P in ProviderProfileTagEnum_Category]?: ProviderProfileTagEnum[] };
}

export interface ChildcareProviderProfileResponseDetailWithDistanceDTO {
    about: string;
    address: ChildcareAddressDTO;
    businessName: string;
    distance: number;
    firstName: string;
    funderKey: string;
    lastInitial: string;
    latitude: number;
    longitude: number;
    profileId: string;
    providerId: string;
    searchStatus: ProviderProfileTagEnum;
    tagGroups: { [P in ProviderProfileTagEnum_Category]?: ProviderProfileTagEnum[] };
}

export interface ChildcareProviderProfileSummaryDTO {
    address: ChildcareAddressDTO;
    businessName: string;
    distance: number;
    firstName: string;
    lastInitial: string;
    profileId: string;
    providerId: string;
    searchStatus: ProviderProfileTagEnum;
    tagGroups: { [P in ProviderProfileTagEnum_Category]?: ProviderProfileTagEnum[] };
}

export interface ChildcareProvidersWithinDistanceInputDTO {
    address: string;
    distanceMinutes: number;
    pageNumber: number;
    pageSize: number;
}

export interface ChildcareProvidersWithinDistanceResultDTO {
    results: Page<ChildcareProviderProfileSummaryDTO>;
    searchedGeoInfo: SearchedGeoInfo;
}

export interface Class<T> extends Serializable, GenericDeclaration, Type, AnnotatedElement, OfField<Class<any>>, Constable {
}

export interface Comparable<T> {
}

export interface Constable {
}

export interface ConstructorExpression<T> extends FactoryExpressionBase<T> {
}

export interface ConsumerApplicationCareRecipient_DTO extends ConsumerApplicationCareRecipient_DTOParts_FirstName, ConsumerApplicationCareRecipient_DTOParts_LastName, ConsumerApplicationCareRecipient_DTOParts_DateOfBirth, ConsumerApplicationCareRecipient_DTOParts_Gender, ConsumerApplicationCareRecipient_DTOParts_Address {
}

export interface ConsumerApplicationCareRecipient_DTOParts_Address {
    address: string;
}

export interface ConsumerApplicationCareRecipient_DTOParts_DateOfBirth {
    dateOfBirth?: Nullable<number>;
}

export interface ConsumerApplicationCareRecipient_DTOParts_FirstName {
    firstName: string;
}

export interface ConsumerApplicationCareRecipient_DTOParts_Gender {
    gender?: Nullable<ConsumerApplicationGender>;
}

export interface ConsumerApplicationCareRecipient_DTOParts_GeoDataDetail {
    geoData?: Nullable<GeoData_DTO_Response_Listing>;
}

export interface ConsumerApplicationCareRecipient_DTOParts_LastName {
    lastName: string;
}

export interface ConsumerApplicationCareRecipient_DTOWithGeo extends ConsumerApplicationCareRecipient_DTOParts_FirstName, ConsumerApplicationCareRecipient_DTOParts_LastName, ConsumerApplicationCareRecipient_DTOParts_DateOfBirth, ConsumerApplicationCareRecipient_DTOParts_Gender, ConsumerApplicationCareRecipient_DTOParts_Address, ConsumerApplicationCareRecipient_DTOParts_GeoDataDetail {
}

export interface ConsumerApplicationContact_DTO extends ConsumerApplicationContact_DTOParts_FirstName, ConsumerApplicationContact_DTOParts_LastName, ConsumerApplicationContact_DTOParts_Relationship, ConsumerApplicationContact_DTOParts_Email, ConsumerApplicationContact_DTOParts_Phone, ConsumerApplicationContact_DTOParts_PreferredMethod, ConsumerApplicationContact_DTOParts_PreferredTime {
}

export interface ConsumerApplicationContact_DTOParts_Email {
    email: string;
}

export interface ConsumerApplicationContact_DTOParts_FirstName {
    firstName: string;
}

export interface ConsumerApplicationContact_DTOParts_LastName {
    lastName: string;
}

export interface ConsumerApplicationContact_DTOParts_Phone {
    phone: string;
}

export interface ConsumerApplicationContact_DTOParts_PreferredMethod {
    preferredMethod?: Nullable<ContactMethod>;
}

export interface ConsumerApplicationContact_DTOParts_PreferredTime {
    preferredTime: string;
}

export interface ConsumerApplicationContact_DTOParts_Relationship {
    relationship?: Nullable<ConsumerApplicationRelationship>;
}

export interface ConsumerApplicationNewAndStalledEmail_ConsumerApplicationNotifDTO {
    highlightReasons: HighlightReason[];
    status: ConsumerApplicationStatus;
}

export interface ConsumerApplicationRegistrationDTO {
    agencyId?: Nullable<string>;
    consumerNotes: string;
    dateOfBirth?: Nullable<number>;
    email: string;
    firstName: string;
    gaclientId?: Nullable<string>;
    lastName?: Nullable<string>;
    phone?: Nullable<string>;
    preferredTime?: Nullable<string>;
    relationship: ConsumerApplicationRelationship;
    zip: string;
}

export interface ConsumerApplication_DTO {
}

export interface ConsumerApplication_DTO_AgencyListingInfo {
    agency: Agency_DTO_Response_Listing;
}

export interface ConsumerApplication_DTO_AgencyNotes {
    agencyNotes: string;
}

export interface ConsumerApplication_DTO_AssignedAgencyAdmin {
    assignedAgencyAdmin?: Nullable<User_DTO_Response_NameAndIdOnly>;
}

export interface ConsumerApplication_DTO_AssignedAgencyAdminId {
    assignedAgencyAdmin?: Nullable<string>;
}

export interface ConsumerApplication_DTO_CareNeeds {
    careNeeds: ConsumerApplicationCareNeeds[];
}

export interface ConsumerApplication_DTO_CareRecipient {
    careRecipient: ConsumerApplicationCareRecipient_DTO;
}

export interface ConsumerApplication_DTO_CareRecipientWithGeo {
    careRecipient: ConsumerApplicationCareRecipient_DTOWithGeo;
}

export interface ConsumerApplication_DTO_ConsumerNotes {
    consumerNotes: string;
}

export interface ConsumerApplication_DTO_Contact {
    contact: ConsumerApplicationContact_DTO;
}

export interface ConsumerApplication_DTO_CreatedAt {
    createdAt: number;
}

export interface ConsumerApplication_DTO_DeclinedNotes {
    declinedNotes?: Nullable<string>;
}

export interface ConsumerApplication_DTO_DeclinedReason {
    declinedReason?: Nullable<ConsumerApplicationDeclinedReason>;
}

export interface ConsumerApplication_DTO_FundingSources {
    fundingSources: ConsumerApplicationFundingSource[];
}

export interface ConsumerApplication_DTO_HighlightReasons {
    highlightReasons?: Nullable<HighlightReason[]>;
}

export interface ConsumerApplication_DTO_HomeEnvironment {
    homeEnvironment: { [P in ConsumerApplicationHomeEnvironmentKey]?: boolean };
}

export interface ConsumerApplication_DTO_Hours {
    hours: number;
}

export interface ConsumerApplication_DTO_Id {
    id: string;
}

export interface ConsumerApplication_DTO_IsArchived {
    archived: boolean;
}

export interface ConsumerApplication_DTO_Languages {
    languages: ConsumerApplicationLanguage[];
}

export interface ConsumerApplication_DTO_LatestEditor {
    latestEditor?: Nullable<User_DTO_Response_NameOnly>;
}

export interface ConsumerApplication_DTO_ReadableId {
    readableId?: Nullable<string>;
}

export interface ConsumerApplication_DTO_Request_Update extends ConsumerApplication_DTO_Status, ConsumerApplication_DTO_Hours, ConsumerApplication_DTO_CareRecipient, ConsumerApplication_DTO_Contact, ConsumerApplication_DTO_AgencyNotes, ConsumerApplication_DTO_Schedule, ConsumerApplication_DTO_Languages, ConsumerApplication_DTO_CareNeeds, ConsumerApplication_DTO_HomeEnvironment, ConsumerApplication_DTO_FundingSources, ConsumerApplication_DTO_AssignedAgencyAdminId {
    declinedNotes?: Nullable<string>;
    declinedReason?: Nullable<ConsumerApplicationDeclinedReason>;
}

export interface ConsumerApplication_DTO_Response_Details extends ConsumerApplication_DTO_Id, ConsumerApplication_DTO_CareRecipient, ConsumerApplication_DTO_Contact, ConsumerApplication_DTO_Status, ConsumerApplication_DTO_Hours, ConsumerApplication_DTO_Schedule, ConsumerApplication_DTO_ConsumerNotes, ConsumerApplication_DTO_AgencyNotes, ConsumerApplication_DTO_Languages, ConsumerApplication_DTO_CareNeeds, ConsumerApplication_DTO_HomeEnvironment, ConsumerApplication_DTO_FundingSources, ConsumerApplication_DTO_CreatedAt, ConsumerApplication_DTO_UpdatedAt, ConsumerApplication_DTO_AssignedAgencyAdmin {
    declinedNotes?: Nullable<string>;
    declinedReason?: Nullable<ConsumerApplicationDeclinedReason>;
}

export interface ConsumerApplication_DTO_Response_Listing extends ConsumerApplication_DTO_Id, ConsumerApplication_DTO_ReadableId, ConsumerApplication_DTO_AgencyListingInfo, ConsumerApplication_DTO_CareRecipientWithGeo, ConsumerApplication_DTO_Contact, ConsumerApplication_DTO_Languages, ConsumerApplication_DTO_Status, ConsumerApplication_DTO_Hours, ConsumerApplication_DTO_ConsumerNotes, ConsumerApplication_DTO_AgencyNotes, ConsumerApplication_DTO_IsArchived, ConsumerApplication_DTO_UpdatedAt, ConsumerApplication_DTO_CreatedAt, ConsumerApplication_DTO_HomeEnvironment, ConsumerApplication_DTO_LatestEditor, ConsumerApplication_DTO_AssignedAgencyAdmin, ConsumerApplication_DTO_HighlightReasons, ConsumerApplication_DTO_DeclinedReason, ConsumerApplication_DTO_DeclinedNotes {
}

export interface ConsumerApplication_DTO_Schedule {
    schedule: string;
}

export interface ConsumerApplication_DTO_Status {
    status: ConsumerApplicationStatus;
}

export interface ConsumerApplication_DTO_UpdatedAt {
    updatedAt: number;
}

export interface ConsumerReportPayload {
    behavior: Behavior;
    jobId: string;
    notes: string;
    reportSource: ReportSourceEnum;
}

export interface ConsumerWaitlistEntry_DTOParts_Email {
    email: string;
}

export interface ConsumerWaitlistEntry_DTOParts_ZipCode {
    zipCode: string;
}

export interface ContactInfo {
    address: string;
    email: string;
    phone?: Nullable<string>;
    smsConsent?: Nullable<boolean>;
}

export interface CoordinatorsListDTO {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    threadIds: string[];
}

export interface CurrentSettingsDTO {
    contactInfo: ContactInfo;
    emailSettings: SettingsDTO;
    languagePreference: LocizeLanguage;
    smsSettings: SettingsDTO;
}

export interface DSHSVerificationResponseV2DTO {
    eligibility?: Nullable<string>;
    providerOneId?: Nullable<string>;
}

export interface DeleteJobPostDTO {
    resolvedMethod: ResolvedMethodEnum;
    resolvedNotes: string;
    resolvedProviders: string[];
    testimonialConsent?: Nullable<boolean>;
    testimonialName?: Nullable<string>;
    testimonialSource?: Nullable<TestimonialSourceEnum>;
}

export interface DeleteJobUsingEmailDTO {
    jobid: string;
    userid: string;
}

export interface EmailChangePayload {
    token: string;
}

export interface EmailChangeRequestPayload {
    changeNotes: string;
    currentEmail: string;
    newEmail: string;
}

export interface EmailFilterNotificationDTO {
    enabled: boolean;
    frequency: NotificationFrequency;
    method: CommunicationMethod;
    type: NotificationType;
}

export interface EnvironmentConfig {
    environmentName: string;
}

export interface Error {
    message: string;
}

export interface Expression<T> extends Serializable {
    type: Class<T>;
}

export interface ExpressionBase<T> extends Expression<T> {
}

export interface FactoryExpression<T> extends Expression<T> {
    args: Expression<any>[];
}

export interface FactoryExpressionBase<T> extends ExpressionBase<T>, FactoryExpression<T> {
}

export interface FunderSelectionController_FilterOptions {
    offering?: Nullable<string>;
}

export interface Funder_DTO {
}

export interface Funder_DTO_Id {
    id: string;
}

export interface Funder_DTO_Name {
    name: string;
}

export interface Funder_DTO_Offering {
    offering: string;
}

export interface Funder_DTO_Response_IdOnly extends Funder_DTO_Id {
}

export interface Funder_DTO_Response_Listing extends Funder_DTO_Id, Funder_DTO_Segment, Funder_DTO_Name, Funder_DTO_State {
}

export interface Funder_DTO_Response_SegmentOnly extends Funder_DTO_Segment {
}

export interface Funder_DTO_Segment {
    segment: string;
}

export interface Funder_DTO_State {
    state: string;
}

export interface GeneralAPIConfig_DTO {
}

export interface GeneralAPIConfig_DTO_Id {
    id: string;
}

export interface GeneralAPIConfig_DTO_Method {
    method: HttpMethod;
}

export interface GeneralAPIConfig_DTO_Password {
    password?: Nullable<string>;
}

export interface GeneralAPIConfig_DTO_Path {
    path: string;
}

export interface GeneralAPIConfig_DTO_Url {
    url: string;
}

export interface GeneralAPIConfig_DTO_Username {
    username?: Nullable<string>;
}

export interface GenerateInviteRequestDTO {
    regPathId: string;
    sendingUserId: string;
}

export interface GenericDeclaration extends AnnotatedElement {
    typeParameters: TypeVariable<any>[];
}

export interface GenericInviteControllerResponse {
    invite?: Nullable<PrivatePayInvite_DTO_Response_Details>;
    message: string;
    success: boolean;
}

export interface GeoData_DTO {
    geoData?: Nullable<GeoData_DTO_Response_Listing>;
}

export interface GeoData_DTO_City {
    city: string;
}

export interface GeoData_DTO_County {
    county: string;
}

export interface GeoData_DTO_Id {
    id: string;
}

export interface GeoData_DTO_Response_CityAndState extends GeoData_DTO_Id, GeoData_DTO_City, GeoData_DTO_State {
}

export interface GeoData_DTO_Response_Listing extends GeoData_DTO_Id, GeoData_DTO_City, GeoData_DTO_ZipCode, GeoData_DTO_County {
}

export interface GeoData_DTO_State {
    state: string;
}

export interface GeoData_DTO_ZipCode {
    zipCode: string;
}

export interface GetThreadsRequestDTO {
    pageInfo: PageInfo;
    selectedFunderId?: Nullable<string>;
    threadFilter?: Nullable<ThreadFilterEnum>;
    withUserId?: Nullable<string>;
}

export interface GroupInviteResponseDTO {
    expiresAt: number;
    link: string;
    senderFirstName: string;
}

export interface IAPIEndpoint_DTO {
}

export interface IAPIEndpoint_DTO_Method {
    method: HttpMethod;
}

export interface IAPIEndpoint_DTO_Path {
    path: string;
}

export interface IRegistrationController {
}

export interface ImpersonationSession {
    id: string;
    impersonatedUserEmail: string;
    impersonatingUserEmail: string;
    sessionStartedAt: number;
}

export interface InitialMessagePayload {
    jobPostId?: Nullable<string>;
    messageText: string;
    otherUserId: string;
    phone?: Nullable<string>;
}

export interface InitialMessageResponseDTO {
    threadId: string;
}

export interface InviteDetailsDTO {
    createdAt: number;
    email: string;
    firstName: string;
    funderName: string;
    issuerFirstName: string;
    issuerLastName: string;
    lastName: string;
    reason: string;
    role: RoleType;
    status: SingleInvite_Status;
    token: string;
    verifiersInfo: string;
}

export interface InviteRedemptionDTO {
    inviteType: SingleInviteType;
    token: string;
}

export interface InvitedChildcareProviderDetailsDTO {
    email: string;
    firstName: string;
    lastName: string;
    licenseNumber: string;
}

export interface Iterable<T> {
}

export interface JobPostAndRepostDTO {
    jobPostDTO: JobPostDTO;
    verificationId?: Nullable<string>;
}

export interface JobPostDTO extends JobPost_Tags, JobPost_Hours, JobPost_HoursFlexible, JobPost_ScheduleNotes, JobPost_ScheduleFlexible, JobPost_FurtherIntroduction, JobPost_Address, JobPost_UserNotes, JobPost_FirstName, JobPost_Reposted {
    verificationId?: Nullable<string>;
}

export interface JobPostExtendedDTO extends JobPost_JobPostId, JobPost_JobNumber, JobPost_UserNameAndRole, JobPost_Hours, JobPost_HoursFlexible, JobPost_ScheduleNotes, JobPost_ScheduleFlexible, JobPost_FurtherIntroduction, JobPost_ReasonForDeletion, JobPost_Address, JobPost_GeoDataInfo, JobPost_PostDate, JobPost_Days, JobPost_DeletionDate, JobPost_UserNotes, JobPost_FirstName {
    bookmarked?: Nullable<boolean>;
    postingUserOrganization?: Nullable<string>;
    tags: JobPostTagEnum_DTO_TagAndCategory[];
    threadIds?: Nullable<string[]>;
}

export interface JobPostListDTO {
    distance: number;
    entity: JobPostExtendedDTO;
}

export interface JobPostSearchDTO {
    address: string;
    distanceMinutes: number;
    excludedTags: JobPostTagEnum[];
    maxHours?: Nullable<number>;
    minHours?: Nullable<number>;
    pageNumber: number;
    sort: JobPostSort;
    tagGroups: JobPostTagEnum[][];
}

export interface JobPostTagEnum_DTO {
}

export interface JobPostTagEnum_DTO_TagAndCategory {
    category: JobPostTagCategoryEnum;
    order: number;
    tag: JobPostTagEnum;
}

export interface JobPost_Address {
    address: string;
}

export interface JobPost_Days {
    daysPosted: number;
}

export interface JobPost_DeletionDate {
    resolvedDate?: Nullable<number>;
}

export interface JobPost_FirstName {
    firstName?: Nullable<string>;
}

export interface JobPost_FurtherIntroduction {
    furtherIntroduction: string;
}

export interface JobPost_GeoDataInfo {
    geoData: GeoData_DTO_Response_CityAndState;
}

export interface JobPost_Hours {
    hours: number;
}

export interface JobPost_HoursFlexible {
    hoursFlexible: boolean;
}

export interface JobPost_JobNumber {
    jobNumber: number;
}

export interface JobPost_JobPostId {
    id: string;
}

export interface JobPost_PostDate {
    createdAt: number;
}

export interface JobPost_ReasonForDeletion {
    resolvedNotes: string;
}

export interface JobPost_Reposted {
    reposted?: Nullable<boolean>;
}

export interface JobPost_ScheduleFlexible {
    scheduleFlexible: boolean;
}

export interface JobPost_ScheduleNotes {
    scheduleNotes: string;
}

export interface JobPost_Tags {
    tags: JobPostTagEnum[];
}

export interface JobPost_UserNameAndRole {
    postingUserInfo: User_DTO_Response_NameRoleAndId;
}

export interface JobPost_UserNotes {
    userNotes?: Nullable<string>;
}

export interface JobPosterPreferences {
    hours: number;
    jobTags: string[];
}

export interface JobResponseThreadDTO {
    archived: boolean;
    lastMessageDate: number;
    lastMessageText: string;
    numUnreadMessages: number;
    providerFirstName: string;
    providerHasImg: boolean;
    providerId: string;
    providerLastNameInitial: string;
    providerPhone?: Nullable<string>;
    starred: boolean;
    threadId: string;
}

export interface JobResponseThreadListDTO {
    jobResponsesMetadata: JobResponsesMetadataDTO;
    threads: JobResponseThreadDTO[];
}

export interface JobResponsesDTO {
    clientName: string;
    createdAt: number;
    daysOpen: number;
    hours: number;
    id: string;
    jobNumber: number;
    lastMessageDate: number;
    location: string;
    numThreads: number;
    resolvedMethod?: Nullable<string>;
    unreadMessageCount: number;
}

export interface JobResponsesMetadataDTO {
    clientName: string;
    id: string;
    jobIsResolved: boolean;
    jobNumber: number;
}

export interface LegacyUser_DTO {
}

export interface LegacyUser_DTO_Response_Session extends User_DTO_Response_Session {
}

export interface LicenseChangeRequestPayload {
    newLicense: string;
    providerId: string;
}

export interface LocationDTO {
    city: string;
    distance: number;
    state: string;
}

export interface LoginRequestDTO {
    email: string;
    password: string;
}

export interface MedicaidInviteListDTO {
    email: string;
    expiresAt: number;
    firstName: string;
    lastName: string;
    role: RoleType;
    status: SingleInvite_Status;
    token: string;
}

export interface MedicaidInvitesController_MedicaidInviteDTO {
    email: string;
    firstName: string;
    lastName: string;
    reason: string;
    registrationPathId: string;
    role: RoleType;
    verificationInputsJson: string;
}

export interface MedicaidInvitesController_MedicaidInviteRegPathOptionDTO {
    dtype: string;
    id: string;
    roleConfig: MedicaidInvitesController_RoleConfigOptionDTO;
}

export interface MedicaidInvitesController_OrganizationOptionDTO {
    id: string;
    name: string;
}

export interface MedicaidInvitesController_RoleConfigOptionDTO {
    id: string;
    roleDisplayName: string;
    roleType: RoleType;
}

export interface MedicaidProviderProfile_Availability {
    availability: ProviderProfileTagEnum;
}

export interface MedicaidProviderProfile_AvailabilityLastUpdate {
    availabilityLastChanged: number;
}

export interface MedicaidProviderProfile_AvailableHours {
    hours: number;
}

export interface MedicaidProviderProfile_DTO {
}

export interface MedicaidProviderProfile_DTO_Request_ProfileDetails extends MedicaidProviderProfile_ProviderProfileTagSet_Req {
    availability: ProviderProfileTagEnum;
    furtherIntroNotes: string;
    hasImg: boolean;
    hours: number;
    hoursFlexible: boolean;
    schedulingFlexible: boolean;
    schedulingNotes: string;
}

export interface MedicaidProviderProfile_DTO_Response_ProfileDetails extends MedicaidProviderProfile_ProviderProfileId, MedicaidProviderProfile_ProviderNameFunderAndId, MedicaidProviderProfile_Availability, MedicaidProviderProfile_AvailabilityLastUpdate, MedicaidProviderProfile_UpdatedAt, MedicaidProviderProfile_LastTrainingUpdate, MedicaidProviderProfile_AvailableHours, MedicaidProviderProfile_FlexibleHours, MedicaidProviderProfile_SchedulingNotes, MedicaidProviderProfile_SchedulingFlexible, MedicaidProviderProfile_FurtherIntroNotes, MedicaidProviderProfile_ProviderProfileTagSet_Res, MedicaidProviderProfile_ProviderProfileCredentialSet_Res {
    bookmarked?: Nullable<boolean>;
    geoDataCityState: GeoData_DTO_Response_CityAndState;
    hasImg: boolean;
    phone?: Nullable<string>;
    providerNumbers?: Nullable<ProviderNumber_DTO_Response_Details[]>;
    threadIds?: Nullable<string[]>;
}

export interface MedicaidProviderProfile_FlexibleHours {
    hoursFlexible: boolean;
}

export interface MedicaidProviderProfile_FurtherIntroNotes {
    furtherIntroNotes: string;
}

export interface MedicaidProviderProfile_LastTrainingUpdate {
    lastTrainingUpdate: number;
}

export interface MedicaidProviderProfile_ProviderNameFunderAndId {
    provider: User_DTO_Response_NameFunderAndId;
}

export interface MedicaidProviderProfile_ProviderProfileCredentialSet_Res {
    credentials: ProviderCredential_DTO_Response_Details[];
}

export interface MedicaidProviderProfile_ProviderProfileId {
    id: string;
}

export interface MedicaidProviderProfile_ProviderProfileTagSet_Req {
    providerProfileTags: ProviderProfileTagEnum_DTO_Value[];
}

export interface MedicaidProviderProfile_ProviderProfileTagSet_Res {
    tags: ProviderProfileTagEnum_DTO_ValueAndCategory[];
}

export interface MedicaidProviderProfile_SchedulingFlexible {
    schedulingFlexible: boolean;
}

export interface MedicaidProviderProfile_SchedulingNotes {
    schedulingNotes: string;
}

export interface MedicaidProviderProfile_UpdatedAt {
    updatedAt: number;
}

export interface MedicaidReferralCoordinator_DTO {
}

export interface MedicaidReferralCoordinator_DTO_Response_Session extends User_DTO_Response_Session {
    organization: Organization_DTO_Response_IdAndName;
}

export interface MessageListDTO {
    messageList: MessageResponseDTO[];
    unreadMessageCount: number;
}

export interface MessagePayload {
    messageText: string;
    threadId: string;
}

export interface MessageResponseAssistiveDTO {
    createdAt: number;
    firstName: string;
    hasImg?: Nullable<boolean>;
    id: string;
    lastName: string;
    messageText: string;
    userDType: string;
}

export interface MessageResponseDTO {
    createdAt: number;
    messageText: string;
    sender: MessagingUserDTO;
}

export interface MessageToChildcareProviderDTO {
    geoDataId: string;
    parentEmail: string;
    parentFirstName: string;
    parentMessage: string;
    parentPhone: string;
    parentZipCode: string;
    providerId: string;
}

export interface MessagingUserAssistiveDTO {
    firstName: string;
    hasImg?: Nullable<boolean>;
    id: string;
    lastName: string;
    userDType: string;
}

export interface MessagingUserDTO {
    firstName: string;
    hasImg?: Nullable<boolean>;
    id: string;
    lastNameOrInitial: string;
    organizationName?: Nullable<string>;
    roleDisplayName: string;
    roleType: RoleType;
}

export interface NewJobsNearbyEmailDTO {
    distance: number;
    id: string;
}

export interface NewMessageSettingsDTO {
    emailEnabled: boolean;
    smsEnabled: boolean;
}

export interface NewStatusDTO {
    declinedNotes?: Nullable<string>;
    declinedReason?: Nullable<ConsumerApplicationDeclinedReason>;
    status: ConsumerApplicationStatus;
}

export interface NotificationConfigInfoDTO {
    communicationMethod: CommunicationMethod;
    enable?: Nullable<boolean>;
    frequency: NotificationFrequency;
    type: NotificationType;
}

export interface OfField<F> extends TypeDescriptor {
    array: boolean;
    primitive: boolean;
}

export interface OnboardingDTO {
    progressDTO: OnboardingProgressDTO;
    roleDTO: OnboardingRoleDTO;
}

export interface OnboardingProfileStepDTO {
    profileValues: MedicaidProviderProfile_DTO_Request_ProfileDetails;
    stepType: OnboardingStepType;
}

export interface OnboardingProgressDTO extends OnboardingProgress_DTO_StepProgress {
}

export interface OnboardingProgress_DTO {
}

export interface OnboardingProgress_DTO_StepProgress {
    stepProgress: { [P in OnboardingStepType]?: OnboardingStepProgress };
}

export interface OnboardingRoleDTO extends RoleConfig_DTO_FunderSegment, RoleConfig_DTO_RoleTypeInfo {
}

export interface OnboardingStepDTO {
    json: string;
    settings?: Nullable<CurrentSettingsDTO>;
    stepType: OnboardingStepType;
}

export interface OnboardingStepProgress {
    complete: boolean;
    json: string;
    stepOrder: number;
}

export interface OnboardingStep_DTO {
}

export interface Order extends Serializable {
    ascending: boolean;
    descending: boolean;
    direction: Direction;
    ignoreCase: boolean;
    nullHandling: NullHandling;
    property: string;
}

export interface OrganizationJobPostDTO {
    city: string;
    coordinatorId: string;
    coordinatorName: string;
    firstName: string;
    id: string;
    readableJobId: number;
}

export interface Organization_DTO {
}

export interface Organization_DTO_Response_IdAndName {
    id: string;
    name: string;
}

export interface PPHCAgencyDashboardConsumersController_NewAssignedAdminDTO {
    newAdminID?: Nullable<string>;
}

export interface PPHCAgencyDashboardProvidersController_CreateOrUpdateResponseDTO {
    error?: Nullable<string>;
    mutatedProvider?: Nullable<AgencyProviderListing_DTO_Response_ListView>;
}

export interface PPHCAgencyDashboardProvidersController_ListingFormData {
    existingProviderListingID?: Nullable<string>;
    formData: AgencyProviderListing_DTO_Request_Create;
}

export interface PPHCCarinaAdminDashboardAgenciesController_AgencyAdminDetails extends User_DTO_Email, User_DTO_FirstName, User_DTO_LastName {
    agency: Agency_DTO_Response_Listing;
}

export interface PPHCCarinaAdminDashboardFundersController_FunderAdminDetails extends User_DTO_Id, User_DTO_Email, User_DTO_FirstName, User_DTO_LastName {
    funder: Funder_DTO_Response_IdOnly;
}

export interface PPHCCarinaAdminDashboardFundersController_FunderAdminListingDTO extends User_DTO_Id, User_DTO_FirstName, User_DTO_LastName, BaseEntity_DTO_CreatedAt, User_DTO_Email {
    email?: string;
    funder: PPHCCarinaAdminDashboardFundersController_FunderDataDTO;
}

export interface PPHCCarinaAdminDashboardFundersController_FunderDataDTO extends Funder_DTO_Name {
}

export interface PPHCConsumerXpController_GeoDataDTO extends GeoData_DTO_ZipCode {
}

export interface PPHCConsumerXpController_ProviderListingDTO extends AgencyProviderListing_DTO_Id, AgencyProviderListing_DTO_NumProviders, AgencyProviderListing_DTO_HasMaleProviders, AgencyProviderListing_DTO_Languages, AgencyProviderListing_DTO_Notes, AgencyProviderListing_DTO_UpdatedAt {
    agency: PPHCConsumerXpController_ProviderListing_AgencyDTO;
    geoData: PPHCConsumerXpController_GeoDataDTO;
}

export interface PPHCConsumerXpController_ProviderListing_AgencyDTO extends Agency_DTO_Id, Agency_DTO_AgencyRef, Agency_DTO_Funder, Agency_DTO_Name, Agency_DTO_Description, Agency_DTO_ExtendedDescription, Agency_DTO_Url, Agency_DTO_HourlyCost, Agency_DTO_Expertises, Agency_DTO_DiscountedCost, Agency_DTO_DiscountDescription, Agency_DTO_PhoneNumber {
}

export interface PPHCConsumerXpController_ProviderListing_WithDistanceDTO {
    distance: number;
    entity: PPHCConsumerXpController_ProviderListingDTO;
    searchedGeoDataId?: Nullable<string>;
}

export interface PPHCFunderDashboardAgencyAdminsController_AgencyAdminDetails extends User_DTO_Email, User_DTO_FirstName, User_DTO_LastName {
    agency: Agency_DTO_Response_Listing;
}

export interface Page<T> extends Slice<T> {
    totalElements: number;
    totalPages: number;
}

export interface PageInfo {
    filters: { [index: string]: string };
    pageNum: number;
    pageSize: number;
    search?: Nullable<string>;
    searchMap: { [index: string]: string };
    sort?: Nullable<SortInfo>;
}

export interface Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: Sort;
    unpaged: boolean;
}

export interface PasswordResetToken_DTOParts_Token {
    token: string;
}

export interface PhotoDTO {
    fileInBase64String: string;
}

export interface PrivatePayInvite_DTO {
}

export interface PrivatePayInvite_DTO_AgencyDetails {
    agency: Agency_DTO_Response_Details;
}

export interface PrivatePayInvite_DTO_Email {
    email: string;
}

export interface PrivatePayInvite_DTO_ExpiresAt {
    expiresAt: number;
}

export interface PrivatePayInvite_DTO_FirstName {
    firstName: string;
}

export interface PrivatePayInvite_DTO_LastName {
    lastName: string;
}

export interface PrivatePayInvite_DTO_RegisteredUser {
    registeredUser?: Nullable<User_DTO_Response_IdOnly>;
}

export interface PrivatePayInvite_DTO_Request_Update extends PrivatePayInvite_DTO_Email {
}

export interface PrivatePayInvite_DTO_Response_Details extends PrivatePayInvite_DTO_Token, PrivatePayInvite_DTO_FirstName, PrivatePayInvite_DTO_LastName, PrivatePayInvite_DTO_Email, PrivatePayInvite_DTO_AgencyDetails {
}

export interface PrivatePayInvite_DTO_Response_Find extends PrivatePayInvite_DTO_Token, PrivatePayInvite_DTO_FirstName, PrivatePayInvite_DTO_Email, PrivatePayInvite_DTO_RegisteredUser, PrivatePayInvite_DTO_ExpiresAt {
}

export interface PrivatePayInvite_DTO_Token {
    token: string;
}

export interface ProfileIdDTO {
    profileId: string;
}

export interface ProviderCredential_DTO {
}

export interface ProviderCredential_DTO_Badge {
    badge: boolean;
}

export interface ProviderCredential_DTO_Code {
    code: string;
}

export interface ProviderCredential_DTO_Description {
    description: string;
}

export interface ProviderCredential_DTO_Name {
    name: string;
}

export interface ProviderCredential_DTO_Response_Details extends ProviderCredential_DTO_Code, ProviderCredential_DTO_Name, ProviderCredential_DTO_Description, ProviderCredential_DTO_Badge {
}

export interface ProviderFilterPreferences {
    hours: number;
    providerProfileTags: string[];
}

export interface ProviderListDTO {
    distance: number;
    entity: MedicaidProviderProfile_DTO_Response_ProfileDetails;
}

export interface ProviderListing_DTO {
}

export interface ProviderListing_DTO_GeoData {
    geoData: GeoData_DTO_Response_Listing;
}

export interface ProviderListing_DTO_Id {
    id: string;
}

export interface ProviderListing_DTO_Response_ListView extends ProviderListing_DTO_Id, ProviderListing_DTO_GeoData {
}

export interface ProviderNotificationDTO {
    availability: ProviderProfileTagEnum;
    city: string;
    distance: number;
    firstName: string;
    hours: number;
    hoursFlexible: boolean;
    profileTags: ProviderProfileTagEnum_DTO_ValueAndCategory[];
    providerId: string;
    schedulingFlexible: boolean;
    state: string;
}

export interface ProviderNumber_DTO {
}

export interface ProviderNumber_DTO_Response_Details {
    providerNumber: string;
    providerNumberType: ProviderNumberTypeEnum;
}

export interface ProviderProfileSearchDTO {
    address: string;
    availability: ProviderProfileTagEnum[];
    distanceMinutes: number;
    excludedTags: ProviderProfileTagEnum[];
    maxHours?: Nullable<number>;
    minHours?: Nullable<number>;
    pageNumber: number;
    sort: ProviderProfileSort;
    tagGroups: ProviderProfileTagEnum[][];
}

export interface ProviderProfileTagEnum_DTO {
}

export interface ProviderProfileTagEnum_DTO_Value {
    tag: ProviderProfileTagEnum;
}

export interface ProviderProfileTagEnum_DTO_ValueAndCategory {
    category: ProviderProfileTagEnum_Category;
    order: number;
    tag: ProviderProfileTagEnum;
}

export interface QNewJobsNearbyEmailDTO extends ConstructorExpression<NewJobsNearbyEmailDTO> {
}

export interface RISEVerificationDTO {
    CONTACT_CELL?: Nullable<string>;
    CONTACT_EMAIL?: Nullable<string>;
    CREATED_DATE_HCW?: Nullable<string>;
    CREATED_DATE_PCA?: Nullable<string>;
    CREATED_DATE_PSW?: Nullable<string>;
    CREDENTIAL_STATUS_CODE_HCW?: Nullable<string>;
    CREDENTIAL_STATUS_CODE_PCA?: Nullable<string>;
    CREDENTIAL_STATUS_CODE_PSW?: Nullable<string>;
    FIRST_NAME?: Nullable<string>;
    LAST_ACTIVE_CREDENTIAL_EXPIRATION_HCW?: Nullable<string>;
    LAST_ACTIVE_CREDENTIAL_EXPIRATION_PCA?: Nullable<string>;
    LAST_ACTIVE_CREDENTIAL_EXPIRATION_PSW?: Nullable<string>;
    LAST_NAME?: Nullable<string>;
    PHYSICAL_ADDRESS_1?: Nullable<string>;
    PHYSICAL_ADDRESS_2?: Nullable<string>;
    PHYSICAL_CITY?: Nullable<string>;
    PHYSICAL_STATE?: Nullable<string>;
    PHYSICAL_ZIP?: Nullable<string>;
    PROVIDER_NUMBER_HCW?: Nullable<string>;
    PROVIDER_NUMBER_PCA?: Nullable<string>;
    PROVIDER_NUMBER_PSW?: Nullable<string>;
    PROVIDER_TYPE_HCW?: Nullable<string>;
    PROVIDER_TYPE_PCA?: Nullable<string>;
    PROVIDER_TYPE_PSW?: Nullable<string>;
    SPECIALTY_CODES_HCW?: Nullable<string>;
    SPECIALTY_CODES_PCA?: Nullable<string>;
    SPECIALTY_CODES_PSW?: Nullable<string>;
    UPDATED_DATE_HCW?: Nullable<string>;
    UPDATED_DATE_PCA?: Nullable<string>;
    UPDATED_DATE_PSW?: Nullable<string>;
    VENDOR_ID?: Nullable<string>;
}

export interface RedeemedInviteDTO {
    invitedUser: SingleInviteUserInfoDTO;
    regStep: RegistrationController_RegistrationStepDTO;
}

export interface RedeemedTokenDTO {
    redeemedUser: RedeemedTokenUserInfoDTO;
    regStep: RegistrationController_RegistrationStepDTO;
}

export interface RedeemedTokenUserInfoDTO {
    email: string;
    firstName: string;
    lastName: string;
    registrationPath: RegistrationController_RegPathDTO;
}

export interface RegistrationController_PasswordCreationDTO {
    email: string;
    langPref: LocizeLanguage;
    password: string;
}

export interface RegistrationController_RegPathDTO {
    id: string;
    registrationSteps: RegistrationController_RegistrationStepDTO[];
    roleConfig: RegistrationController_RoleConfigDTO;
}

export interface RegistrationController_RegistrationStepDTO extends RegistrationStep_DTO_Id, RegistrationStep_DTO_DisplayName, RegistrationStep_DTO_Order, RegistrationStep_DTO_Step {
}

export interface RegistrationController_RoleConfigDTO extends RoleConfig_DTO_Id, RoleConfig_DTO_RoleDisplayName, RoleConfig_DTO_RoleTypeInfo {
    funder: Funder_DTO_Response_SegmentOnly;
}

export interface RegistrationController_VerificationParamDTO {
    type: VerificationParameterType;
    value: string;
}

export interface RegistrationController_VerificationResponseBodyDTO {
    message: string;
    regStep: RegistrationController_RegistrationStepDTO;
}

export interface RegistrationController_VerifyCredentialsDTO {
    params: { [index: string]: RegistrationController_VerificationParamDTO };
}

export interface RegistrationRecordDTO {
    date: number;
    registrationRecordId: string;
}

export interface RegistrationStep_DTO {
}

export interface RegistrationStep_DTO_DisplayName {
    displayName: string;
}

export interface RegistrationStep_DTO_Id {
    id: string;
}

export interface RegistrationStep_DTO_Order {
    order: number;
}

export interface RegistrationStep_DTO_Step {
    step: RegistrationStepEnum;
}

export interface ReportableUserInfo {
    firstName: string;
    id: string;
    lastNameInitial: string;
    roleType: RoleType;
}

export interface RepresentativeJobPosterVerificationDTO {
    verificationResultId?: Nullable<string>;
    verificationStatus: VerificationStatusEnum;
}

export interface RoleChangeRequestPayload {
    changeNotes: string;
    currentRole: ApiUserType;
    newRole: ApiUserType;
    userId: string;
}

export interface RoleConfig_DTO {
}

export interface RoleConfig_DTO_FunderSegment {
    funder: Funder_DTO_Segment;
}

export interface RoleConfig_DTO_Id {
    id: string;
}

export interface RoleConfig_DTO_RoleDisplayName {
    roleDisplayName: string;
}

export interface RoleConfig_DTO_RoleTypeInfo {
    roleType: RoleType;
}

export interface RoleConfigsWGroupInviteDTO {
    funderId: string;
    regPathId: string;
    roleConfig: CarinaAdminFunderManagementController_RoleConfigDTO;
}

export interface RoleSelectionController_FunderWithRoleConfigDTO extends Funder_DTO_Id, Funder_DTO_Segment, Funder_DTO_State {
    roleConfigs: RoleSelectionController_RoleConfigDTO[];
}

export interface RoleSelectionController_RegPathDTO {
    dtype: string;
    id: string;
    registrationSteps: RegistrationController_RegistrationStepDTO[];
}

export interface RoleSelectionController_RoleConfigDTO extends RoleConfig_DTO_Id, RoleConfig_DTO_RoleDisplayName, RoleConfig_DTO_RoleTypeInfo {
    registrationPaths: RoleSelectionController_RegPathDTO[];
}

export interface RoleSelectionController_RoleSelectionDTO {
    email: string;
    firstName: string;
    lastName: string;
    referralMethod: ReferralMethodEnum;
    referralMethodOther: string;
    regPathId: string;
}

export interface SearchedGeoInfo {
    geoDataId: string;
    searchedAddress: string;
    searchedZipCode: string;
}

export interface Serializable {
}

export interface ServiceResponse<ResultType> {
    error?: Nullable<Error>;
    result?: Nullable<ResultType>;
}

export interface SettingsDTO {
    childcare?: Nullable<NotificationConfigInfoDTO>;
    jobFilter?: Nullable<NotificationConfigInfoDTO>;
    jobPostExpiration?: Nullable<NotificationConfigInfoDTO>;
    newJobs?: Nullable<NotificationConfigInfoDTO>;
    newMessage?: Nullable<NotificationConfigInfoDTO>;
    newProviders?: Nullable<NotificationConfigInfoDTO>;
    providerFilter?: Nullable<NotificationConfigInfoDTO>;
    updateProfileReminder?: Nullable<NotificationConfigInfoDTO>;
}

export interface SingleInviteUserInfoDTO {
    email: string;
    firstName: string;
    lastName: string;
    registrationPath: RegistrationController_RegPathDTO;
}

export interface Slice<T> extends Streamable<T> {
    content: T[];
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: Pageable;
    size: number;
    sort: Sort;
}

export interface Sort extends Streamable<Order>, Serializable {
    sorted: boolean;
    unsorted: boolean;
}

export interface SortInfo {
    columnName: string;
    direction: SortDirection;
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
    empty: boolean;
}

export interface SubmitWaitlistEntryDTO extends ConsumerWaitlistEntry_DTOParts_Email, ConsumerWaitlistEntry_DTOParts_ZipCode {
}

export interface Supplier<T> {
}

export interface SupportMessageDTO {
    email: string;
    firstName: string;
    lastName: string;
    message: string;
    phone: string;
    program: SupportMessageProgram;
    referrer?: Nullable<string>;
    role: SupportMessageRole;
    state: SupportMessageState;
    zipCode: string;
}

export interface SurveyJobInfoDTO {
    jobNumber: number;
}

export interface SurveyPayload {
    careProviderId?: Nullable<string>;
    hired: boolean;
    hours?: Nullable<number>;
    jobPostId?: Nullable<string>;
    resolvedNotes: string;
    testimonialConsent?: Nullable<boolean>;
    testimonialName?: Nullable<string>;
    testimonialSource?: Nullable<TestimonialSourceEnum>;
    threadId: string;
}

export interface SurveyProviderDTO {
    firstName: string;
    id: string;
    lastName: string;
}

export interface SyncUserCredsDTO {
    accountStatus: User_AccountStatus;
    email: string;
    firstName: string;
    hashedPassword: string;
    lastName?: Nullable<string>;
    legacyUserId: number;
    roleName: string;
}

export interface ThreadDetailsAssistiveDTO {
    archived: boolean;
    consumerFirstName?: Nullable<string>;
    id: string;
    jobPostId?: Nullable<string>;
    jobPostNumber?: Nullable<number>;
    otherUser: MessagingUserAssistiveDTO;
    postingUserId?: Nullable<string>;
    providerAvailability?: Nullable<ProviderProfileTagEnum>;
    resolvedDate?: Nullable<number>;
    starred: boolean;
}

export interface ThreadDetailsConsumerSpecificDTO extends Comparable<ThreadDetailsConsumerSpecificDTO> {
    consumerFirstName: string;
    pronouns: JobPostTagEnum[];
}

export interface ThreadDetailsDTO {
    archived: boolean;
    consumerData?: Nullable<ThreadDetailsConsumerSpecificDTO>;
    hours?: Nullable<number>;
    id: string;
    jobIsPostedByOtherUser?: Nullable<boolean>;
    jobIsResolved?: Nullable<boolean>;
    jobPostId?: Nullable<string>;
    jobPostNumber?: Nullable<number>;
    location?: Nullable<LocationDTO>;
    otherUser: MessagingUserDTO;
    phone?: Nullable<string>;
    providerData?: Nullable<ThreadDetailsProviderSpecificDTO>;
    scheduleNotes?: Nullable<string>;
    starred: boolean;
}

export interface ThreadDetailsProviderSpecificDTO extends Comparable<ThreadDetailsProviderSpecificDTO> {
    availability: ProviderProfileTagEnum;
    pronouns: ProviderProfileTagEnum[];
}

export interface ThreadListDTO {
    archived: boolean;
    jobPost?: Nullable<ThreadListJobPostDTO>;
    lastMessageDate: number;
    lastMessageText: string;
    otherUser: MessagingUserDTO;
    otherUserPhone?: Nullable<string>;
    starred: boolean;
    threadId: string;
    unread: boolean;
    unreadMessageCount: number;
    updatedAt: number;
}

export interface ThreadListJobPostDTO {
    consumerFirstName: string;
    jobIsResolved: boolean;
    jobPostId: string;
    jobPostNumber: number;
    jobPosterId: string;
}

export interface TokenRedemptionDTO {
    token: string;
}

export interface TrackAgencyCallDTO {
    agencyId: string;
    event: AgencyCallTrackingEvent;
    searchedGeoDataId: string;
}

export interface TravelDataDTO {
    dist_miles: number;
    time_minutes: number;
    travelType: TravelType;
}

export interface Type {
    typeName: string;
}

export interface TypeDescriptor {
}

export interface TypeVariable<D> extends Type, AnnotatedElement {
    annotatedBounds: AnnotatedType[];
    bounds: Type[];
    genericDeclaration: D;
    name: string;
}

export interface UnreadThreadsCountPerConfigDTO {
    configId: string;
    count: number;
}

export interface UpdateEmailDTO {
    newEmail: string;
}

export interface UpdateEmailFilterNotificationDTO {
    enable: boolean;
    frequency: NotificationFrequency;
}

export interface UpdateLangPrefDTO {
    newLangPref: LocizeLanguage;
}

export interface UserAlertDTO {
    acknowledged: boolean;
    alertType: UserAlertType;
}

export interface UserController_PasswordResetDTO {
    password: string;
    token: string;
}

export interface UserController_PasswordResetRequestDTO {
    email: string;
}

export interface UserController_PasswordResetTokenDataDTO extends PasswordResetToken_DTOParts_Token {
    registeredUser: UserController_PasswordResetTokenDataDTO_UserEmail;
}

export interface UserController_PasswordResetTokenDataDTO_UserEmail extends User_DTO_Email {
    email?: string;
}

export interface UserController_RequestDeactivationDTO {
    userId: string;
}

export interface UserController_SessionDTO {
    impersonatingUser?: Nullable<User_DTO_Response_Session>;
    user: User_DTO_Response_Session;
}

export interface UserReportActionDTO {
    action: Action;
    actionNotes: string;
    by: string;
    caseNumber: number;
    date: number;
}

export interface UserReportDetailsDTO {
    actions: UserReportActionDTO[];
    behavior: Behavior;
    caseNumber: number;
    category: BehaviorCategory;
    offenderCounts: string;
    offenderId: string;
    offenderLanguage: string;
    offenderName: string;
    program: string;
    reportId: string;
    reportNotes: string;
    reportedDate: number;
    reporterCounts: string;
    reporterId: string;
    reporterLanguage: string;
    reporterName: string;
    status: ReportStatusEnum;
}

export interface UserReportListDTO {
    behavior: Behavior;
    caseNumber: number;
    category: BehaviorCategory;
    id: string;
    notes: string;
    offender: string;
    offenderRole: string;
    reporter: string;
    reporterRole: string;
    status: ReportStatusEnum;
    submittedDate: number;
    updatedDate: number;
}

export interface UserReportPayload {
    behavior: Behavior;
    notes: string;
    reportSource: ReportSourceEnum;
    reportedUserId: string;
}

export interface UserSupportManager_DTO {
}

export interface UserSupportManager_DTO_Response_Session extends User_DTO_Response_Session {
}

export interface User_DTO {
}

export interface User_DTO_AccountStatus {
    accountStatus: User_AccountStatus;
}

export interface User_DTO_AssistiveRole {
    assistiveRole: boolean;
}

export interface User_DTO_Email {
    email?: Nullable<string>;
}

export interface User_DTO_FirstName {
    firstName: string;
}

export interface User_DTO_Id {
    id: string;
}

export interface User_DTO_LastName {
    lastName: string;
}

export interface User_DTO_Permissions {
    permissions: Permission[];
}

export interface User_DTO_Response_IdOnly extends User_DTO_Id {
}

export interface User_DTO_Response_NameAndIdOnly extends User_DTO_Id, User_DTO_FirstName, User_DTO_LastName {
}

export interface User_DTO_Response_NameFunderAndId extends User_DTO_Id, User_DTO_FirstName, User_DTO_LastName, User_DTO_Role {
    funder: Funder_DTO_Response_SegmentOnly;
}

export interface User_DTO_Response_NameOnly extends User_DTO_FirstName, User_DTO_LastName {
}

export interface User_DTO_Response_NameRoleAndId {
    firstName: string;
    id: string;
    role: string;
}

export interface User_DTO_Response_Session extends User_DTO_Id, User_DTO_Email, User_DTO_FirstName, User_DTO_LastName, User_DTO_Role, User_DTO_AssistiveRole, User_DTO_AccountStatus, User_DTO_Permissions {
    funder: Funder_DTO_Response_Listing;
    geoData: GeoData_DTO_Response_CityAndState;
    languagePreference: LocizeLanguage;
}

export interface User_DTO_Role {
    role: string;
}

export interface UseridDTO {
    userid: string;
}

export interface VerificationConfig_DTO {
}

export interface VerificationConfig_DTO_VerificationStrategy {
    verificationStrategy: VerificationStrategyEnum;
}

export interface notifConfigDTO {
    configId: string;
}

export type Nullable<T> = T | undefined;

export type RestResponse<R> = Promise<GenericAxiosResponse<R>>;

export enum Action {
    STATUS_CHANGE = "STATUS_CHANGE",
    MESSAGE_REPORTER = "MESSAGE_REPORTER",
    ADD_NOTES = "ADD_NOTES",
    MESSAGE_MANAGER = "MESSAGE_MANAGER",
    MESSAGE_OFFENDER = "MESSAGE_OFFENDER",
    SUSPEND_OFFENDER = "SUSPEND_OFFENDER",
    UNSUSPEND_OFFENDER = "UNSUSPEND_OFFENDER",
    DELETE_OFFENDER = "DELETE_OFFENDER",
    BLOCK_OFFENDER = "BLOCK_OFFENDER",
    REPORT_DSHS = "REPORT_DSHS",
    OTHER_ACTION = "OTHER_ACTION",
    BEHAVIOR_CHANGE = "BEHAVIOR_CHANGE",
}

export enum AgencyCallTrackingEvent {
    EXPAND_ACCORDION = "EXPAND_ACCORDION",
    CLICK_CALL_BUTTON = "CLICK_CALL_BUTTON",
    CLICK_PHONE_NUMBER = "CLICK_PHONE_NUMBER",
}

export enum AgencyExpertises {
    MEALS = "MEALS",
    PERSONAL_CARE = "PERSONAL_CARE",
    CLEANING = "CLEANING",
    TRANSFERS = "TRANSFERS",
    LAUNDRY = "LAUNDRY",
    MEDICATION_MANAGEMENT = "MEDICATION_MANAGEMENT",
    SHOPPING = "SHOPPING",
    TRANSPORTATION = "TRANSPORTATION",
}

export enum AgencyInviteStatus {
    INVITED = "INVITED",
    ACTIVE = "ACTIVE",
    INVITE_EXPIRED = "INVITE_EXPIRED",
}

export enum ApiUserType {
    AgencyAdmin = "AgencyAdmin",
    CarinaAdmin = "CarinaAdmin",
    FunderAdmin = "FunderAdmin",
    LegacyUser = "LegacyUser",
    Consumer = "Consumer",
    Provider = "Provider",
    ProxyProvider = "ProxyProvider",
    MedicaidReferralCoordinator = "MedicaidReferralCoordinator",
    UserSupportManager = "UserSupportManager",
    UserSupportAgent = "UserSupportAgent",
    MedicaidCaseManager = "MedicaidCaseManager",
    CaseWorker = "CaseWorker",
    ChildcareProvider = "ChildcareProvider",
}

export enum Behavior {
    NON_RESPONSIVE = "NON_RESPONSIVE",
    NO_SHOW = "NO_SHOW",
    CURSING = "CURSING",
    NEGATIVE_EXPERIENCE = "NEGATIVE_EXPERIENCE",
    OTHER_UNPROFESSIONAL = "OTHER_UNPROFESSIONAL",
    SEXUALLY_SUGGESTIVE = "SEXUALLY_SUGGESTIVE",
    DISCRIMINATORY = "DISCRIMINATORY",
    OTHER_HOSTILE = "OTHER_HOSTILE",
    ADULT_ABUSE_NEGLECT = "ADULT_ABUSE_NEGLECT",
    CHILD_ABUSE_NEGLECT = "CHILD_ABUSE_NEGLECT",
    VIOLENCE = "VIOLENCE",
    OTHER_ABUSIVE = "OTHER_ABUSIVE",
    SOMETHING_ELSE = "SOMETHING_ELSE",
}

export enum BehaviorCategory {
    UNPROFESSIONAL = "UNPROFESSIONAL",
    OFFENSIVE_HOSTILE = "OFFENSIVE_HOSTILE",
    ABUSIVE_BEHAVIOR = "ABUSIVE_BEHAVIOR",
    SOMETHING_ELSE = "SOMETHING_ELSE",
}

export enum BlockedPerson_BlockedSourceEnum {
    USER_REPORT_VIOLENCE = "USER_REPORT_VIOLENCE",
    CARINA_ADMIN = "CARINA_ADMIN",
}

export enum CommunicationMethod {
    EMAIL = "EMAIL",
    SMS = "SMS",
    NONE = "NONE",
}

export enum ConsumerApplicationCareNeeds {
    HOUSEKEEPING = "HOUSEKEEPING",
    LAUNDRY = "LAUNDRY",
    MEAL_PREP = "MEAL_PREP",
    SHOPPING = "SHOPPING",
    HYGIENE = "HYGIENE",
    BEHAVIORAL_CHALLENGES = "BEHAVIORAL_CHALLENGES",
    AMBULATION = "AMBULATION",
    POSITIONING = "POSITIONING",
    TRANSFER = "TRANSFER",
    ASSISTED_TRANSFER = "ASSISTED_TRANSFER",
}

export enum ConsumerApplicationDeclinedReason {
    CLIENT_CANNOT_AFFORD_RATES = "CLIENT_CANNOT_AFFORD_RATES",
    CLIENT_NO_LONGER_NEEDS_SERVICES = "CLIENT_NO_LONGER_NEEDS_SERVICES",
    CLIENT_NEEDS_OUTSIDE_AGENCY_SCOPE = "CLIENT_NEEDS_OUTSIDE_AGENCY_SCOPE",
    CLIENT_DID_NOT_RESPOND = "CLIENT_DID_NOT_RESPOND",
    AGENCY_HAS_NO_AVAILABLE_PROVIDERS = "AGENCY_HAS_NO_AVAILABLE_PROVIDERS",
    AGENCY_CANNOT_MEET_CLIENT_REQUIREMENTS = "AGENCY_CANNOT_MEET_CLIENT_REQUIREMENTS",
    INDIVIDUAL_LOOKING_FOR_CAREGIVING_WORK_NOT_SERVICES = "INDIVIDUAL_LOOKING_FOR_CAREGIVING_WORK_NOT_SERVICES",
    OTHER = "OTHER",
}

export enum ConsumerApplicationFundingSource {
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC",
    OTHER = "OTHER",
}

export enum ConsumerApplicationGender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    DIFFERENT_IDENTITY = "DIFFERENT_IDENTITY",
    NOT_DISCLOSED = "NOT_DISCLOSED",
}

export enum ConsumerApplicationHomeEnvironmentKey {
    SMOKING_HOME = "SMOKING_HOME",
    FRAGRANCE_FREE = "FRAGRANCE_FREE",
    DOGS = "DOGS",
    CATS = "CATS",
    BIRDS = "BIRDS",
    OTHER_PETS = "OTHER_PETS",
    PROVIDER_SMOKING = "PROVIDER_SMOKING",
}

export enum ConsumerApplicationLanguage {
    ENGLISH = "ENGLISH",
    SPANISH = "SPANISH",
    VIETNAMESE = "VIETNAMESE",
    RUSSIAN = "RUSSIAN",
    CHINESE_CANTONESE = "CHINESE_CANTONESE",
    CHINESE_MANDARIN = "CHINESE_MANDARIN",
    TAGALOG = "TAGALOG",
    KOREAN = "KOREAN",
    AMERICAN_SIGN_LANGUAGE = "AMERICAN_SIGN_LANGUAGE",
    AMHARIC = "AMHARIC",
    ARABIC = "ARABIC",
    SOMALI = "SOMALI",
    UKRAINIAN = "UKRAINIAN",
    BENGALI = "BENGALI",
    HAITIAN_CREOLE = "HAITIAN_CREOLE",
    ITALIAN = "ITALIAN",
    POLISH = "POLISH",
    YIDDISH = "YIDDISH",
    HEBREW = "HEBREW",
    EDO_BINI = "EDO_BINI",
    URDU = "URDU",
    HINDI = "HINDI",
    FRENCH = "FRENCH",
    TWI = "TWI",
}

export enum ConsumerApplicationRelationship {
    MYSELF = "MYSELF",
    SON_DAUGHTER = "SON_DAUGHTER",
    SPOUSE_PARTNER = "SPOUSE_PARTNER",
    RELATIVE = "RELATIVE",
    FRIEND = "FRIEND",
    CASE_WORKER = "CASE_WORKER",
    NON_PROFIT = "NON_PROFIT",
    OTHER = "OTHER",
}

export enum ConsumerApplicationStatus {
    NEW_LEAD = "NEW_LEAD",
    CONTACTED = "CONTACTED",
    CONTACTED_2 = "CONTACTED_2",
    PHONE_SCREENED = "PHONE_SCREENED",
    NOT_NOW = "NOT_NOW",
    ASSIGNMENT_PENDING = "ASSIGNMENT_PENDING",
    MATCHED = "MATCHED",
    DECLINED = "DECLINED",
}

export enum ContactMethod {
    PHONE = "PHONE",
    SMS = "SMS",
    EMAIL = "EMAIL",
}

export enum DeleteJobResponseMessage {
    JOB_NOT_FOUND = "JOB_NOT_FOUND",
    JOB_ALREADY_DELETED = "JOB_ALREADY_DELETED",
    EMAIL_MISMATCH = "EMAIL_MISMATCH",
    GENERIC_JOB_DELETE_ERROR = "GENERIC_JOB_DELETE_ERROR",
}

export enum Direction {
    ASC = "ASC",
    DESC = "DESC",
}

export enum EmailLanguage {
    English = "English",
    Spanish = "Spanish",
    Russian = "Russian",
}

export enum FooterType {
    Default = "Default",
    ChildCareProvider = "ChildCareProvider",
    MedicaidNotification = "MedicaidNotification",
}

export enum GeneralAPIConfig_Status {
    ACTIVE = "ACTIVE",
    DEACTIVATED = "DEACTIVATED",
    MOCK = "MOCK",
}

export enum HighlightReason {
    NEW = "NEW",
    STALLED = "STALLED",
    UNASSIGNED = "UNASSIGNED",
}

export enum HttpMethod {
    GET = "GET",
    HEAD = "HEAD",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
    OPTIONS = "OPTIONS",
    TRACE = "TRACE",
}

export enum JobPostSort {
    DISTANCE = "DISTANCE",
    POST_DATE = "POST_DATE",
}

export enum JobPostTagCategoryEnum {
    AGE_RANGE = "AGE_RANGE",
    TYPE_OF_SUPPORT = "TYPE_OF_SUPPORT",
    CLIENT_GENDER = "CLIENT_GENDER",
    PRONOUN = "PRONOUN",
    PREFERRED_PROVIDER_GENDER = "PREFERRED_PROVIDER_GENDER",
    LANGUAGE = "LANGUAGE",
    TYPE_OF_CARE = "TYPE_OF_CARE",
    SKILL = "SKILL",
    MOVEMENT = "MOVEMENT",
    TRANSPORTATION = "TRANSPORTATION",
    ENVIRONMENT = "ENVIRONMENT",
    ENVIRONMENT_GENERAL = "ENVIRONMENT_GENERAL",
    PETS = "PETS",
    SPECIALIZED_SKILL = "SPECIALIZED_SKILL",
}

export enum JobPostTagEnum {
    CHILD = "CHILD",
    ADULT = "ADULT",
    OLDER_ADULT = "OLDER_ADULT",
    BEHAVIORAL = "BEHAVIORAL",
    ADDICTIONS_MENTAL = "ADDICTIONS_MENTAL",
    DEVELOPMENTAL_DISABILITY = "DEVELOPMENTAL_DISABILITY",
    HOME_GENERAL = "HOME_GENERAL",
    PHYSICAL_DISABILITY = "PHYSICAL_DISABILITY",
    MALE_CLIENT = "MALE_CLIENT",
    FEMALE_CLIENT = "FEMALE_CLIENT",
    NONBINARY_CLIENT = "NONBINARY_CLIENT",
    NO_GENDER_PREFERENCE_CLIENT = "NO_GENDER_PREFERENCE_CLIENT",
    HE_HIM = "HE_HIM",
    SHE_HER = "SHE_HER",
    THEY_THEM = "THEY_THEM",
    XE_XEM = "XE_XEM",
    ZE_ZIR = "ZE_ZIR",
    MALE = "MALE",
    FEMALE = "FEMALE",
    NONBINARY = "NONBINARY",
    NO_GENDER_PREFERENCE = "NO_GENDER_PREFERENCE",
    ENGLISH = "ENGLISH",
    ALBANIAN = "ALBANIAN",
    AMERICAN_SIGN = "AMERICAN_SIGN",
    AMHARIC = "AMHARIC",
    ARABIC = "ARABIC",
    ARMENIAN = "ARMENIAN",
    BENGALI = "BENGALI",
    BOSNIAN = "BOSNIAN",
    BULGARIAN = "BULGARIAN",
    BURMESE = "BURMESE",
    CAMBODIAN = "CAMBODIAN",
    CANTONESE = "CANTONESE",
    CHUUKESE = "CHUUKESE",
    CREOLE = "CREOLE",
    CROATIAN = "CROATIAN",
    DARI = "DARI",
    FARSI = "FARSI",
    FRENCH = "FRENCH",
    GERMAN = "GERMAN",
    HINDI = "HINDI",
    HMONG = "HMONG",
    ITALIAN = "ITALIAN",
    JAPANESE = "JAPANESE",
    KAREN = "KAREN",
    KHMER = "KHMER",
    KOREAN = "KOREAN",
    KURDISH = "KURDISH",
    LAOTIAN = "LAOTIAN",
    MANDARIN = "MANDARIN",
    MOLDAVIAN = "MOLDAVIAN",
    NEPALI = "NEPALI",
    OROMO = "OROMO",
    PASHTU = "PASHTU",
    POLISH = "POLISH",
    PORTUGUESE = "PORTUGUESE",
    PUNJABI = "PUNJABI",
    ROMANIAN = "ROMANIAN",
    RUSSIAN = "RUSSIAN",
    SAMOAN = "SAMOAN",
    SOMALI = "SOMALI",
    SPANISH = "SPANISH",
    SWAHILI = "SWAHILI",
    TAGALOG = "TAGALOG",
    TAMIL = "TAMIL",
    THAI = "THAI",
    TIGRINYA = "TIGRINYA",
    TONGAN = "TONGAN",
    TURKISH = "TURKISH",
    UKRAINIAN = "UKRAINIAN",
    URDU = "URDU",
    VIETNAMESE = "VIETNAMESE",
    OTHER_LANGUAGE = "OTHER_LANGUAGE",
    ONGOING = "ONGOING",
    ONETIME = "ONETIME",
    ONCALL = "ONCALL",
    HOUSEKEEPING = "HOUSEKEEPING",
    LAUNDRY = "LAUNDRY",
    MEAL_PREP = "MEAL_PREP",
    SHOPPING = "SHOPPING",
    PERSONAL_CARE = "PERSONAL_CARE",
    MEDS_MGMT = "MEDS_MGMT",
    BEHAVIOR_CHALLENGE = "BEHAVIOR_CHALLENGE",
    LGBT_FRIENDLY = "LGBT_FRIENDLY",
    NURSE_DELEGATION = "NURSE_DELEGATION",
    AMBULATION = "AMBULATION",
    POSITIONING = "POSITIONING",
    TRANSFER = "TRANSFER",
    TRANSFER_ASSISTED = "TRANSFER_ASSISTED",
    TRANSPORT = "TRANSPORT",
    TRANSPORT_PROVIDER = "TRANSPORT_PROVIDER",
    FRAG_FREE = "FRAG_FREE",
    NON_SMOKING = "NON_SMOKING",
    SMOKING = "SMOKING",
    NON_SMOKING_PROVIDER = "NON_SMOKING_PROVIDER",
    MULTIGENERATIONAL_HOUSEHOLD = "MULTIGENERATIONAL_HOUSEHOLD",
    MULTILINGUAL_HOUSEHOLD = "MULTILINGUAL_HOUSEHOLD",
    LGBTQIA_HOUSEHOLD = "LGBTQIA_HOUSEHOLD",
    MULTIGENERATIONAL_HOUSEHOLDS = "MULTIGENERATIONAL_HOUSEHOLDS",
    MULTILINGUAL_HOUSEHOLDS = "MULTILINGUAL_HOUSEHOLDS",
    LGBTQIA_HOUSEHOLDS = "LGBTQIA_HOUSEHOLDS",
    DOG = "DOG",
    CAT = "CAT",
    BIRD = "BIRD",
    OTHER_PET = "OTHER_PET",
    CLEAN = "CLEAN",
    COOKDIET = "COOKDIET",
    EMPATHETIC = "EMPATHETIC",
    COMMUNICATOR = "COMMUNICATOR",
    BEHAVIORXP = "BEHAVIORXP",
    LISTENER = "LISTENER",
    ADVOCATE = "ADVOCATE",
    COOK = "COOK",
    RELATIONSHIPS = "RELATIONSHIPS",
    KIND = "KIND",
    TALKER = "TALKER",
    MOTIVATOR = "MOTIVATOR",
    ORGANIZED = "ORGANIZED",
    PUNCTUAL = "PUNCTUAL",
    AUTISTIC = "AUTISTIC",
    MEMORY_CARE = "MEMORY_CARE",
    NON_VERBAL = "NON_VERBAL",
}

export enum LocizeLanguage {
    English = "English",
    Spanish = "Spanish",
    Russian = "Russian",
}

export enum NotificationFrequency {
    IMMEDIATE = "IMMEDIATE",
    DAILY = "DAILY",
    DAILY_WEEKDAY = "DAILY_WEEKDAY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    DELAYED = "DELAYED",
}

export enum NotificationType {
    MARKETING_BLAST = "MARKETING_BLAST",
    CHILDCARE_PARENT_MESSAGE = "CHILDCARE_PARENT_MESSAGE",
    NEW_CONSUMER_APPLICATIONS = "NEW_CONSUMER_APPLICATIONS",
    NEW_OR_STALLED_CONSUMER_APPLICATIONS = "NEW_OR_STALLED_CONSUMER_APPLICATIONS",
    NEW_JOBS_NEARBY = "NEW_JOBS_NEARBY",
    NEW_MESSAGE = "NEW_MESSAGE",
    NEW_PROVIDERS_NEARBY = "NEW_PROVIDERS_NEARBY",
    INVITE = "INVITE",
    JOB_POST_EXPIRATION_FIRST_WARNING = "JOB_POST_EXPIRATION_FIRST_WARNING",
    JOB_POST_EXPIRATION_SECOND_WARNING = "JOB_POST_EXPIRATION_SECOND_WARNING",
    JOB_POST_EXPIRATION_FINAL_WARNING = "JOB_POST_EXPIRATION_FINAL_WARNING",
    NEW_JOBS_FILTER = "NEW_JOBS_FILTER",
    NEW_PROVIDERS_FILTER = "NEW_PROVIDERS_FILTER",
    AVAILABILITY_STATUS_FIRST_WARNING = "AVAILABILITY_STATUS_FIRST_WARNING",
    AVAILABILITY_STATUS_SECOND_WARNING = "AVAILABILITY_STATUS_SECOND_WARNING",
    AVAILABILITY_STATUS_FINAL_WARNING = "AVAILABILITY_STATUS_FINAL_WARNING",
    CHILDCARE_AVAILABILITY_STATUS_FIRST_WARNING = "CHILDCARE_AVAILABILITY_STATUS_FIRST_WARNING",
    CHILDCARE_AVAILABILITY_STATUS_SECOND_WARNING = "CHILDCARE_AVAILABILITY_STATUS_SECOND_WARNING",
    CHILDCARE_AVAILABILITY_STATUS_FINAL_WARNING = "CHILDCARE_AVAILABILITY_STATUS_FINAL_WARNING",
}

export enum NullHandling {
    NATIVE = "NATIVE",
    NULLS_FIRST = "NULLS_FIRST",
    NULLS_LAST = "NULLS_LAST",
}

export enum OnboardingStepType {
    GUIDELINES = "GUIDELINES",
    PROFILE = "PROFILE",
    SETTINGS = "SETTINGS",
}

export enum Permission {
    FUNDER_SELECT = "FUNDER_SELECT",
    ADMIN_TOOLS_USER_SEARCH = "ADMIN_TOOLS_USER_SEARCH",
    ADMIN_TOOLS_USER_INVITE = "ADMIN_TOOLS_USER_INVITE",
    ADMIN_TOOLS_USER_PASSWORD_RESET = "ADMIN_TOOLS_USER_PASSWORD_RESET",
    ADMIN_TOOLS_USER_EMAIL_CHANGE = "ADMIN_TOOLS_USER_EMAIL_CHANGE",
    ADMIN_TOOLS_USER_ROLE_CHANGE = "ADMIN_TOOLS_USER_ROLE_CHANGE",
    ADMIN_TOOLS_LICENSE_NUMBER_CHANGE = "ADMIN_TOOLS_LICENSE_NUMBER_CHANGE",
    ADMIN_TOOLS_ADDRESS_CHANGE = "ADMIN_TOOLS_ADDRESS_CHANGE",
    ADMIN_TOOLS_REPORTING_SYSTEM = "ADMIN_TOOLS_REPORTING_SYSTEM",
    MESSAGING_ACCESS = "MESSAGING_ACCESS",
    SETTINGS_ACCESS = "SETTINGS_ACCESS",
    SETTINGS_ACCESS_JOB_FILTERS = "SETTINGS_ACCESS_JOB_FILTERS",
    SETTINGS_ACCESS_PROVIDER_FILTERS = "SETTINGS_ACCESS_PROVIDER_FILTERS",
}

export enum ProviderNumberTypeEnum {
    HCW = "HCW",
    PSW = "PSW",
    PCA = "PCA",
    PROVIDER_ONE = "PROVIDER_ONE",
}

export enum ProviderProfileSort {
    DISTANCE = "DISTANCE",
    JOIN_DATE = "JOIN_DATE",
}

export enum ProviderProfileTagEnum {
    SEEKING = "SEEKING",
    NOTSEEKING = "NOTSEEKING",
    SOON = "SOON",
    ENGLISH = "ENGLISH",
    ALBANIAN = "ALBANIAN",
    AMERICAN_SIGN = "AMERICAN_SIGN",
    AMHARIC = "AMHARIC",
    ARABIC = "ARABIC",
    ARMENIAN = "ARMENIAN",
    BENGALI = "BENGALI",
    BOSNIAN = "BOSNIAN",
    BULGARIAN = "BULGARIAN",
    BURMESE = "BURMESE",
    CAMBODIAN = "CAMBODIAN",
    CANTONESE = "CANTONESE",
    CHUUKESE = "CHUUKESE",
    CREOLE = "CREOLE",
    CROATIAN = "CROATIAN",
    DARI = "DARI",
    FARSI = "FARSI",
    FRENCH = "FRENCH",
    GERMAN = "GERMAN",
    HINDI = "HINDI",
    HMONG = "HMONG",
    ITALIAN = "ITALIAN",
    JAPANESE = "JAPANESE",
    KAREN = "KAREN",
    KHMER = "KHMER",
    KOREAN = "KOREAN",
    KURDISH = "KURDISH",
    LAOTIAN = "LAOTIAN",
    MANDARIN = "MANDARIN",
    MOLDAVIAN = "MOLDAVIAN",
    NEPALI = "NEPALI",
    OROMO = "OROMO",
    PASHTU = "PASHTU",
    POLISH = "POLISH",
    PORTUGUESE = "PORTUGUESE",
    PUNJABI = "PUNJABI",
    ROMANIAN = "ROMANIAN",
    RUSSIAN = "RUSSIAN",
    SAMOAN = "SAMOAN",
    SOMALI = "SOMALI",
    SPANISH = "SPANISH",
    SWAHILI = "SWAHILI",
    TAGALOG = "TAGALOG",
    TAMIL = "TAMIL",
    THAI = "THAI",
    TIGRINYA = "TIGRINYA",
    TONGAN = "TONGAN",
    TURKISH = "TURKISH",
    UKRAINIAN = "UKRAINIAN",
    URDU = "URDU",
    VIETNAMESE = "VIETNAMESE",
    OTHER_LANGUAGE = "OTHER_LANGUAGE",
    CHILD = "CHILD",
    ADULT = "ADULT",
    OLDER_ADULT = "OLDER_ADULT",
    BEHAVIORAL = "BEHAVIORAL",
    ADDICTIONS_MENTAL = "ADDICTIONS_MENTAL",
    DEVELOPMENTAL_DISABILITY = "DEVELOPMENTAL_DISABILITY",
    HOME_GENERAL = "HOME_GENERAL",
    PHYSICAL_DISABILITY = "PHYSICAL_DISABILITY",
    MALE_CLIENT = "MALE_CLIENT",
    FEMALE_CLIENT = "FEMALE_CLIENT",
    NONBINARY_CLIENT = "NONBINARY_CLIENT",
    NO_GENDER_PREFERENCE_CLIENT = "NO_GENDER_PREFERENCE_CLIENT",
    MALE = "MALE",
    FEMALE = "FEMALE",
    NONBINARY = "NONBINARY",
    NO_GENDER_PREFERENCE = "NO_GENDER_PREFERENCE",
    ONGOING = "ONGOING",
    ONETIME = "ONETIME",
    ONCALL = "ONCALL",
    HOUSEKEEPING = "HOUSEKEEPING",
    LAUNDRY = "LAUNDRY",
    MEAL_PREP = "MEAL_PREP",
    SHOPPING = "SHOPPING",
    PERSONAL_CARE = "PERSONAL_CARE",
    MEDS_MGMT = "MEDS_MGMT",
    BEHAVIOR_CHALLENGE = "BEHAVIOR_CHALLENGE",
    LGBT_FRIENDLY = "LGBT_FRIENDLY",
    NURSE_DELEGATION = "NURSE_DELEGATION",
    AMBULATION = "AMBULATION",
    POSITIONING = "POSITIONING",
    TRANSFER = "TRANSFER",
    TRANSFER_ASSISTED = "TRANSFER_ASSISTED",
    TRANSPORT = "TRANSPORT",
    TRANSPORT_PROVIDER = "TRANSPORT_PROVIDER",
    FRAG_FREE = "FRAG_FREE",
    NON_SMOKING = "NON_SMOKING",
    SMOKING = "SMOKING",
    NON_SMOKING_PROVIDER = "NON_SMOKING_PROVIDER",
    MULTIGENERATIONAL_HOUSEHOLDS = "MULTIGENERATIONAL_HOUSEHOLDS",
    MULTILINGUAL_HOUSEHOLDS = "MULTILINGUAL_HOUSEHOLDS",
    LGBTQIA_HOUSEHOLDS = "LGBTQIA_HOUSEHOLDS",
    DOG = "DOG",
    CAT = "CAT",
    BIRD = "BIRD",
    OTHER_PET = "OTHER_PET",
    CLEAN = "CLEAN",
    COOKDIET = "COOKDIET",
    EMPATHETIC = "EMPATHETIC",
    COMMUNICATOR = "COMMUNICATOR",
    BEHAVIORXP = "BEHAVIORXP",
    LISTENER = "LISTENER",
    ADVOCATE = "ADVOCATE",
    COOK = "COOK",
    RELATIONSHIPS = "RELATIONSHIPS",
    KIND = "KIND",
    TALKER = "TALKER",
    MOTIVATOR = "MOTIVATOR",
    ORGANIZED = "ORGANIZED",
    PUNCTUAL = "PUNCTUAL",
    AUTISTIC = "AUTISTIC",
    MEMORY_CARE = "MEMORY_CARE",
    NON_VERBAL = "NON_VERBAL",
    HE_HIM = "HE_HIM",
    SHE_HER = "SHE_HER",
    THEY_THEM = "THEY_THEM",
    XE_XEM = "XE_XEM",
    ZE_ZIR = "ZE_ZIR",
    EXEMPT = "EXEMPT",
    HCA_AHCAS = "HCA_AHCAS",
    IN_PROCESS = "IN_PROCESS",
    RESPITE_LIMITED = "RESPITE_LIMITED",
    OTHER_FILTER = "OTHER_FILTER",
    HCW = "HCW",
    PCA = "PCA",
    PSW = "PSW",
    DAYTIME = "DAYTIME",
    EVENING = "EVENING",
    OVERNIGHTS = "OVERNIGHTS",
    WEEKENDS = "WEEKENDS",
    FLEXIBLE = "FLEXIBLE",
    BILINGUAL_CARE = "BILINGUAL_CARE",
    INFANT_CARE = "INFANT_CARE",
    PRESCHOOL_CURRICULUM = "PRESCHOOL_CURRICULUM",
    SCHOOL_AGE = "SCHOOL_AGE",
    SPECIAL_NEEDS = "SPECIAL_NEEDS",
    TODDLER_CARE = "TODDLER_CARE",
}

export enum ProviderProfileTagEnum_Category {
    PROVIDER_GENDER = "PROVIDER_GENDER",
    PREFERRED_CLIENT_GENDER = "PREFERRED_CLIENT_GENDER",
    LANGUAGE = "LANGUAGE",
    TYPE_OF_CARE = "TYPE_OF_CARE",
    AGE_RANGE = "AGE_RANGE",
    TYPE_OF_SUPPORT = "TYPE_OF_SUPPORT",
    GENERAL_SKILLS = "GENERAL_SKILLS",
    MOVEMENT_SKILLS = "MOVEMENT_SKILLS",
    TRANSPORTATION_SKILLS = "TRANSPORTATION_SKILLS",
    ENVIRONMENT = "ENVIRONMENT",
    ENVIRONMENT_GENERAL = "ENVIRONMENT_GENERAL",
    PETS = "PETS",
    SPECIALIZED_SKILL = "SPECIALIZED_SKILL",
    AVAILABILITY = "AVAILABILITY",
    SCHEDULE = "SCHEDULE",
    PRONOUN = "PRONOUN",
    TRAINING_FILTER = "TRAINING_FILTER",
}

export enum ReferralMethodEnum {
    EMPTY = "EMPTY",
    SEIU755UNION = "SEIU755UNION",
    CDWA = "CDWA",
    SEIU775BENEFITSGROUP = "SEIU775BENEFITSGROUP",
    INDIVIDUALPROVIDER = "INDIVIDUALPROVIDER",
    HOMECARECLIENT = "HOMECARECLIENT",
    AFRIENDORFAMILY = "AFRIENDORFAMILY",
    CARINAEMAILS = "CARINAEMAILS",
    OTHER = "OTHER",
    CASEMANAGER = "CASEMANAGER",
    RISECAREWELL = "RISECAREWELL",
    INHOMEPROVIDER = "INHOMEPROVIDER",
    SEIULOCAL503 = "SEIULOCAL503",
    OHCC = "OHCC",
}

export enum RegistrationStepEnum {
    ROLE_SELECTION = "ROLE_SELECTION",
    VERIFICATION = "VERIFICATION",
    TOKEN_REDEMPTION = "TOKEN_REDEMPTION",
    INVITE_REDEMPTION = "INVITE_REDEMPTION",
    PASSWORD_CREATION = "PASSWORD_CREATION",
    GUIDELINES = "GUIDELINES",
}

export enum ReportSourceEnum {
    PROVIDER_PROFILE = "PROVIDER_PROFILE",
    JOB_POST = "JOB_POST",
    DIRECT_MESSAGE = "DIRECT_MESSAGE",
    JOB_POST_MESSAGE = "JOB_POST_MESSAGE",
}

export enum ReportStatusEnum {
    RESOLVED = "RESOLVED",
    PENDING = "PENDING",
    UNPROCESSED = "UNPROCESSED",
}

export enum ResolvedMethodEnum {
    FILLED_OTHER = "FILLED_OTHER",
    NOT_FILLED = "NOT_FILLED",
    FILLED_CARINA = "FILLED_CARINA",
    SYSTEM_DELETE = "SYSTEM_DELETE",
    DELETED_FROM_NOTIFICATION = "DELETED_FROM_NOTIFICATION",
}

export enum RoleType {
    PROVIDER = "PROVIDER",
    CHILDCARE_PROVIDER = "CHILDCARE_PROVIDER",
    PROXY_PROVIDER = "PROXY_PROVIDER",
    CONSUMER = "CONSUMER",
    SUPPORT = "SUPPORT",
    CASE_WORKER = "CASE_WORKER",
    MEDICAID_REFERRAL_COORDINATOR = "MEDICAID_REFERRAL_COORDINATOR",
    MEDICAID_CASE_MANAGER = "MEDICAID_CASE_MANAGER",
    USER_SUPPORT_AGENT = "USER_SUPPORT_AGENT",
    USER_SUPPORT_MANAGER = "USER_SUPPORT_MANAGER",
}

export enum SingleInviteType {
    CHILDCARE = "CHILDCARE",
    MEDICAID = "MEDICAID",
}

export enum SingleInvite_Status {
    PENDING = "PENDING",
    REDEEMED = "REDEEMED",
    INVALIDATED = "INVALIDATED",
    EXPIRED = "EXPIRED",
}

export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export enum SupportMessageProgram {
    MEDICAID_HOME_CARE = "MEDICAID_HOME_CARE",
    PRIVATE_PAY_HOME_CARE = "PRIVATE_PAY_HOME_CARE",
    CHILD_CARE = "CHILD_CARE",
}

export enum SupportMessageRole {
    CASE_MANAGER = "CASE_MANAGER",
    CONSUMER = "CONSUMER",
    FAMILY = "FAMILY",
    PROVIDER = "PROVIDER",
    OTHER = "OTHER",
}

export enum SupportMessageState {
    CALIFORNIA = "CALIFORNIA",
    CONNECTICUT = "CONNECTICUT",
    ILLINOIS = "ILLINOIS",
    MASSACHUSETTS = "MASSACHUSETTS",
    NEW_YORK = "NEW_YORK",
    WASHINGTON = "WASHINGTON",
    OREGON = "OREGON",
    OTHER = "OTHER",
}

export enum TestimonialSourceEnum {
    SELF_REPORT = "SELF_REPORT",
    FRIEND_FAMILY = "FRIEND_FAMILY",
    OTHER_REPRESENTATIVE = "OTHER_REPRESENTATIVE",
    LEGACY_HUBSPOT = "LEGACY_HUBSPOT",
}

export enum ThreadFilterEnum {
    ALL = "ALL",
    UNREAD = "UNREAD",
    STARRED = "STARRED",
    ARCHIVED = "ARCHIVED",
}

export enum TravelType {
    CAR = "CAR",
    BUS = "BUS",
}

export enum UserAlertType {
    LGBT_BANNER = "LGBT_BANNER",
}

export enum User_AccountStatus {
    NEW = "NEW",
    ACTIVE = "ACTIVE",
    DEACTIVATED = "DEACTIVATED",
}

export enum VerificationParameterType {
    LAST_4 = "LAST_4",
    DOB = "DOB",
    UNIQUE_ID = "UNIQUE_ID",
    FIRST_NAME = "FIRST_NAME",
    LAST_NAME = "LAST_NAME",
    INVITE_TOKEN = "INVITE_TOKEN",
    PRIME_ID = "PRIME_ID",
    EMAIL = "EMAIL",
    SUPERVISOR_INFO = "SUPERVISOR_INFO",
    LANGUAGE_PREF = "LANGUAGE_PREF",
}

export enum VerificationStatusEnum {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    CANCELLED = "CANCELLED",
}

export enum VerificationStrategyEnum {
    API = "API",
    TOKEN = "TOKEN",
    INVITE = "INVITE",
    EMAIL_DOMAIN = "EMAIL_DOMAIN",
}

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}


// Added by 'AxiosClientExtension' extension

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, GenericAxiosResponse } from "axios";

declare module "axios" {
  export interface GenericAxiosResponse<R> extends AxiosResponse {
    data: R;
  }
}

class AxiosHttpClient implements HttpClient<AxiosRequestConfig> {
  constructor(private axios: AxiosInstance) {}

  request<R>(requestConfig: {
    method: string;
    url: string;
    queryParams?: any;
    data?: any;
    copyFn?: (data: R) => R;
    options?: AxiosRequestConfig;
  }): RestResponse<R> {
    function assign(target: any, source?: any) {
      if (source != undefined) {
        for (const key in source) {
          if (source.hasOwnProperty(key)) {
            target[key] = source[key];
          }
        }
      }
      return target;
    }

    const config: AxiosRequestConfig = {};
    config.method = requestConfig.method as typeof config.method; // `string` in axios 0.16.0, `Method` in axios 0.19.0
    config.url = requestConfig.url;
    config.params = requestConfig.queryParams;
    config.data = requestConfig.data;
    assign(config, requestConfig.options);
    const copyFn = requestConfig.copyFn;

    const axiosResponse = this.axios.request(config);
    return axiosResponse.then((axiosResponse) => {
      if (copyFn && axiosResponse.data) {
        (axiosResponse as any).originalData = axiosResponse.data;
        axiosResponse.data = copyFn(axiosResponse.data);
      }
      return axiosResponse;
    });
  }
}

declare global {
  interface Window {
    updateLogoutTimer?: () => void;
  }
}

function createAxiosInstance(): AxiosInstance {
  const axiosInstance = axios.create();
  axiosInstance.interceptors.response.use((config) => {
    if (window.updateLogoutTimer) window.updateLogoutTimer();
    return config;
  });
  return axiosInstance;
}

        

export class AxiosAuthControllerClient extends AuthControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosAuthCredentialsMigrationControllerClient extends AuthCredentialsMigrationControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosAvailabilityStatusCronControllerClient extends AvailabilityStatusCronControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosCarinaAdminFunderManagementControllerClient extends CarinaAdminFunderManagementControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosCarinaAdminUserManagementControllerClient extends CarinaAdminUserManagementControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosCarinaAdminUtilityControllerClient extends CarinaAdminUtilityControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosChildcareControllerClient extends ChildcareControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosChildcareInvitesControllerClient extends ChildcareInvitesControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosChildcareMessagingControllerClient extends ChildcareMessagingControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosChildcareProviderProfileControllerClient extends ChildcareProviderProfileControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosChildcareProviderProfileSearchControllerClient extends ChildcareProviderProfileSearchControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosDirectionsControllerClient extends DirectionsControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosEnvironmentConfigControllerClient extends EnvironmentConfigControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosFindInviteControllerClient extends FindInviteControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosFunderAdminToolsControllerClient extends FunderAdminToolsControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosFunderSelectionControllerClient extends FunderSelectionControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosGroupInviteControllerClient extends GroupInviteControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosHealthcheckControllerClient extends HealthcheckControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosInviteManagementControllerClient extends InviteManagementControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosJobPostCronControllerClient extends JobPostCronControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosJobPostSearchAndFilterControllerClient extends JobPostSearchAndFilterControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosLocizeControllerClient extends LocizeControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosMedicaidConsumerControllerClient extends MedicaidConsumerControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosMedicaidControllerClient extends MedicaidControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosMedicaidCoordinatorControllerClient extends MedicaidCoordinatorControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosMedicaidInvitesControllerClient extends MedicaidInvitesControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosMedicaidProviderControllerClient extends MedicaidProviderControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosMessagingControllerClient extends MessagingControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosNotificationCronControllerClient extends NotificationCronControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosOnboardingControllerClient extends OnboardingControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCAgencyDashboardConsumersControllerClient extends PPHCAgencyDashboardConsumersControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCAgencyDashboardProvidersControllerClient extends PPHCAgencyDashboardProvidersControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCAgencyRegistrationControllerClient extends PPHCAgencyRegistrationControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCCarinaAdminDashboardAgenciesControllerClient extends PPHCCarinaAdminDashboardAgenciesControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCCarinaAdminDashboardConsumersControllerClient extends PPHCCarinaAdminDashboardConsumersControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCCarinaAdminDashboardFundersControllerClient extends PPHCCarinaAdminDashboardFundersControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCCarinaAdminDashboardProvidersControllerClient extends PPHCCarinaAdminDashboardProvidersControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCConsumerXpControllerClient extends PPHCConsumerXpControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCFunderDashboardAgencyAdminsControllerClient extends PPHCFunderDashboardAgencyAdminsControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCFunderDashboardConsumersControllerClient extends PPHCFunderDashboardConsumersControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPPHCFunderDashboardProvidersControllerClient extends PPHCFunderDashboardProvidersControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosProviderProfileSearchAndFilterControllerClient extends ProviderProfileSearchAndFilterControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosPublicMedicaidControllerClient extends PublicMedicaidControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosRegistrationControllerClient extends RegistrationControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosRegistrationTokenControllerClient extends RegistrationTokenControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosRepresentativeJobPosterControllerClient extends RepresentativeJobPosterControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosRoleSelectionControllerClient extends RoleSelectionControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosSMSCallbackControllerClient extends SMSCallbackControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosShortLinkControllerClient extends ShortLinkControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosSingleInvitesControllerClient extends SingleInvitesControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosSupportControllerClient extends SupportControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosUserControllerClient extends UserControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosUsersAdministrationControllerClient extends UsersAdministrationControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        

export class AxiosUsersImpersonationControllerClient extends UsersImpersonationControllerClient<AxiosRequestConfig> {

    constructor(baseURL: string = window.location.origin, axiosInstance: AxiosInstance = createAxiosInstance()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
        
